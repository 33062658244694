import React from 'react'
import { ReferralType, useShowLeadQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { alwaysArray } from '../../../common/utils'
import ContractorLeadAcceptorPage from './ContractorLeadAcceptorPage'
import { getBudgetRangeByAmountInPennies } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useMyContractorProfile } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { moneyAmountAsNumber } from '../../../common/utils/currency'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'

const DisplayContractorLeadAcceptorPage: React.FC = () => {
  const { leadId } = useParamsFromPageConfig<{ leadId: string }>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const useShowLeadResponse = useShowLeadQuery(gqlDataSource , { id: leadId }, { refetchOnWindowFocus: false })
  const budgetValueAsMoney = useShowLeadResponse.data?.getLead.budgetValue
  const leadBudgetAmountInPennies = budgetValueAsMoney === undefined ? undefined : moneyAmountAsNumber(budgetValueAsMoney)
  const leadBudgetRange = getBudgetRangeByAmountInPennies(leadBudgetAmountInPennies ?? 0)    // NOTE: `leadBudgetAmountInPennies` should never be undefined/null because of the guard

  const { getContractorProfileQuery: useGetContractorProfileResponse } = useMyContractorProfile()
  const hasBudgetRangeSubscription = alwaysArray(useGetContractorProfileResponse.data?.getContractorProfile?.budgetRangeSubscriptions)
    .some(subscription => subscription.budgetRange === leadBudgetRange)

  const isProjectOwnerReferral = useShowLeadResponse.data?.getLead.referral?.type === ReferralType.ProjectOwner

  console.debug(`[DisplayContractorLeadAcceptor] Render: `, { leadId, useShowLeadResponse, useGetContractorProfileResponse, hasBudgetRangeSubscription })

  if (useShowLeadResponse.isFetching || useGetContractorProfileResponse.isFetching) {
    return <LoadingSpinnerPage name="DisplayContractorLeadAcceptor" />
  }

  if (useShowLeadResponse.error || useGetContractorProfileResponse.error || leadBudgetRange == null) {
    console.error(`[DisplayContractorLeadAcceptor] Failed to load Contractor WorkHistory item: `, { useShowLeadResponse, useGetContractorProfileResponse, leadBudgetAmountInPennies, leadBudgetRange })
    return <ErrorBlockPage onRefresh={() => {
      useShowLeadResponse.refetch()
      useGetContractorProfileResponse.refetch()
    }} />
  }

  return <ContractorLeadAcceptorPage
    leadId={leadId}
    budgetRange={leadBudgetRange}
    hasBudgetRangeSubscription={hasBudgetRangeSubscription}
    isProjectOwnerReferral={isProjectOwnerReferral}
  />
}

export default DisplayContractorLeadAcceptorPage
