import React from 'react'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

const WeaverContractors: React.FC = () => {
  const weaverFlags = useWeaverFlags()

  return (
    <div>
      <h2>{weaverFlags["create-project-contractors"].title}</h2>
      <p>{weaverFlags["create-project-contractors"].description}</p>
      <h3>{weaverFlags["create-project-contractors"].contractorCount} Main contractors</h3>
    </div>
  )
}

export default WeaverContractors
