import React, { useCallback, useState } from 'react'
import { IonButton, IonText } from '@ionic/react'
import Styles from "./UploadProjectDocumentsPage.module.scss"
import uploadDocuments from "./upload-project-documents.svg"
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { UploadableFileChip, CreateUploadedFileReturns, UpdateUploadedFileReturns } from './uploadableFileChip/UploadableFileChip'
import  BrowseFilesButton from  '../../../common/components/BrowseFilesButton'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { saveFileToDeviceStorage } from '../../../common/utils/files'
import { useParamsFromPageConfig } from '../../../routesProvider'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { useAddUploadedFileToProjectFilesMutation, useIncrementProjectDocumentCountMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useQueryClient } from 'react-query'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useRouteTo, pageConfig_UploadedProjectDocumentSuccess } from '../../../routes'

const UploadProjectDocuments: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{projectId: string}>()
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const addUploadedFileToProjectFilesMutation = useAddUploadedFileToProjectFilesMutation(gqlDataSource)
  const incrementProjectDocumentCountMutation = useIncrementProjectDocumentCountMutation(gqlDataSource)
  const triggerProjectDocumentUploaded = useAnalyticsEvent("Project_Document_Uploaded")
  const goToUploadProjectDocumentSuccessPage = useRouteTo(pageConfig_UploadedProjectDocumentSuccess.path)

  const queryClient = useQueryClient()

  if (!myTeam) return <LoadingSpinnerPage name='displayMyProfile' />

  const [ fileList, setFileList ] = useState<File []>([])

  const addFileToList = (file: File) => {
    setFileList(currentState => ([ ...currentState, file ]))
  }

  const addFilesToList = (files: File[]) => {
    setFileList(currentState => ([ ...currentState, ...files ]))
  }

  const clearFileList = () => {
    setFileList([])
  }

  const removeFileFromList = (fileName: string | undefined | null) => {
    setFileList(currentState => ([ ...currentState.filter(file => file.name !== fileName) ]))
  }

  const onFilesSelectedByUser = useCallback(async (selectedFilesByUser: File[]) => {
    const filesNotAlreadyIncluded = selectedFilesByUser.filter(file => !fileList.includes(file))
    await Promise.all(filesNotAlreadyIncluded.map(file => saveFileToDeviceStorage(file)))

    addFilesToList(filesNotAlreadyIncluded)
  }, [ fileList, addFileToList ])

  const invalidateShowProjectQuery = () => {
    queryClient.invalidateQueries([ 'showProject' ])
  }

  const onUploadedFileArchivedByUser = useCallback((result: UpdateUploadedFileReturns) => {
    removeFileFromList(result.fileName)
    invalidateShowProjectQuery()
  }, [ removeFileFromList ])

  const addUploadedFileToProjectFileList = (uploadedFileId: string) =>
    addUploadedFileToProjectFilesMutation.mutateAsync({
      projectId,
      uploadedFileId: uploadedFileId,
    })

  const onUploadedFileCreated = async (result: CreateUploadedFileReturns) => {
    await addUploadedFileToProjectFileList(result.id)
    invalidateShowProjectQuery()
  }

  const navigateToSuccessPage = () => {
    goToUploadProjectDocumentSuccessPage({ projectId })()
  }

  const onConfirm = async () => {
    await incrementProjectDocumentCountMutation.mutateAsync({
      input: {
        projectId,
        documentCount: fileList.length,
      },
    })
    clearFileList()
    navigateToSuccessPage()
  }

  return (
    <WeaverIonPage>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Documents'/>
      </WeaverIonHeader>
      <WeaverIonContent className='ion-padding'>
        <h4 className={Styles.title}>Upload Documents</h4>

        <div className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
          <img src={uploadDocuments} className={Styles.uploadDocumentsImage} alt="Upload documents image" />
          <h6 className={Styles.uploadYourDocumentsText}>Upload Your Documents</h6>

          <BrowseFilesButton onFilesSelectedByUserCallback={onFilesSelectedByUser} multipleSelection={true} />

          <div className={Styles.filesToUploadContainer}>
            {
              fileList.map(
                file =>
                {
                  // Use the file's last modified + name as the key since files don't come with an id and it's not recommended the use index in cases when items can be removed.
                  const key = `${file.lastModified}_${file.name}`
                  return <UploadableFileChip
                    key={key}
                    file={file}
                    onUploadedFileArchived={onUploadedFileArchivedByUser}
                    onUploadedFileCreated={onUploadedFileCreated}
                    trackEvent={({ fileType, sizeOfUpload, timeOfUpload }) => triggerProjectDocumentUploaded({ projectId, fileType, fileName: file.name, sizeOfUpload, timeOfUpload })}
                  />
                },
              )
            }
          </div>

          <IonButton disabled={fileList.length === 0} onClick={onConfirm} className={Styles.confirmButton}>Confirm</IonButton>
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default UploadProjectDocuments
