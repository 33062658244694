import React from 'react'
import SuccessPage from '../../../common/components/SuccessPage/SuccessPage'
import { useParamsFromPageConfig } from '../../../routesProvider'

type UploadedProjectDocumentSuccessPageProps = {
  routerLink?: string,
}

const UploadedProjectDocumentSuccessPage: React.FC<UploadedProjectDocumentSuccessPageProps> = () => {

  const { projectId } = useParamsFromPageConfig<{ projectId: string }>()

  return (
    <SuccessPage routerLink={`/projects/${projectId}`} routerAction="pop" header= 'Success!' text= 'Documents successfully uploaded' buttonText= 'Continue!'/>
  )
}
export default UploadedProjectDocumentSuccessPage
