import React, { ComponentProps } from 'react'
import { IonPage } from '@ionic/react'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { TeamType } from '../../graphql/generated'
import PublicArchitectProfilePage from './PublicArchitectProfile/PublicArchitectProfilePage'
import PublicContractorProfilePage from './PublicContractorProfile/PublicContractorProfilePage'
import PublicHomeownerProfilePage from './PublicHomeownerProfile/PublicHomeownerProfilePage'
import { useParamsFromPageConfig } from '../../routesProvider'
import PublicWeaverProfilePage from './PublicWeaverProfile/PublicWeaverProfilePage'

export type TeamPublicProfilePageComponentProps = {
  teamId: string,
} & ComponentProps<typeof IonPage>

export const teamProfileModalComponents: Record<TeamType, React.FC<TeamPublicProfilePageComponentProps>> = {
  ARCHITECT: PublicArchitectProfilePage,
  CONTRACTOR: PublicContractorProfilePage,
  HOMEOWNER: PublicHomeownerProfilePage,
  WEAVER: PublicWeaverProfilePage,
}

const DisplayPublicProfile: React.FC = () => {
  const { teamType, teamId } = useParamsFromPageConfig<{ teamType: TeamType, teamId: string }>()
  console.debug(`[DisplayPublicProfile] Render: `, { teamType, teamId })

  if (!teamId || !teamType) return <LoadingSpinnerPage name='DisplayPublicProfile' />

  const PublicProfilePageComponent = teamProfileModalComponents[teamType]
  return <PublicProfilePageComponent {...{ teamId }}  />
}

export default DisplayPublicProfile
