import { useIonAlert } from "@ionic/react"
import { useCallback } from "react"
import { UseMutationResult, useQueryClient } from "react-query"
import { useGraphQLDataSource } from "../../../api/graphql"
import { StorableValue, useStoredValue } from "../../../api/providers/StorageProvider"
import { ClaimInviteMutation, Exact, useClaimInviteMutation, useListMyProjectsAndLeadsQuery } from "../../../graphql/generated"
import { StoredInvite, STORED_INVITE_KEY, EMPTY_INVITE, EmptyInvite, PresentInvite } from "./common"

type UseClaimOptions = NonNullable<Parameters<typeof useClaimInviteMutation>[1]>
type OnClaimSettled = NonNullable<UseClaimOptions["onSettled"]>
type OnClaimError = NonNullable<UseClaimOptions["onError"]>

type ClaimInviteMutationResult = UseMutationResult<ClaimInviteMutation, unknown, Exact<{inviteId: string}>, unknown>
type SetStoredInviteFn = (newValue: StorableValue<StoredInvite>) => void | Promise<void>
type ClearInviteFn = () => void | Promise<void>

type useStoredInvitePresentReturns = {
  invite: PresentInvite,
  hasInvite: true,
  setInvite: SetStoredInviteFn,
  clearInvite: ClearInviteFn,
}

type UseStoredInviteEmptyReturns = {
  invite: EmptyInvite,
  hasInvite: false,
  setInvite: SetStoredInviteFn,
  clearInvite: ClearInviteFn,
}
type UseStoredInviteReturns = useStoredInvitePresentReturns | UseStoredInviteEmptyReturns

type UseInviteReturns<TStoredInvite> = TStoredInvite & {
  claimInviteMutation: ClaimInviteMutationResult,
}

/** retrieves utilities for storing & managing invites
 *
 * Use this hook in a pre-bootstrap context (i.e. [InviteCapture](./InviteCapture.ts)). If you need advanced functionality, e.g. claiming invites, see `useInvite` (post-bootstrap)
 */
export const useStoredInvite = (): UseStoredInviteReturns => {
  const [ invite, setInvite ] = useStoredValue<StoredInvite>({ key: STORED_INVITE_KEY })
  const hasInvite = !!invite?.inviteId
  const clearInvite = useCallback(() => setInvite(EMPTY_INVITE), [ setInvite ])

  if (!hasInvite) {
    return {
      invite: EMPTY_INVITE,
      hasInvite,
      setInvite,
      clearInvite,
    }
  }

  return {
    invite,
    hasInvite,
    setInvite,
    clearInvite,
  }
}

/** Exposes functionality & lifecycle of the locally stored invite, including claiming
 *
 * Use this hook in a bootstrapped context (requires a queryClient). Use `useStoredInvite` in a pre-bootstrapped context
 *
 * By default, [InviteCapture](./InviteCapture.ts) is responsible for populating this from the queryString.
 */
export const useInvite = (): UseInviteReturns<UseStoredInviteReturns> => {
  const { invite, hasInvite, setInvite, clearInvite } = useStoredInvite()
  const queryClient = useQueryClient()

  const [ present ] = useIonAlert()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const onSettled = useCallback(<OnClaimSettled>(async () => {
    console.debug("[InviteClaimer] clearing invite storage")
    await clearInvite()
    await queryClient.invalidateQueries(useListMyProjectsAndLeadsQuery.getKey())
  }), [ setInvite, queryClient ])

  const onError = useCallback(<OnClaimError>((error, { inviteId }) => {
    console.error("[useCapturedInvite] failed to claim invite.", inviteId, error)
    if (error instanceof Error) {
      present({
        header: "Oops! We couldn't claim your invite.",
        message: error.message,
        buttons: [
          {
            text: "Dismiss",
            role: 'cancel',
          },
        ],
      })
    }
  }), [ present ])

  const claimInviteMutation = useClaimInviteMutation(gqlDataSource, { onError, onSettled })

  // use type guarding to make sure the return type signature is consistent
  if (!hasInvite) {
    return {
      claimInviteMutation,
      invite,
      hasInvite,
      setInvite,
      clearInvite,
    }
  }

  return {
    claimInviteMutation,
    invite,
    hasInvite,
    setInvite,
    clearInvite,
  }
}
