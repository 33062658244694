import { alwaysArray } from "../../../../../../common/utils"
import { BudgetRange } from "../../../../../../graphql/generated"
import { getFirstWorkHistoryByBudgetRange, useMyContractorProfile } from "../../datasource"

const hasBadgeAlready = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange): boolean =>
  alwaysArray(profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges)
    .some(badge => badge.budgetRange === budgetRange)

const doesProfileQualifyForBadge = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange): boolean => {
  console.debug(`[doesProfileQualifyForBadge] Checking: `, { profile, budgetRange })

  const workHistory = getFirstWorkHistoryByBudgetRange(profile, budgetRange)
  if (workHistory === undefined) {
    console.debug(`[doesProfileQualifyForBadge] No work history, no badge`)
    return false
  }

  const hasReferences = alwaysArray(workHistory.references).length > 0
  const hasPhotos = alwaysArray(workHistory.photos).length > 0

  console.log(`[doesProfileQualifyForBadge] Result === ${hasReferences && hasPhotos}: `, { workHistory, hasReferences, hasPhotos })
  return hasReferences && hasPhotos
}

// Exported Hooks

export const useHasContractorProfileBudgetRangeBadge = (budgetRange: BudgetRange): boolean => {
  const profile = useMyContractorProfile()

  return hasBadgeAlready(profile, budgetRange)
}

export const useShouldAwardContractorProfileBudgetRangeBadge = (budgetRange: BudgetRange): boolean => {
  const profile = useMyContractorProfile()

  if (hasBadgeAlready(profile, budgetRange)) return false

  return doesProfileQualifyForBadge(profile, budgetRange)
}
