import React from 'react'
import { IonButton, IonIcon, IonTitle } from '@ionic/react'
import { checkmarkCircle } from "ionicons/icons"
import Styles from "./MembershipPayPerLead.module.scss"
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'

type MembershipPayPerLeadProps = {
  title: string,
  list: string[],
  budgetRange: string,
  paymentFee?: string | null,
  isSubscriptionFee?: boolean,
  navigateTo: () => void | Promise<void>,
  goBack: () => void | Promise<void>,
}

const MembershipPayPerLead: React.FC<MembershipPayPerLeadProps> = ({ title, budgetRange, list, paymentFee, isSubscriptionFee, navigateTo }) => {

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={title} />
    </WeaverIonHeader>
    <WeaverIonContent className="ion-padding">

      {isSubscriptionFee
        ? (
          <div className={Styles.budgetFeeInfo}>
            <p className={Styles.largerHeading}>Get unlimited {budgetRange} leads</p>
            <IonTitle>{paymentFee}</IonTitle>
            <p>per month</p>
          </div>
        )
        : (
          <div className={Styles.budgetFeeInfo}>
            <p className={Styles.largerHeading}>Accept this {budgetRange} lead</p>
            <IonTitle>{paymentFee}</IonTitle>
            <p>for this lead only</p>
          </div>
        )}
      <div className={Styles.feeListItems}>
        {list.map((itemText: string) => (
          <span key={itemText}><IonIcon icon={checkmarkCircle} /><p>{itemText}</p></span>
        ))}
      </div>

      {isSubscriptionFee
        ? (
          <IonButton className={Styles.payButton} expand='block' onClick={navigateTo}>
            <b>Pay {paymentFee} per month</b>
          </IonButton>
        )
        : (
          <IonButton className={Styles.payButton} expand='block' onClick={navigateTo}>
            <b>Pay {paymentFee} to accept this lead</b>
          </IonButton>
        )}
    </WeaverIonContent>
  </>
}

export default MembershipPayPerLead
