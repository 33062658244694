import React, { useCallback, useState } from 'react'
import { IonIcon, IonTextarea, IonButton, useIonRouter } from "@ionic/react"
import { sendOutline, attachOutline } from 'ionicons/icons'

import Styles from "./ChatFooter.module.scss"
import { SelectedFiles } from './SelectedFiles'
import { useChatRoom } from '../services/ChatRoomProvider'
import { v4 as uuid } from "uuid"

import { NewChatDocumentActionSheet } from './NewChatDocumentActionSheet'
import { convertImageToFile, saveFileToDeviceStorage } from '../../../../../common/utils/files'
import { Filesystem } from '@capacitor/filesystem'
import { isSomething } from '../../../../../common/utils'
import { useImageGallery } from './useImageGallery'
import { useCamera } from './useCamera'
import { verifyPermission, PhonePermission } from '../../../../../common/utils/phoneCameraPermissions'
import { useMyIndividual, useMyIndividualActiveTeam } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { DateTime } from 'luxon'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import { CreateUploadedFileReturns, UpdateUploadedFileReturns } from '../../../../projects/documents/uploadableFileChip/UploadableFileChip'
import { useWeaverFlags } from '../../../../../api/thirdParty/launchDarkly/useWeaverFlags'

type ChatFooterProps = {
  goToFilePickerScreen: () => void,
}

const ChatFooter: React.FC<ChatFooterProps> = ({ goToFilePickerScreen }) => {
  const weaverFlags = useWeaverFlags()

  const [ localCurrentMessage, setLocalCurrentMessage ] = useState("")

  const { pickImages } = useImageGallery()
  const myTeam = useMyIndividualActiveTeam()

  const { takePhoto } = useCamera()

  const {
    chatRoomId,
    chatRoomName,
    chatRoomMessages,
    newMessageFiles,
    getMembersPresentInChatRoom,
    clearFilesFromNewMessage,
    addFileToNewMessage,
    addFilesToNewMessage,
    sendChatRoomMessage,
    removeFileFromNewMessage,
  } = useChatRoom()

  const [ showActionSheet, setShowActionSheet ] = useState(false)
  const [ createdChatRoomMessageDocuments, setCreatedChatRoomMessageDocuments ] = useState<CreateUploadedFileReturns[]>([])
  const ionRouter = useIonRouter()
  const triggerMessageSentEvent = useAnalyticsEvent("Messaging_Message_Sent")

  const [ nextMessageIdFromClient, setNextMessageIdFromClient ] = useState<string>(uuid())

  const myIndividual = useMyIndividual()

  const onActionSheetDimissed = useCallback(() => {
    setShowActionSheet(false)
  }, [ setShowActionSheet ])

  const wasLastMessageSentAtLeastFiveMinsAgo = (newMessageSentAtISO: string) => {
    const DELAY_IN_MINUTES_BETWEEN_NEW_MESSAGE_EVENT_TRIGGER = 5

    if (chatRoomMessages === undefined) return true

    const isThisFirstMessage = chatRoomMessages.length === 0

    if (isThisFirstMessage) return true

    const lastMessageSentAtISO = chatRoomMessages[chatRoomMessages.length - 1].sentAt

    const differenceInMinutesBetweenMessages = (DateTime.fromISO(newMessageSentAtISO).toSeconds() - DateTime.fromISO(lastMessageSentAtISO).toSeconds()) / 60
    return differenceInMinutesBetweenMessages >= DELAY_IN_MINUTES_BETWEEN_NEW_MESSAGE_EVENT_TRIGGER
  }

  const sendCurrentMessage = async () => {
    const doesChatRoomMessageDocumentExistInSelectedFiles = (chatRooMessageDocument: CreateUploadedFileReturns) => newMessageFiles.find(file => file.name === chatRooMessageDocument.fileName)
    const currentCreatedChatRoomMessages = createdChatRoomMessageDocuments.filter(doesChatRoomMessageDocumentExistInSelectedFiles)
    const newMessageSentAtISO = new Date().toISOString()

    const messageSentInfo = await sendChatRoomMessage({
      chatRoomId,
      documentCount: newMessageFiles.length > 0 ? newMessageFiles.length : undefined,
      idFromClient: nextMessageIdFromClient,
      content: localCurrentMessage,
      sentAt: newMessageSentAtISO,
    }, currentCreatedChatRoomMessages)

    setNextMessageIdFromClient(uuid())
    setLocalCurrentMessage("")
    clearFilesFromNewMessage()
    setCreatedChatRoomMessageDocuments([])

    if (!messageSentInfo || !messageSentInfo.recipients) return

    const recipients = messageSentInfo.recipients

    const baseEventInfo = {
      chatRoomId,
      chatRoomName : chatRoomName || "",
      documentCount: newMessageFiles.length,
      content: localCurrentMessage,
      sentAt: DateTime.fromISO(newMessageSentAtISO).toLocaleString(DateTime.DATETIME_FULL),
      wasLastMessageSentAtLeastFiveMinsAgo: wasLastMessageSentAtLeastFiveMinsAgo(newMessageSentAtISO),
      sender: {
        individualId: myIndividual.id,
        teamId: myTeam?.id || "",
        familyName: myIndividual.familyName,
        givenName: myIndividual.givenName,
      },
    }

    const membersOnline = await getMembersPresentInChatRoom()

    await Promise.all(recipients.filter(isSomething).map(recipient => (
      triggerMessageSentEvent({
        ...baseEventInfo,
        receiver: {
          individualId: recipient.individualId,
          teamId: recipient.teamId,
          familyName: recipient.familyName,
          givenName: recipient.givenName,
          isOnline: membersOnline.find(member => member.clientId === recipient.individualId) !== undefined,
        },
      })
    ),
    ))
  }

  const createChatRoomMessageAttachment = async (result: CreateUploadedFileReturns) => {
    createdChatRoomMessageDocuments.push(result)
    return result
  }

  const archiveChatRoomMessageAttachment = (result: UpdateUploadedFileReturns) => {
    const { fileName } = result
    if (fileName){
      // A File is instantiated here since the selected files in ChatRoomContext is of type File[]
      removeFileFromNewMessage(new File([], fileName))
    }
  }

  const openFilePicker = useCallback(() => {
    setShowActionSheet(false)
    goToFilePickerScreen()
  }, [ ionRouter, setShowActionSheet ])

  const openImageGallery = useCallback(async () => {
    setShowActionSheet(false)
    const { images } = await pickImages()
    const readImagesFromFSPromises = images
      .map(({ path, format }) => {
        if (path === undefined) return undefined
        return Filesystem.readFile({ path })
          .then(imageFromFS => convertImageToFile(imageFromFS.data, format))
      })

    const files = await Promise.all(readImagesFromFSPromises)

    // The images picked from the gallery are saved to the device's storage in order to display the preview in the messages list
    const saveImagesToDeviceStoragePromises = files.filter(isSomething)
      .map(async file => await saveFileToDeviceStorage(file))

    await Promise.all(saveImagesToDeviceStoragePromises)

    addFilesToNewMessage(files.filter(isSomething))
  }, [ addFilesToNewMessage, pickImages, setShowActionSheet ])

  const openCamera = useCallback(async () => {
    const photo = await takePhoto()
    const file = convertImageToFile(photo.base64String, photo.format)
    if (file) {
      // The image taken from the camera is saved to the device's storage in order to display the preview in the messages list
      saveFileToDeviceStorage(file)
      addFileToNewMessage(file)
    }
  }, [ addFileToNewMessage, takePhoto ])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLIonTextareaElement>) => {
    if (event.key !== "Enter") return
    event.preventDefault()
    if (localCurrentMessage === "") return
    sendCurrentMessage()
  }

  return (
    <WeaverIonFooter className={Styles.chatFooter} id="chat-footer">
      <div className={Styles.container}>
        <div>
          <IonButton hidden color={"primary"} onClick={() => ionRouter.push('/not-implemented-yet')} >
            Next Step: Arrange Site Visit
          </IonButton>
        </div>
        <div className={Styles.messagingRowContainer}>
          <div className={Styles.messageTextAreaContainer}>
            <IonTextarea
              enterkeyhint='send'
              autoGrow
              rows={1}
              data-testid="chat-text-area"
              placeholder={"Send a message..."}
              value={localCurrentMessage}
              onIonChange={(event: CustomEvent) => setLocalCurrentMessage(event.detail.value)}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </div>

          {weaverFlags.files.enabled && weaverFlags.files.showChatMessageFiles && (
            <IonButton onClick={() => setShowActionSheet(true)} fill="clear" size="small">
              <IonIcon size='large' icon={attachOutline} color="primary" data-testid="chat-send-document-button" />
            </IonButton>
          )}

          <SingleClickButton onClick={sendCurrentMessage} fill="clear" size="small" disabled={localCurrentMessage === ''}>
            <IonIcon size='large' icon={sendOutline} color="primary" data-testid="chat-send-message-button" />
          </SingleClickButton>
        </div>

        <SelectedFiles
          createChatRoomMessageAttachment={createChatRoomMessageAttachment}
          archiveChatRoomMessageDocument={archiveChatRoomMessageAttachment}
        />

        <NewChatDocumentActionSheet
          onDismiss={onActionSheetDimissed}
          isOpen={showActionSheet}
          openFilePickerHandler={() => openFilePicker()}
          openPhotoGalleryHandler={
            () => verifyPermission(PhonePermission.Photos)
              .then(permissionGranted => {
                if (permissionGranted){ openImageGallery() }
              })
          }

          openCameraHandler={
            () => verifyPermission(PhonePermission.Camera)
              .then(permissionGranted => {
                if (permissionGranted){ openCamera() }
              })
          }
        />
      </div>
    </WeaverIonFooter>
  )
}

export default ChatFooter
