import React, { useMemo } from "react"
import * as ReactIs from "react-is"
import { useDevThrowErrorTestConsoleError } from "../../hooks/useConsole"
import { sortBy } from "../../utils"
import WeaverIonContent from "./WeaverIonContent"
import WeaverIonFooter from "./WeaverIonFooter"
import WeaverIonHeader from "./WeaverIonHeader"

type ReactFC_Children = Parameters<React.FC>['0']['children']

type NodeError = (nodeName: string) => string
export const ERROR_ONLY_ELEMENTS: NodeError = nodeName => `${nodeName} children must only contain elements`
export const ERROR_OTHER_WEAVERION_CHILDREN: NodeError = nodeName => `${nodeName} must contain other WeaverIon children`
export const ERROR_ONLY_WEAVERION_CHILDREN: NodeError = nodeName => `${nodeName} must ONLY contain other WeaverIon children`
export const ERROR_DIRECTLY_WEAVERIONCONTENT: NodeError = nodeName => `${nodeName} must directly contain WeaverIonContent`
export const ERROR_BAD_ORDER: NodeError = nodeName => `${nodeName} children must follow the order WeaverIonHeader > WeaverIonContent > WeaverIonFooter`

const weaverIonHeader = <WeaverIonHeader />
const weaverIonContent = <WeaverIonContent />
const weaverIonFooter = <WeaverIonFooter />

export const useWeaverIonThingsCorrectly = (parentNodeName: string, disableDirectChildStructureChecks: boolean, children: ReactFC_Children): void => {
  const onError = useDevThrowErrorTestConsoleError()

  const errorMessage: NodeError | false = useMemo(() => {
    if (disableDirectChildStructureChecks) return false

    // Normalise the children into always being an array
    const childrenArray =
      children == null
        ? []
        : Array.isArray(children)
          ? children
          : [ children ]

    // Ensure all the children are all elements
    const childrenEle: JSX.Element[] = []
    for (const child of childrenArray) {
      if (!ReactIs.isElement(child)) return ERROR_ONLY_ELEMENTS
      childrenEle.push(child)
    }

    // Get the WeaverIon children
    const acceptableOrder = [
      weaverIonHeader.type.name,
      weaverIonContent.type.name,
      weaverIonFooter.type.name,
    ]
    const childrenNodeNames = childrenEle.map(child => String(child.type.name)).filter(name => acceptableOrder.includes(name))

    if (childrenNodeNames.length === 0) return ERROR_OTHER_WEAVERION_CHILDREN
    if (childrenNodeNames.length !== childrenEle.length) return ERROR_ONLY_WEAVERION_CHILDREN
    if (!childrenNodeNames.includes(weaverIonContent.type.name)) return ERROR_DIRECTLY_WEAVERIONCONTENT

    // Confirm the order is acceptable
    const orderedChildrenNodeNames = [ ...childrenNodeNames ].sort(sortBy(name => acceptableOrder.indexOf(name)))
    if (!childrenNodeNames.every((value, index) => value === orderedChildrenNodeNames[index])) {
      return ERROR_BAD_ORDER
    }

    return false
  }, [ disableDirectChildStructureChecks, children ])

  if (errorMessage) {
    onError(errorMessage(parentNodeName))
  }
}
