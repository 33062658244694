import React, { useEffect, useRef, useState } from 'react'
import { IonIcon, IonSpinner, IonButton, useIonModal } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import { formatBytes } from '../../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { openFile,  readFileFromDeviceStorage, downloadUploadedFile } from '../../../../common/utils/files'
import { DownloadUploadedFileModal } from '../../../projects/DownloadUploadedFileModal/DownloadUploadedFileModal'
import DownloadUploadedFileModalStyles from "../../../projects/DownloadUploadedFileModal/DownloadUploadedFileModal.module.scss"
import { useAutoRetryGetUploadedFileQuery } from '../../../../common/hooks/useAutoRetryGetUploadedFileQuery'
import { ContentDisposition, SignedUrlStatus, useGetUploadedFileQuery } from '../../../../graphql/generated'
import { fullSizeTransformation } from '../../../../common/utils/imageTransformations'
import useAbortableEffect from '../../../../common/hooks/useAbortableEffect'
import { useChatRoom } from './services/ChatRoomProvider'
import { useGraphQLDataSource } from '../../../../api/graphql'
import Styles from "./Chat.module.scss"

const ChatMessageImageItem: React.FC<TChatRoomMessageDocument> = ({ id, fileName, fileSizeInBytes, fileContentType }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const query = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment, transformation: fullSizeTransformation } })
  const { isLoading, data } = query
  useAutoRetryGetUploadedFileQuery(query)

  const uploadedFile = data?.getUploadedFile
  const status = uploadedFile?.signedUrlForDownload.status

  const [ present ] = useIonModal(DownloadUploadedFileModal, {
    uploadedFileId: id,
  })

  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)
  const { readFromCache, saveToCache } = useChatRoom()
  const [ imageBlob, setImageBlob ] = useState<Blob>()
  const imgRef = useRef<HTMLImageElement>(null)

  useAbortableEffect(status => {

    const setImageBlobIfComponentIsStillMounted = (blob: Blob) => {
      if (!status.aborted){
        setImageBlob(blob)
      }
    }

    const readImageBlobFromFS = async () => {
      const blob = await readFileFromDeviceStorage(fileName)
      if (blob) {
        setImageBlobIfComponentIsStillMounted(blob)
        saveToCache(fileName, blob)
      } else {
        if (uploadedFile && fileName && !isLoading){
          const file = await downloadUploadedFile(uploadedFile, () => undefined, false)
          if (file) setImageBlob(file)
        }
      }
    }

    const readImageBlobFromCache = () => {
      const blob = readFromCache(fileName)
      if (blob) {
        setImageBlobIfComponentIsStillMounted(blob)
      } else {
        readImageBlobFromFS()
      }
    }

    readImageBlobFromCache()
  }, [ fileName, readFileFromDeviceStorage, readFromCache, setImageBlob, saveToCache, isLoading, status  ])

  useEffect(() => {
    const updateImageSrc = () => {
      if (imageBlob) {
        const url = URL.createObjectURL(imageBlob)
        if (imgRef && imgRef.current) {
          imgRef.current.src = url
        }
      }
    }
    updateImageSrc()
  }, [ imageBlob, setImageBlob ])

  const openFullSizeImageModal = () => {
    present({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const handleDownload = async () => {
    if (!uploadedFile)
      return

    const urlStatus = uploadedFile?.signedUrlForDownload.status
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openFullSizeImageModal()
    } else {
      const file = await downloadUploadedFile(uploadedFile, onProgressUpdate)
      if (file){
        setImageBlob(file)
        console.log(file)
      }
    }
  }

  return (
    <div className={Styles.chatMessageDocumentContainer}>
      {
        !isLoading && <img ref={imgRef} className={Styles.chatMessageImage} onClick={() => { openFile(fileName, fileContentType) }} />
      }

      {
        isLoading && <div className={`${Styles.thumbnailPhotoContainer} ${Styles.success}`}>
          <IonSpinner className={Styles.spinner} color="black" />
        </div>
      }

      <div className={Styles.chatMessageImageBottomContainer}>
        <div className={Styles.chatMessageImageDetailsContainer}>
          <div className={Styles.chatMessageDocumentName}>{fileName}</div>
          <div className={Styles.chatMessageDocumentSize}>{fileSizeInBytes ? formatBytes(fileSizeInBytes) : ""}</div>
        </div>

        <div className={Styles.chatMessageImageDownloadContainer}>
          {
            isDownloadingInProgress
              ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
              : <IonButton className={Styles.downloadButton} fill='clear' size='small' onClick={() => handleDownload()}>
                <IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} />
              </IonButton>
          }
        </div>
      </div>
    </div>
  )
}

export default ChatMessageImageItem
