import React from 'react'
import { IonBadge, IonButton, IonCard, IonCardContent, IonItem, IonItemDivider, IonLabel, IonList, IonText } from '@ionic/react'
import { DateTime } from 'luxon'
import { useGraphQLDataSource } from '../../api/graphql'
import { TeamType, useListMyProjectsAndLeadsQuery } from '../../graphql/generated'
import ProjectAvatar from './ProjectAvatar'
import Styles from './ProjectsIndexPage.module.scss'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ReactComponent as ConstructionWorkerIcon } from "../../assets/icons/twemoji-construction-worker.svg"
import { getKnownRoutePathForPage, Page } from '../../routes'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'

const NO_LEADS_DESCRIPTION = 'New Leads will show up here.'
const NO_PROJECTS_DESCRIPTION_CONTRACTOR = 'You don\'t have any active projects yet. Accept leads to be put forward to a project shortlist.'
const NO_PROJECTS_DESCRIPTION_OWNER = 'You don\'t have any active projects yet. Your projects will appear here.'

function timeAgo(date: string): string {
  const now = DateTime.local()
  const past = DateTime.fromISO(date)
  const diff = now.diff(past, 'hours')
  return `${Math.floor(diff.hours)} hours ago`
}

const ProjectsIndexPage: React.FC = () => {
  const currentTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { isFetching, error, data, refetch } = useListMyProjectsAndLeadsQuery(gqlDataSource, {}, { refetchOnWindowFocus: false })

  if (isFetching) {
    return <LoadingSpinnerPage name="ProjectsIndex"/>
  }

  if (error || !data) {
    return <ErrorBlockPage onRefresh={refetch} />
  }

  const projects = data.listMyProjects
  const leads = data.listMyLeads
  const newLeads = leads.filter(x => !x.lastAccessed)

  const renderLeadsSection = () => {
    if (currentTeam?.type !== TeamType.Contractor) return null

    if (leads.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <IonLabel>Leads</IonLabel>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <p>{NO_LEADS_DESCRIPTION}</p>
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )

    return <>
      <IonItemDivider>
        <IonLabel>Leads {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}</IonLabel>
      </IonItemDivider>
      {leads.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/leads/${id}`}>
        <ProjectAvatar slot="start" title={title} />
        <IonLabel>
          {title} {!lastAccessed ? <IonBadge slot="end" color='success'>New</IonBadge> : null }
          {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
        </IonLabel>
      </IonItem>,
      )}
    </>

  }

  const renderProjectsSection = () => {
    const isContractor = currentTeam?.type === TeamType.Contractor
    if (projects.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <IonText><h4 className='ion-padding-start'>Projects</h4></IonText>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <ConstructionWorkerIcon className={Styles.projectsIcon} />
              <p>{isContractor ? NO_PROJECTS_DESCRIPTION_CONTRACTOR : NO_PROJECTS_DESCRIPTION_OWNER}</p>
              {!isContractor ? <IonButton routerLink={getKnownRoutePathForPage(Page.CreateProject)}>Create a project</IonButton> : null}
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )
    return <>
      <IonText><h4>Projects</h4></IonText>
      {!isContractor ? <IonButton className='ion-margin-bottom' routerLink={getKnownRoutePathForPage(Page.CreateProject)}>Create a project</IonButton> : null}
      {projects.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/projects/${id}`}>
        <ProjectAvatar slot="start" title={title} />
        <IonLabel>
          {title}
          {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
        </IonLabel>
      </IonItem>,
      )}
    </>
  }

  return <WeaverIonPage>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Projects'/>
    </WeaverIonHeader>
    <WeaverIonContent fullscreen>
      <IonList>
        {renderLeadsSection()}
        {renderProjectsSection()}
      </IonList>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ProjectsIndexPage
