import React from 'react'
import { IonIcon, IonBadge } from '@ionic/react'
import { pastProjectLabel } from './screenWorkHistoryMenu.i18n'
import { WorkHistoryMenuItem, progressionStatusIcons } from './config'
import NestedMenu from '../../../../../../common/components/NestedMenu/NestedMenu'
import { BudgetRange, ProgressionStatus } from '../../../../../../graphql/generated'
import { GetContractorProfileWorkHistory } from '../../datasource'
import { objectEntries } from '../../../../../../common/utils/objects'
import { alwaysArray } from '../../../../../../common/utils'
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences, useRouteTo } from '../../../../../../routes'
import { createValidCssClassname } from '../../../../../../common/utils/string'
import { progressionStatusBadgeLabel } from '../../progressionStatus.i18n'
import Styles from './ScreenWorkHistoryMenu.module.scss'

type ScreenWorkHistoryMenuProps = {
  budgetRange: BudgetRange,
  workHistory?: GetContractorProfileWorkHistory,
}

type WorkHistoryMenuItemConfig = {
  status: ProgressionStatus,
  disabled?: boolean,
  onSelect?: () => Promise<void> | void,
}

const ScreenWorkHistoryMenu = ({ budgetRange, workHistory }: ScreenWorkHistoryMenuProps) => {
  const uniqueSearch = `${new Date().getTime()}`

  const goToNewWorkHistory = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory.path)
  const goToWorkHistoryReferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences.path)
  const goToWorkHistoryPhotos = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos.path)

  const hasNoWorkHistory = workHistory === undefined

  const doesWorkHistoryHaveCompletedReference = alwaysArray(workHistory?.references).some(reference => reference.status === ProgressionStatus.Completed)
  const doesWorkHistoryHaveUnverifiedReference = alwaysArray(workHistory?.references).some(reference => reference.status === ProgressionStatus.WaitingOnWeaver)

  const workHistoryReferenceProgressStatus =
    doesWorkHistoryHaveCompletedReference
      ? ProgressionStatus.Completed
      : doesWorkHistoryHaveUnverifiedReference
        ? ProgressionStatus.WaitingOnWeaver
        : ProgressionStatus.WaitingOnUser

  const doesWorkHistoryHaveAnyPhotos = alwaysArray(workHistory?.photos).length > 0

  const workHistoryPhotoProgressStatus =
    doesWorkHistoryHaveAnyPhotos
      ? ProgressionStatus.Completed
      : ProgressionStatus.WaitingOnUser

  const menuItemConfig: Record<WorkHistoryMenuItem, WorkHistoryMenuItemConfig> = {
    [WorkHistoryMenuItem.AddPastProject]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.WaitingOnUser,
          onSelect: () => goToNewWorkHistory({ budgetRange }, uniqueSearch, 'root', 'replace')(),
        }
        : {
          status: ProgressionStatus.Completed,
          disabled: false, // Not clickable but not disabled
        },
    [WorkHistoryMenuItem.AddReference]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.NotStarted,
          disabled: true,
        }
        : {
          status: workHistoryReferenceProgressStatus,
          onSelect: () => workHistory && goToWorkHistoryReferences({ budgetRange, workHistoryId: workHistory?.id }, uniqueSearch, 'root', 'replace')(),
        },
    [WorkHistoryMenuItem.AddPhotos]:
      hasNoWorkHistory
        ? {
          status: ProgressionStatus.NotStarted,
          disabled: true,
        }
        : {
          status: workHistoryPhotoProgressStatus,
          onSelect: () => workHistory && goToWorkHistoryPhotos({ budgetRange, workHistoryId: workHistory?.id }, uniqueSearch, 'root', 'replace')(),
        },
  }

  const options = objectEntries(menuItemConfig).map(([ key, value ]) => ({
    key,
    title: pastProjectLabel[key],
    prefix: <IonIcon icon={progressionStatusIcons[key]} />,
    suffix: (
      <IonBadge className={Styles[createValidCssClassname(progressionStatusBadgeLabel[value.status])]} slot="end">
        {progressionStatusBadgeLabel[value.status]}
      </IonBadge>
    ),
    value: key,
    itemClassname: Styles[createValidCssClassname(progressionStatusBadgeLabel[value.status])],
    isDisabled: value.disabled,
  }))

  const wrapperFunction = (menuItem: WorkHistoryMenuItem) => {
    const maybeFn = menuItemConfig[menuItem].onSelect
    return maybeFn && maybeFn()
  }

  return <NestedMenu onSelect={(menuItem) => wrapperFunction(menuItem)} options={options} />
}

export default ScreenWorkHistoryMenu
