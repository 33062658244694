import React, { useCallback, useState } from 'react'
import { IonButton, IonIcon, IonToolbar, useIonAlert } from '@ionic/react'
import { useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useOnboardHomeownerTeam } from '../../hooks'
import { useMyIndividual, useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zWeaverAddressInput } from '../../../../../graphql/zod'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import ReactHookFormSingleInputPage from '../../pages/ReactHookFormSingleInputPage'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import { TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { homeownerSteps } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { useScreens } from '../../../../../common/hooks/useScreens'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import Styles from "./OnboardingHomeowner.module.scss"
import { pageConfig_Projects, useRouteTo, useRouteToOnboardingForTeam } from '../../../../../routes'
import { firstPartOfPostCode } from '../../../../../common/utils/addresses'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import IndicatorBar from '../../../../projects/CreateProjectPage/IndicatorBar'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import BrilliantScreen from '../../../../../common/components/BrilliantScreen/BrilliantScreen'

const zFormSchema = z.object({
  teamName: z.string().min(1, 'Required'),
  propertyAddress: zWeaverAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>

enum HomeownerOnboardingScreens {
  Introduction = "Introduction",
  SignupSteps = "SignupSteps",
  SignpostCompanyDetails = "SignpostCompanyDetails",
  TeamName = "TeamName",
  PropertyAddress = "PropertyAddress",
  Finished = "Finished",
}

const OnboardingHomeowner: React.FC = () => {
  const myIndividual = useMyIndividual()

  const { register, trigger, setValue, getValues, resetField, clearErrors, formState: { errors } } = useForm<FormSchema>({
    defaultValues: {
      teamName: `The ${myIndividual.familyName}'s`,
    },
    resolver: zodResolver(zFormSchema),
  })

  console.debug('[OnboardingHomeowner] Render ', { formValues: getValues() })

  const [ Screen, activeScreen ] = useScreens<HomeownerOnboardingScreens>({
    init: () => HomeownerOnboardingScreens.Introduction,
    screens: {
      Introduction: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)
          const switchToOnboardingTeam = useRouteToOnboardingForTeam()

          return <>
            <WeaverIonContent>
              <SuccessNotice
                caption="Brilliant!"
                message="You're almost ready to find builders for your project."
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={switchToOnboardingTeam}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SignupSteps: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <OnboardingSignupSteps
                caption="Sign up to Weaver"
                message="Completing these steps will allow you to find builders"
                steps={homeownerSteps}
                currentPageIndex={1}
                goToNextPage={changeToNextScreen}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SignpostCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <TitleWithBulletsPage
                title='Next enter your details'
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'Pick a name that builders on Weaver will see', icon: shieldCheckmark },
                  { description: 'Choose your address', icon: shieldCheckmark },
                ]}
              />,
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      TeamName: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const canGoForward = async () => {
            const isValid = await trigger([ 'teamName' ])
            isValid && changeToNextScreen()
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={0} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              <ReactHookFormSingleInputPage
                title='Give your team a name'
                inputLabel="Team name:"
                inputSubtext='This is the name clients will see on Weaver (You can change this later)'
                inputFieldProps={register('teamName')}
                inputFieldError={errors.teamName}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={canGoForward}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, errors, register ]),
      },

      PropertyAddress: {
        render: useCallback( ({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const onboardHomeownerTeamFn = useOnboardHomeownerTeam()
          const [ isSubmitting, setSubmitting ] = useState(false)
          const [ currentAddress, setCurrentAddress ] = useState(getValues('propertyAddress'))

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)

          const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('Onboarding_Company_Address_Selected')

          const [ present ] = useIonAlert()

          const submitIfFieldIsValid = async () => {
            const isValid = await trigger()
            if (!isValid) return

            setSubmitting(true)
            const value = getValues('propertyAddress')

            await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
              postCode: value.postCode,
              city: value.postTown ?? undefined,
              firstPartOfPostCode: firstPartOfPostCode(value.postCode) ?? undefined,
            })

            const data = getValues()

            try {
              const onboardResult = await onboardHomeownerTeamFn({
                teamName: data.teamName,
                propertyAddress: data.propertyAddress,
              })

              await setOnboardingCompleteMutation.mutateAsync({
                teamId: onboardResult.team.id,
                isOnboardingComplete: true,
              })

              changeToNextScreen()
            } catch (e) {
              if (e instanceof Error) {
                present({
                  header: "Failed to Onboard Homeowner Team",
                  message: e.message,
                  buttons: [
                    {
                      text: "Dismiss",
                      role: 'cancel',
                    },
                  ],
                })
              }
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              <PickAddress
                title="Enter your Property Address"
                value={currentAddress}
                hideFields={[ 'careOf', 'poBox' ]}
                setValue={propertyAddress => {
                  if (propertyAddress) {
                    setValue("propertyAddress", propertyAddress)
                    setCurrentAddress(propertyAddress)
                    clearErrors("propertyAddress")
                  } else {
                    resetField('propertyAddress')
                  }
                }}
                hasError={errors.propertyAddress}
                disabled={isSubmitting}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <SingleClickButton slot="end" onClick={submitIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, errors, getValues, setValue, resetField ]),
      },

      Finished: {
        render: useCallback(() => {
          const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Info_Complete')
          const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
          const routeToProjects = useRouteTo(pageConfig_Projects.path)

          return <>
            <WeaverIonContent>
              <BrilliantScreen
                title="Time to find some builders!"
                description="Now you're ready to add project details"
                actionButtonProps={{
                  onClick: async () => {
                    const formValues = getValues()

                    await triggerEventOnboardingCompanyComplete({
                      teamType: TeamType.Homeowner,
                      claimedTeamName: formValues.teamName,
                      claimedTeamType: TeamType.Homeowner,
                    })

                    await useMyIndividualInvalidateCacheFn()
                    routeToProjects({}, undefined, 'root', 'replace')()
                  },
                  children: <>Continue</>,
                }}
              />
            </WeaverIonContent>
          </>
        }, []),
      },
    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )

}

export default OnboardingHomeowner
