import React from 'react'
import { CreateProjectMutation } from '../../../graphql/generated'
import { getKnownRoutePathForPage, Page } from '../../../routes'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'

export type SuccessSlideProps = {
  createdProject: CreateProjectMutation["createProject"],
}

export const SuccessSlide: React.FC<SuccessSlideProps> = ({ createdProject: { id } }) => {

  const projectPath = getKnownRoutePathForPage(Page.ShowProject).replace(":id", id)

  return (
    <BrilliantScreen
      title='Brilliant'
      subtitle='Your project has been created.'
      description='You will hear from us as soon as we match you with the right contractor. Weaver will update you on the progress within 3 business days.'
      actionButtonProps={{
        href: projectPath,
        children: <>Continue</>,
        expand: 'block',
      }}/>
  )
}

export default SuccessSlide
