import React, { useEffect } from "react"
import { IonAlert } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, useRouteTo } from "../../../../../../routes"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { BudgetRange, useAwardContractorProfileBudgetRangeBadgeMutation } from "../../../../../../graphql/generated"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import AnimatedBudgetRangeBadgePage from "../../../ContractorBudgetReferences/AnimatedBudgetRangeBadgePage"
import { useHasContractorProfileBudgetRangeBadge, useShouldAwardContractorProfileBudgetRangeBadge } from "./hooks"
import { useMyContractorProfile } from "../../datasource"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"

const ContractorProfileBudgetRangeAwardBadgePage: React.FC = () => {
  const { budgetRange } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  // If I've already been awarded the badge, skip this page
  const hasContractorProfileBudgetRangeBadge = useHasContractorProfileBudgetRangeBadge(budgetRange)
  useEffect(() => {
    if (hasContractorProfileBudgetRangeBadge) {
      goToUnlockContractorBudgetRange({ budgetRange })()
    }
  }, [ hasContractorProfileBudgetRangeBadge ])

  // If I shouldn't award the badge, show an error
  const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)

  // Build the click function
  const profile = useMyContractorProfile()
  const isNotReadyToRenderView = profile.getContractorProfileQuery.isLoading

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const awardContractorProfileBudgetRangeBadgeMutation = useAwardContractorProfileBudgetRangeBadgeMutation(gqlDataSource)

  const triggerEvent_WorkHistory_References_Badge_Awarded = useAnalyticsEvent('WorkHistory_References_Badge_Awarded')

  const onClick = async () => {
    await awardContractorProfileBudgetRangeBadgeMutation.mutateAsync({ budgetRange })
    await profile.getContractorProfileQuery.refetch()

    await triggerEvent_WorkHistory_References_Badge_Awarded({
      budgetRange: budgetRange,
    })

    goToUnlockContractorBudgetRange({ budgetRange })()
  }

  // If I've not already been awarded the badge, show the animation and release the endophines!
  return isNotReadyToRenderView || hasContractorProfileBudgetRangeBadge
    ?  <WeaverIonPage>
      <WeaverIonContent>
        {null}
      </WeaverIonContent>
    </WeaverIonPage>
    : !shouldAwardContractorProfileBudgetRangeBadge
      ? (
        <WeaverIonPage>
          <WeaverIonContent>
            <IonAlert
              isOpen={true}
              header={'Oops! Cannot award badge!'}
            />
          </WeaverIonContent>
        </WeaverIonPage>
      )
      : (
        <WeaverIonPage>
          <WeaverIonContent>
            <ResponsiveContentWrapper>
              <AnimatedBudgetRangeBadgePage
                description='You have added a reference project in the'
                actionButtonProps={{
                  children: <>Continue</>,
                  expand: 'block',
                  size: 'large',
                }}
                budgetRange={budgetRange}
                onClick={onClick}
              />
            </ResponsiveContentWrapper>
          </WeaverIonContent>
        </WeaverIonPage>
      )
}

export default ContractorProfileBudgetRangeAwardBadgePage
