import React, { ComponentProps } from 'react'
import { IonButton, IonGrid, IonCol, IonRow } from '@ionic/react'
import RangeBadge from './RangeBadge'
import Styles from './AnimatedBudgetRangeBadgePage.module.scss'
import { budgetRangeLabels } from '../ContractorBudgetRanges/budgetRanges.i18n'
import { BudgetRange } from '../../../../graphql/generated'
import SingleClickButton from '../../../../common/components/SingleClickButton'

type AnimatedBudgetRangeBadgePageProps = {
  description: string,
  budgetRange: BudgetRange,
  actionButtonProps: ComponentProps<typeof IonButton>,
  onClick?: () => void,
}

const AnimatedBudgetRangeBadgePage: React.FC<AnimatedBudgetRangeBadgePageProps> = ({ budgetRange, description, actionButtonProps, onClick }) =>
  <IonGrid>
    <IonRow className='ion-justify-content-center ion-align-items-center'>
      <IonCol className='ion-margin-top ion-text-center'>
        <div className={Styles.fadeInUp}>
          <h5><b>You&apos;ve unlocked </b></h5>
          <h1><b>{budgetRangeLabels[budgetRange]} LEADS</b></h1>
          <span>{description}
            <br/>{budgetRangeLabels[budgetRange]} Range
          </span>
        </div>
        <div className={`${Styles.pulse} ${Styles.badge}`}>
          <RangeBadge budgetRange={budgetRange} />
        </div>
        <SingleClickButton onClick={onClick} {...actionButtonProps} className={`${Styles.continueBtn} ${actionButtonProps.className}`} />
      </IonCol>
    </IonRow>
  </IonGrid>

export default AnimatedBudgetRangeBadgePage
