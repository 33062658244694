import React, { useCallback, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonToolbar, useIonAlert } from '@ionic/react'
import { useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { prettifyCompanyName } from '../../companyNameHelpers'
import { useOnboardContractorTeam } from '../../hooks'
import { useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zWeaverAddressInput } from '../../../../../graphql/zod'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import CompanyDetailsFind from '../../pages/CompanyDetailsFind'
import CompanyDetailsEdit from '../../pages/CompanyDetailsEdit'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import CorporateStructureSelector from '../../../CorporateStructureSelector'
import { CorporateStructureType, TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { contractorSteps } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { pageConfig_Profile_ContractorLeadPreferences, pageConfig_Projects, useRouteTo, useRouteToOnboardingForTeam } from '../../../../../routes'
import { useScreensWithProps } from '../../../../../common/hooks/useScreens'
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import ContactSupportScreen from '../../../../../api/providers/ContactSupportProvider/ContactSupportScreen'
import ResponsiveContentWrapper from '../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'
import { firstPartOfPostCode } from '../../../../../common/utils/addresses'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import IndicatorBar from '../../../../projects/CreateProjectPage/IndicatorBar'
import Styles from "./OnboardingContractor.module.scss"
import { showIntercom } from '../../../../../common/utils/intercom'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import BrilliantScreen from '../../../../../common/components/BrilliantScreen/BrilliantScreen'
import { useInvite } from '../../../../../common/components/InviteClaimer/useStoredInvite'

enum ContractorOnboardingScreens {
  Introduction = "Introduction",
  SignupSteps = "SignupSteps",
  SignpostCompanyDetails = "SignpostCompanyDetails",
  SelectCorporateStructure = "SelectCorporateStructure",
  FindCompanyDetails = "FindCompanyDetails",
  EditCompanyDetails = "EditCompanyDetails",
  ConfirmCompanyAddress = "ConfirmCompanyAddress",
  Finished = "Finished",
  UnsupportedCorporateStructure = "UnsupportedCorporateStructure",
}

const zFormSchema = z.object({
  corporateStructure: z.enum([ CorporateStructureType.LimitedCompany, CorporateStructureType.SoleTrader, CorporateStructureType.Partnership, CorporateStructureType.Other ]),
  company: z.object({
    companyName: z.string(),
    companyNumber: z.string(),
    regAddress_CareOf: z.string().nullish(),
    regAddress_POBox: z.string().nullish(),
    regAddress_AddressLine1: z.string().nullish(),
    regAddress_AddressLine2: z.string().nullish(),
    regAddress_PostTown: z.string().nullish(),
    regAddress_County: z.string().nullish(),
    regAddress_PostCode: z.string().nullish(),
    regAddress_Country: z.string().nullish(),
  }),
  companyTradingAs: z.string().min(1, 'Required'),
  companyAddress: zWeaverAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>

const OnboardingContractor: React.FC = () => {
  const { register, trigger, setValue, getValues, resetField, clearErrors, formState: { errors } } = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  console.debug('[OnboardingContractor] Render ', { formValues: getValues() })

  type ScreenRenderProps = {
    register: typeof register,
    trigger: typeof trigger,
    getValues: typeof getValues,
    setValue: typeof setValue,
    resetField: typeof resetField,
    errors: typeof errors,
    prettifyCompanyName: typeof prettifyCompanyName,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<ContractorOnboardingScreens, ScreenRenderProps>({
    init: () => ContractorOnboardingScreens.Introduction,
    screenProps: {
      register, trigger, getValues, setValue, resetField, errors, prettifyCompanyName,
    },
    screens: {

      Introduction: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)
          const switchToOnboardingTeam = useRouteToOnboardingForTeam()

          return <>
            <WeaverIonContent>
              <SuccessNotice
                caption="Brilliant!"
                message="To start receiving tailored leads on Weaver, please complete the next few steps"
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={switchToOnboardingTeam}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SignupSteps: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <OnboardingSignupSteps
                caption="Sign up to Weaver"
                message="Completing these steps will allow you to receive tailored leads"
                steps={contractorSteps}
                currentPageIndex={1}
                goToNextPage={changeToNextScreen}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SignpostCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <TitleWithBulletsPage
                title='Next enter your company details'
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'Find your company name on Companies House Services', icon: shieldCheckmark },
                  { description: 'Confirm a trading name that clients on Weaver will see', icon: shieldCheckmark },
                ]}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SelectCorporateStructure: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen, trigger, getValues, setValue, resetField }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const changeToContactSupport = useChangeScreen(ContractorOnboardingScreens.UnsupportedCorporateStructure)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger('corporateStructure')
            if (!isValid) return

            const corporateStructure = getValues('corporateStructure')
            const contactSupport = corporateStructure !== CorporateStructureType.LimitedCompany

            if (contactSupport) {
              changeToContactSupport()
            } else {
              changeToNextScreen()
            }
          }

          return <>
            <WeaverIonContent>
              <CorporateStructureSelector
                value={getValues("corporateStructure")}
                setValue={async corporateStructure => {
                  if (corporateStructure) {
                    setValue("corporateStructure", corporateStructure)
                    await trigger([ 'corporateStructure' ])
                  } else {
                    resetField('corporateStructure')
                  }
                }}
                hasError={errors.corporateStructure !== undefined}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      FindCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen, trigger, getValues, setValue, resetField, errors, prettifyCompanyName }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'company', 'companyTradingAs' ])
            if (!isValid) return

            // Overwrite the address with what we have from companies house
            // (even if there's already an address as they've gone back in the screens)
            const selectedCompany = getValues('company')
            setValue('companyAddress', {
              careOf: selectedCompany.regAddress_CareOf,
              poBox: selectedCompany.regAddress_POBox,
              addressLine1: selectedCompany.regAddress_AddressLine1,
              addressLine2: selectedCompany.regAddress_AddressLine2,
              postTown: selectedCompany.regAddress_PostTown,
              county: selectedCompany.regAddress_County,
              postCode: selectedCompany.regAddress_PostCode ?? '',
              country: selectedCompany.regAddress_Country,
            })

            changeToNextScreen()
          }

          const resetCompanyDetailsState = () => {
            resetField('company')
            resetField('companyTradingAs')
          }

          const clearCompanyDetailErrors = () => clearErrors([ 'company', 'companyTradingAs' ])

          /* To ensure when navigating between screens we reset company info
           this is so when a user clicks back a reset is performed */
          useEffect(() => {
            resetCompanyDetailsState()
          }, [])

          return <>
            <WeaverIonContent scrollY={false}>
              <IndicatorBar currentPageIndex={1} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <CompanyDetailsFind
                value={getValues("company")}
                setValue={company => {
                  if (company) {
                    setValue("company", company)
                    setValue("companyTradingAs", prettifyCompanyName(company.companyName))
                  } else {
                    resetCompanyDetailsState()
                  }
                }}
                hasError={errors.companyTradingAs !== undefined || errors.company !== undefined}
                clearErrors={clearCompanyDetailErrors}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      EditCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen, trigger, getValues, register, errors }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'companyTradingAs' ])
            if (!isValid) return

            changeToNextScreen()
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={2} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <CompanyDetailsEdit
                company={getValues("company")}
                register={{
                  companyTradingAs: register('companyTradingAs'),
                }}
                errors={errors}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      ConfirmCompanyAddress: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen, trigger, getValues, setValue, resetField, errors }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const [ isSubmitting, setSubmitting ] = useState(false)
          const [ currentAddress, setCurrentAddress ] = useState(getValues('companyAddress'))

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
          const onboardContractorTeamFn = useOnboardContractorTeam()

          const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('Onboarding_Company_Address_Selected')

          const [ present ] = useIonAlert()

          const submitIfFieldIsValid = async () => {
            const isValid = await trigger()
            if (!isValid) return

            setSubmitting(true)
            const value = getValues('companyAddress')

            await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
              postCode: value.postCode,
              city: value.postTown ?? undefined,
              firstPartOfPostCode: firstPartOfPostCode(value.postCode) ?? undefined,
            })

            const data = getValues()

            try {
              const onboardResult = await onboardContractorTeamFn({
                companyNumber: data.company.companyNumber,
                companyRegisteredName: data.company.companyName,
                companyTradingAs: data.companyTradingAs,
                companyAddress: data.companyAddress,
              })

              await setOnboardingCompleteMutation.mutateAsync({
                teamId: onboardResult.team.id,
                isOnboardingComplete: true,
              })

              changeToNextScreen()
            } catch (e) {
              if (e instanceof Error) {
                present({
                  header: "Failed to Onboard Contractor Team",
                  message: e.message,
                  buttons: [
                    {
                      text: "Dismiss",
                      role: 'cancel',
                    },
                  ],
                })
              }
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={3} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <PickAddress
                title="Enter your Company Address"
                value={currentAddress}
                hideAutocomplete={true}
                hideFields={[ 'careOf', 'poBox' ]}
                setValue={companyAddress => {
                  if (companyAddress) {
                    setValue("companyAddress", companyAddress)
                    setCurrentAddress(companyAddress)
                    clearErrors('companyAddress')
                  } else {
                    resetField('companyAddress')
                  }
                }}
                hasError={errors.companyAddress}
                disabled={isSubmitting}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <SingleClickButton slot="end" onClick={submitIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      Finished: {
        render: useCallback(() => {
          const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Info_Complete')
          const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
          const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)
          const goToProjects = useRouteTo(pageConfig_Projects.path)
          const { hasInvite } = useInvite()

          const getNextPageFn = () => {
            // if the contractor onboards via an invite, they should be sent to the projects page after success
            // InviteClaimer should claim and remove the invite when the useMyIndividual hook returns an activeTeam
            if (hasInvite) {
              return goToProjects({}, undefined, "root", "replace")
            }
            return goToContractorLeadPreferences({}, undefined, 'root', 'replace')
          }

          return <>
            <WeaverIonContent>
              <BrilliantScreen
                title="Amazing!"
                description="Thank you for adding your company information."
                actionButtonProps={{
                  onClick: async () => {
                    const formValues = getValues()

                    await triggerEventOnboardingCompanyComplete({
                      teamType: TeamType.Contractor,
                      claimedTeamNumber: formValues.company.companyNumber,
                      claimedTeamName: formValues.companyTradingAs,
                      claimedTeamType: TeamType.Contractor,
                    })

                    const goToNextPage = getNextPageFn()
                    await useMyIndividualInvalidateCacheFn()
                    goToNextPage()

                  },
                  children: <>Continue</>,
                }}
              />
            </WeaverIonContent>
          </>
        }, []),
      },

      UnsupportedCorporateStructure: {
        render: useCallback(({ useChangeScreen }) => {
          const changeToSelectCorporateStructure = useChangeScreen(ContractorOnboardingScreens.SelectCorporateStructure)

          return <>
            <WeaverIonContent>
              <ResponsiveContentWrapper>
                <ContactSupportScreen />
              </ResponsiveContentWrapper>
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToSelectCorporateStructure}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={showIntercom}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default OnboardingContractor
