import React from 'react'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { RequirePhoneNumber } from './RequirePhoneNumberPage'

/**
 * Ensures that the Minimum Individual Profile is filled out by fingerprinting the Auth UserData and MyIndividual.
 * Guards against the rest of the app loading, with freedom to do localised routing.
 * Once the minimum threshold is met, passes the children through for rendering.
 * This component is a composition of others that meets the above criteria.
 *
 * Related LaunchDarkly flag: minimumIndividualProfile
 */
const MinimalIndividualProfile: React.FC = ({ children }) => {
  const flags = useWeaverFlags()

  return flags['minimum-individual-profile']?.enabled
    ? (
      <RequirePhoneNumber>
        {children}
      </RequirePhoneNumber>
    )
    : <>{children}</>
}

export default MinimalIndividualProfile
