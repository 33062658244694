import React, { useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAuthContext, WeaverIdTokenClaims } from '../../api/providers/AuthProvider'
import TitleWithBulletsPage from '../../domains/onboarding/team/pages/TitleWithBulletsPage'
import builderPng from '../../assets/images/builder.png'
import { arrowForwardOutline, phonePortrait } from 'ionicons/icons'
import MobileNumberCapture from '../../common/components/MobileNumberCapture'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useSetIndividualPhoneNumberMutation } from '../../graphql/generated'
import { useGraphQLDataSource } from '../../api/graphql'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { useScreensWithProps } from '../../common/hooks/useScreens'
import { IonButton, IonIcon, IonToolbar } from '@ionic/react'
import Styles from "./RequirePhoneNumberPage.module.scss"
import { pageConfig_Projects, useRouteTo } from '../../routes'
import SingleClickButton from '../../common/components/SingleClickButton'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonFooter from '../../common/components/WeaverIonWrappers/WeaverIonFooter'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'

// Check if aspect of 'minimum-individual-profile' been disabled
const useIsFlagEnabled = () => !useWeaverFlags()['minimum-individual-profile'].disableAspect?.['require-phone-number']

export const RequirePhoneNumber: React.FC = ({ children }) => {
  const isFlagEnabled = useIsFlagEnabled()
  const auth = useAuthContext()

  const phoneNumber = auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber]
  console.debug('[MinimalIndividualProfile.RequirePhoneNumber] Phone number: ', { auth, phoneNumber })

  return isFlagEnabled && phoneNumber == null
    ? <CollectPhoneNumber />
    : <>{children}</>
}

const zFormSchema = z.object({
  phoneNumber: z.string(),
}).superRefine((validated, ctx) => {
  if (!isPossiblePhoneNumber(validated.phoneNumber)) {
    ctx.addIssue({
      path: [ "phoneNumber" ],
      code: z.ZodIssueCode.custom,
      message: "A valid Phone Number is required",
    })
  }
})

type FormSchema = z.infer<typeof zFormSchema>

enum RequiredPhoneNumberScreen {
  Introduction = "Introduction",
  CollectPhoneNumber = "CollectPhoneNumber",
}

const CollectPhoneNumber: React.FC = () => {
  // Form Setup
  const form = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  // Mutations
  const auth = useAuthContext()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setIndividualPhoneNumberMutation = useSetIndividualPhoneNumberMutation(gqlDataSource)

  const triggerEvent = useAnalyticsEvent('Onboarding_Contact_Number_Entered')

  const routeToProjects = useRouteTo(pageConfig_Projects.path)

  // Handle Submission
  const onSubmit = async (formState: FormSchema): Promise<void> => {
    // This sets the Phone Number in Auth0, making it available in the tokens the next time they're generated
    console.debug('[RequirePhoneNumber.onSubmit] Setting the individual phone number: ', { formState })
    await setIndividualPhoneNumberMutation.mutateAsync(formState)

    console.debug('[RequirePhoneNumber.onSubmit] Firing the event')
    await triggerEvent({
      // country code can be between 2(USA) & 5 characters (British Virgin Islands)
      countryCode: formState.phoneNumber.slice(0,5),
      // should inclusion of country code and next 4 digits (based off the UK)
      areaCode: formState.phoneNumber.slice(0,7),
    })

    // Trigger auth token refresh
    console.debug('[RequirePhoneNumber.onSubmit] Refreshing auth')
    await auth.forceRefresh()

    // Switch to Home to cause a redirection
    console.debug('[RequirePhoneNumber.onSubmit] Redirect to Projects')
    routeToProjects({}, undefined, 'root', 'replace')()
  }

  type ScreenProps = {
    form: typeof form,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<RequiredPhoneNumberScreen, ScreenProps>({
    init: () => RequiredPhoneNumberScreen.Introduction,
    screenProps: {
      form,
    },
    screens: {

      Introduction: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <TitleWithBulletsPage
                title="Thanks for Registering!"
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'We need your phone number to verify your account.', icon: phonePortrait },
                ]}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      CollectPhoneNumber: {
        render: useCallback(({ form }) => {
          const [ clickInProgress, setClickInProgress ] = useState(false)

          const onClickNext = async () => {
            setClickInProgress(true)

            console.debug('[RequirePhoneNumber.CollectPhoneNumber.onClickNext] Check form state')
            const isValid = await form.trigger()
            if (!isValid) {
              console.debug('[RequirePhoneNumber.CollectPhoneNumber.onClickNext] Validity: ', { isValid })
              setClickInProgress(false)
              return
            }

            console.debug('[RequirePhoneNumber.CollectPhoneNumber.onClickNext] Submit!')
            await onSubmit(form.getValues())
          }

          return <>
            <WeaverIonContent className='ion-padding'>
              <Controller
                control={form.control}
                name="phoneNumber"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MobileNumberCapture
                    value={value}
                    onChange={onChange}
                    disabled={clickInProgress}
                    error={error}
                    errorMessage={'A valid Phone Number is required.'}
                  />
                )}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <SingleClickButton slot="end" onClick={onClickNext}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}
