import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json.data
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSDate: string,
  AWSDateTime: string,
  AWSEmail: string,
  AWSIPAddress: string,
  AWSJSON: string,
  AWSPhone: string,
  AWSTime: string,
  AWSTimestamp: number,
  AWSURL: string,
};

export type AddProjectSlotInput = {
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};

export type ArchitectProfile = {
  __typename?: 'ArchitectProfile',
  companyAddress?: Maybe<WeaverAddress>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type AssignProjectToWeaverUserInput = {
  projectId: Scalars['ID'],
  weaverTeamId: Scalars['ID'],
};

export enum BudgetCategory {
  Exact = 'Exact',
  NoIdea = 'NoIdea',
  RoughIdea = 'RoughIdea'
}

export enum BudgetRange {
  F30T100 = 'F30T100',
  F100T500 = 'F100T500',
  F500T1000 = 'F500T1000',
  F1000T3000 = 'F1000T3000'
}

export type CachedWeaverCoordinates = {
  __typename?: 'CachedWeaverCoordinates',
  address: WeaverAddress,
  createdAt: Scalars['AWSDateTime'],
  exactCoordinates: WeaverCoordinates,
  fudgedCoordinates: WeaverCoordinates,
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoom = {
  __typename?: 'ChatRoom',
  createdAt: Scalars['AWSDateTime'],
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  individualConnections: Array<IndividualChatRoomConnection>,
  lastMessageAt: Scalars['AWSDateTime'],
  messages?: Maybe<Array<ChatRoomMessage>>,
  name?: Maybe<Scalars['String']>,
  teamConnections: Array<TeamChatRoomConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type ChatRoomMessage = {
  __typename?: 'ChatRoomMessage',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  content: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  documents?: Maybe<Array<UploadedFile>>,
  id: Scalars['ID'],
  idFromClient: Scalars['ID'],
  readAt?: Maybe<Scalars['AWSDateTime']>,
  receivedByIndividual: Individual,
  receivedByIndividualId: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
  sentByIndividual: Individual,
  sentByIndividualId: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
};

export type CompaniesHouseResponse = {
  __typename?: 'CompaniesHouseResponse',
  apiHealthy: Scalars['Boolean'],
  results: Array<CompanySummary>,
};

export type CompanySummary = {
  __typename?: 'CompanySummary',
  accounts_AccountCategory?: Maybe<Scalars['String']>,
  accounts_AccountRefDay?: Maybe<Scalars['String']>,
  accounts_AccountRefMonth?: Maybe<Scalars['String']>,
  accounts_LastMadeUpDate?: Maybe<Scalars['String']>,
  accounts_NextDueDate?: Maybe<Scalars['String']>,
  companyCategory?: Maybe<Scalars['String']>,
  companyName: Scalars['String'],
  companyNumber: Scalars['String'],
  companyStatus?: Maybe<Scalars['String']>,
  confStmtLastMadeUpDate?: Maybe<Scalars['String']>,
  confStmtNextDueDate?: Maybe<Scalars['String']>,
  countryOfOrigin?: Maybe<Scalars['String']>,
  dissolutionDate?: Maybe<Scalars['String']>,
  incorporationDate?: Maybe<Scalars['String']>,
  limitedPartnerships_NumGenPartners?: Maybe<Scalars['String']>,
  limitedPartnerships_NumLimPartners?: Maybe<Scalars['String']>,
  mortgages_NumMortCharges?: Maybe<Scalars['String']>,
  mortgages_NumMortOutstanding?: Maybe<Scalars['String']>,
  mortgages_NumMortPartSatisfied?: Maybe<Scalars['String']>,
  mortgages_NumMortSatisfied?: Maybe<Scalars['String']>,
  previousName_1_CONDATE?: Maybe<Scalars['String']>,
  previousName_1_CompanyName?: Maybe<Scalars['String']>,
  previousName_2_CONDATE?: Maybe<Scalars['String']>,
  previousName_2_CompanyName?: Maybe<Scalars['String']>,
  previousName_3_CONDATE?: Maybe<Scalars['String']>,
  previousName_3_CompanyName?: Maybe<Scalars['String']>,
  previousName_4_CONDATE?: Maybe<Scalars['String']>,
  previousName_4_CompanyName?: Maybe<Scalars['String']>,
  previousName_5_CONDATE?: Maybe<Scalars['String']>,
  previousName_5_CompanyName?: Maybe<Scalars['String']>,
  previousName_6_CONDATE?: Maybe<Scalars['String']>,
  previousName_6_CompanyName?: Maybe<Scalars['String']>,
  previousName_7_CONDATE?: Maybe<Scalars['String']>,
  previousName_7_CompanyName?: Maybe<Scalars['String']>,
  previousName_8_CONDATE?: Maybe<Scalars['String']>,
  previousName_8_CompanyName?: Maybe<Scalars['String']>,
  previousName_9_CONDATE?: Maybe<Scalars['String']>,
  previousName_9_CompanyName?: Maybe<Scalars['String']>,
  previousName_50_CONDATE?: Maybe<Scalars['String']>,
  previousName_50_CompanyName?: Maybe<Scalars['String']>,
  regAddress_AddressLine1?: Maybe<Scalars['String']>,
  regAddress_AddressLine2?: Maybe<Scalars['String']>,
  regAddress_CareOf?: Maybe<Scalars['String']>,
  regAddress_Country?: Maybe<Scalars['String']>,
  regAddress_County?: Maybe<Scalars['String']>,
  regAddress_POBox?: Maybe<Scalars['String']>,
  regAddress_PostCode?: Maybe<Scalars['String']>,
  regAddress_PostTown?: Maybe<Scalars['String']>,
  returns_LastMadeUpDate?: Maybe<Scalars['String']>,
  returns_NextDueDate?: Maybe<Scalars['String']>,
  sicCode_SicText_1?: Maybe<Scalars['String']>,
  sicCode_SicText_2?: Maybe<Scalars['String']>,
  sicCode_SicText_3?: Maybe<Scalars['String']>,
  sicCode_SicText_4?: Maybe<Scalars['String']>,
  uri?: Maybe<Scalars['String']>,
};

export enum ContentDisposition {
  Attachment = 'Attachment',
  Inline = 'Inline'
}

export enum ContractorCompanyCheckProgress {
  Fail = 'Fail',
  NotChecked = 'NotChecked',
  Success = 'Success'
}

export type ContractorCompanyCheckStatus = {
  __typename?: 'ContractorCompanyCheckStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorInvite = {
  companyName: Scalars['String'],
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phone: Scalars['String'],
};

export type ContractorProfile = {
  __typename?: 'ContractorProfile',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedLeads?: Maybe<Scalars['Int']>,
  budgetRangeBadges?: Maybe<Array<ContractorProfileBudgetRangeBadge>>,
  budgetRangeSubscriptions?: Maybe<Array<ContractorProfileBudgetRangeSubscription>>,
  companyAddress?: Maybe<WeaverAddress>,
  companyCheckStatus?: Maybe<ContractorCompanyCheckStatus>,
  companyCheckStatusId?: Maybe<Scalars['ID']>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  locationAreas?: Maybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: Maybe<Event>,
  rejectedLeads?: Maybe<Scalars['Int']>,
  status?: Maybe<ContractorStatus>,
  statusId?: Maybe<Scalars['ID']>,
  updatedAt: Scalars['AWSDateTime'],
  workHistory?: Maybe<Array<ContractorProfileWorkHistory>>,
};

export type ContractorProfileBudgetRangeBadge = {
  __typename?: 'ContractorProfileBudgetRangeBadge',
  awardedAt: Scalars['AWSDateTime'],
  awardedForWorkHistoryId: Scalars['ID'],
  budgetRange: BudgetRange,
  id: Scalars['ID'],
};

export type ContractorProfileBudgetRangeSubscription = {
  __typename?: 'ContractorProfileBudgetRangeSubscription',
  budgetRange: BudgetRange,
  id: Scalars['ID'],
};

export type ContractorProfileLookup = {
  __typename?: 'ContractorProfileLookup',
  contractorProfile?: Maybe<ContractorProfile>,
  contractorProfileId?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
  workHistory?: Maybe<ContractorProfileWorkHistory>,
  workHistoryId?: Maybe<Scalars['ID']>,
  workHistoryReference?: Maybe<ContractorProfileWorkHistoryReference>,
  workHistoryReferenceId?: Maybe<Scalars['ID']>,
};

export type ContractorProfileLookupInput = {
  contractorProfileId?: InputMaybe<Scalars['ID']>,
  workHistoryId?: InputMaybe<Scalars['ID']>,
  workHistoryReferenceId?: InputMaybe<Scalars['ID']>,
};

export type ContractorProfileNote = OpsNote & {
  __typename?: 'ContractorProfileNote',
  body: Scalars['String'],
  contractorProfileId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorProfileWorkHistory = {
  __typename?: 'ContractorProfileWorkHistory',
  constructionValue?: Maybe<Money>,
  id: Scalars['ID'],
  photoIds?: Maybe<Array<Scalars['String']>>,
  photos?: Maybe<Array<UploadedFile>>,
  projectAddress?: Maybe<WeaverAddress>,
  projectTypes?: Maybe<Array<ProjectType>>,
  references?: Maybe<Array<ContractorProfileWorkHistoryReference>>,
};

export type ContractorProfileWorkHistoryReference = {
  __typename?: 'ContractorProfileWorkHistoryReference',
  _notesCount?: Maybe<Scalars['Int']>,
  companyName?: Maybe<Scalars['String']>,
  contractorProfileWorkHistoryReferenceStatusId?: Maybe<Scalars['ID']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  phoneNumber: Scalars['String'],
  status: ProgressionStatus,
  teamType: TeamType,
};

export type ContractorProfileWorkHistoryReferenceNote = OpsNote & {
  __typename?: 'ContractorProfileWorkHistoryReferenceNote',
  body: Scalars['String'],
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ContractorProfileWorkHistoryReferenceStatus = {
  __typename?: 'ContractorProfileWorkHistoryReferenceStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ProgressionStatus,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ContractorProgress {
  Fail = 'Fail',
  NotChecked = 'NotChecked',
  Success = 'Success'
}

export type ContractorStatus = {
  __typename?: 'ContractorStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export enum CorporateStructureType {
  LimitedCompany = 'LimitedCompany',
  Other = 'Other',
  Partnership = 'Partnership',
  SoleTrader = 'SoleTrader'
}

export type CreateArchitectProfile = {
  architectTeamId: Scalars['ID'],
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
};

export type CreateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  name: Scalars['String'],
  teamIds: Array<Scalars['ID']>,
};

export type CreateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyNumber: Scalars['String'],
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: InputMaybe<Scalars['String']>,
  contractorTeamId: Scalars['ID'],
};

export type CreateContractorProfileWorkHistory = {
  constructionValue: MoneyInput,
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes: Array<ProjectType>,
};

export type CreateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  phoneNumber: Scalars['String'],
  teamType: TeamType,
};

export type CreateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: CreateContractorProfileWorkHistoryReference,
};

export type CreateHomeownerProfile = {
  homeownerTeamId: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type CreateNoteInput = {
  text: Scalars['String'],
  title: Scalars['String'],
};

export type CreateProjectInput = {
  address: WeaverAddressInput,
  budgetCategory: Scalars['String'],
  budgetValue: MoneyInput,
  contractorInvites: Array<ContractorInvite>,
  description: Scalars['String'],
  projectTypes: Array<ProjectType>,
  tenderReturnDate: Scalars['AWSDateTime'],
  totalContractors: Scalars['Int'],
  workStartEstimate: WorkStartEstimate,
};

export type CreateProjectTaskInput = {
  actionableType: Scalars['String'],
  assignedTeamId?: InputMaybe<Scalars['ID']>,
  dueAt?: InputMaybe<Scalars['AWSDateTime']>,
  icon?: InputMaybe<Scalars['String']>,
  order: Scalars['Int'],
  parentTaskId?: InputMaybe<Scalars['ID']>,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  url?: InputMaybe<Scalars['String']>,
};

export type CreateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  fileContentType?: InputMaybe<Scalars['String']>,
  fileName?: InputMaybe<Scalars['String']>,
  fileSizeInBytes?: InputMaybe<Scalars['Int']>,
  tags?: InputMaybe<Array<CreateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type CreateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type DownloadConfigInput = {
  disposition?: InputMaybe<ContentDisposition>,
  transformation?: InputMaybe<FileTransformationInput>,
};

export type Event = {
  __typename?: 'Event',
  actionedAt?: Maybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: Maybe<Scalars['ID']>,
};

export type EventInput = {
  actionedAt?: InputMaybe<Scalars['AWSDateTime']>,
  actionedByIndividualId?: InputMaybe<Scalars['ID']>,
};

export type FileTransformation = {
  __typename?: 'FileTransformation',
  height?: Maybe<Scalars['Int']>,
  ignoreAspectRatio?: Maybe<Scalars['Boolean']>,
  s3SubKey?: Maybe<Scalars['String']>,
  transformedContentType?: Maybe<Scalars['String']>,
  transformedExtension?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
};

export type FileTransformationInput = {
  height?: InputMaybe<Scalars['Int']>,
  ignoreAspectRatio?: InputMaybe<Scalars['Boolean']>,
  width?: InputMaybe<Scalars['Int']>,
};

export type HomeownerProfile = {
  __typename?: 'HomeownerProfile',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  propertyAddress?: Maybe<WeaverAddress>,
  teamName?: Maybe<Scalars['String']>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IncrementChatRoomMessageDocumentCount = {
  chatRoomMessageId: Scalars['ID'],
  documentCount: Scalars['Int'],
};

export type IncrementProjectDocumentCount = {
  documentCount: Scalars['Int'],
  projectId: Scalars['ID'],
};

export type Individual = {
  __typename?: 'Individual',
  chatRoomConnections: Array<IndividualChatRoomConnection>,
  createdAt: Scalars['AWSDateTime'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  lastSyncedAt: Scalars['AWSDateTime'],
  pictureURL: Scalars['String'],
  teamConnections: Array<IndividualTeamConnection>,
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualChatRoomConnection = {
  __typename?: 'IndividualChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type IndividualTeamConnection = {
  __typename?: 'IndividualTeamConnection',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type InviteToMyProjectInput = {
  companyName?: InputMaybe<Scalars['String']>,
  email?: InputMaybe<Scalars['String']>,
  familyName?: InputMaybe<Scalars['String']>,
  givenName?: InputMaybe<Scalars['String']>,
  phone?: InputMaybe<Scalars['String']>,
  projectId: Scalars['ID'],
};

export enum LambdaResolver {
  Google = 'Google',
  NotesRo = 'NotesRO',
  NotesRw = 'NotesRW',
  Primary = 'Primary'
}

export type Lead = {
  __typename?: 'Lead',
  approximateLocation: Scalars['String'],
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  description: Scalars['String'],
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  projectTypes: Array<ProjectType>,
  referral?: Maybe<Referral>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  workStartEstimate: WorkStartEstimate,
};

export type ListAllContractorProfilesFilter = {
  companyNameContains?: InputMaybe<Scalars['String']>,
};

export type ListAllOpsWorkHistoryReferencesFilter = {
  idEquals?: InputMaybe<Scalars['String']>,
  nameContains?: InputMaybe<Scalars['String']>,
};

export type ListAllProjectsFilter = {
  assigneeTeamIdEquals?: InputMaybe<Scalars['ID']>,
  titleContains?: InputMaybe<Scalars['String']>,
  typeEquals?: InputMaybe<Scalars['String']>,
};

export enum LocationArea {
  LondonCentral = 'LondonCentral',
  LondonEast = 'LondonEast',
  LondonNorth = 'LondonNorth',
  LondonSouth = 'LondonSouth',
  LondonWest = 'LondonWest'
}

export type MessageSentInfo = {
  __typename?: 'MessageSentInfo',
  recipients?: Maybe<Array<Maybe<MessageSentInfoRecipient>>>,
};

export type MessageSentInfoRecipient = {
  __typename?: 'MessageSentInfoRecipient',
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  individualId: Scalars['ID'],
  teamId: Scalars['ID'],
};

export type Money = {
  __typename?: 'Money',
  amountInPence: Scalars['String'],
  currency: Currency,
};

export type MoneyInput = {
  amountInPence: Scalars['String'],
  currency: Currency,
};

export type Mutation = {
  __typename?: 'Mutation',
  acceptLead: Scalars['Boolean'],
  addContractorCompanyCheckStatus: ContractorCompanyCheckStatus,
  addContractorProfileNote: Scalars['Boolean'],
  addContractorProfileWorkHistoryReferenceNote: Scalars['Boolean'],
  addContractorProfileWorkHistoryReferenceStatus: ContractorProfileWorkHistoryReferenceStatus,
  addContractorStatus: ContractorStatus,
  addProjectInvite: Scalars['Boolean'],
  addProjectMember: Scalars['Boolean'],
  addProjectNote: Scalars['Boolean'],
  addProjectSlot: Scalars['Boolean'],
  addUploadedFileToProjectFiles?: Maybe<UploadedFile>,
  addUploadedFilesToChatRoomMessage?: Maybe<UploadedFile>,
  assignProjectToWeaverUser: Scalars['Boolean'],
  awardContractorProfileBudgetRangeBadge: Scalars['Boolean'],
  claimCompanyTeam: Team,
  claimInvite: Scalars['Boolean'],
  claimNonCompanyTeam: Team,
  clearStripeProductConfigCache: Scalars['Boolean'],
  createArchitectProfile: ArchitectProfile,
  createChatRoom: ChatRoom,
  createContractorProfile: ContractorProfile,
  createContractorProfileWorkHistory: ContractorProfileWorkHistory,
  createContractorProfileWorkHistoryPhoto?: Maybe<UploadedFile>,
  createContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  createHomeownerProfile: HomeownerProfile,
  createNewProjectStatus: Scalars['Boolean'],
  createNote?: Maybe<Note>,
  createProject: Project,
  createProjectTask: Scalars['Boolean'],
  createStripeSessionForLeadPayment: StripeSessionResponse,
  createUploadedFile: UploadedFile,
  deleteNote?: Maybe<Note>,
  flushCachesFrontendSPA: Scalars['Boolean'],
  incrementChatRoomMessageDocumentCount: Scalars['Boolean'],
  incrementProjectDocumentCount: Scalars['Boolean'],
  inviteToMyProject: ProjectMemberInvite,
  joinChatRoom?: Maybe<ChatRoom>,
  joinChatRoomForIndividual?: Maybe<ChatRoom>,
  joinWeaverTeam: Team,
  processProjectAction: Scalars['Boolean'],
  registerMyDeviceForPushNotifications: PushNotificationDevice,
  rejectLead: Scalars['Boolean'],
  removeIndividualFromTeam: Scalars['Boolean'],
  removeProjectInvite: Scalars['Boolean'],
  removeProjectMember: Scalars['Boolean'],
  removeProjectSlot: Scalars['Boolean'],
  removeUploadedFileTags: UploadedFile,
  resetContractorProfileWorkHistory: Scalars['Boolean'],
  selectContractorCompanyCheckStatus: Scalars['Boolean'],
  selectContractorProfileWorkHistoryReferenceStatus: Scalars['Boolean'],
  selectContractorStatus: Scalars['Boolean'],
  selectProjectStatus: Scalars['Boolean'],
  sendChatRoomMessage?: Maybe<MessageSentInfo>,
  setIndividualPhoneNumber: Scalars['Boolean'],
  setOnboardingComplete: Scalars['Boolean'],
  triggerErrorForSentry: Scalars['Boolean'],
  updateArchitectProfile: ArchitectProfile,
  updateChatRoom?: Maybe<ChatRoom>,
  updateContractorCompanyCheckStatus: Scalars['Boolean'],
  updateContractorProfile: ContractorProfile,
  updateContractorProfileWorkHistory: ContractorProfileWorkHistory,
  updateContractorProfileWorkHistoryReference: ContractorProfileWorkHistoryReference,
  updateContractorProfileWorkHistoryReferenceStatus: Scalars['Boolean'],
  updateContractorStatus: Scalars['Boolean'],
  updateHomeownerProfile: HomeownerProfile,
  updateNote?: Maybe<Note>,
  updateProjectStatus: Scalars['Boolean'],
  updateProjectTaskStatus: Scalars['Boolean'],
  updateProjectWorkflow: Scalars['Boolean'],
  updateUploadedFile: UploadedFile,
};

export type MutationAcceptLeadArgs = {
  projectId: Scalars['ID'],
};

export type MutationAddContractorCompanyCheckStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
};

export type MutationAddContractorProfileNoteArgs = {
  body: Scalars['String'],
  contractorProfileId: Scalars['ID'],
};

export type MutationAddContractorProfileWorkHistoryReferenceNoteArgs = {
  body: Scalars['String'],
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
};

export type MutationAddContractorProfileWorkHistoryReferenceStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProgressionStatus,
};

export type MutationAddContractorStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ContractorProgress,
};

export type MutationAddProjectInviteArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};

export type MutationAddProjectMemberArgs = {
  projectId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
  teamId: Scalars['ID'],
};

export type MutationAddProjectNoteArgs = {
  body: Scalars['String'],
  projectId: Scalars['ID'],
};

export type MutationAddProjectSlotArgs = {
  input: AddProjectSlotInput,
};

export type MutationAddUploadedFileToProjectFilesArgs = {
  projectId: Scalars['ID'],
  uploadedFileId: Scalars['ID'],
};

export type MutationAddUploadedFilesToChatRoomMessageArgs = {
  chatRoomMessageId: Scalars['ID'],
  uploadedFileIds: Array<Scalars['ID']>,
};

export type MutationAssignProjectToWeaverUserArgs = {
  input?: InputMaybe<AssignProjectToWeaverUserInput>,
};

export type MutationAwardContractorProfileBudgetRangeBadgeArgs = {
  budgetRange: BudgetRange,
};

export type MutationClaimCompanyTeamArgs = {
  companyNumber: Scalars['String'],
  name: Scalars['String'],
  referringInviteId?: InputMaybe<Scalars['ID']>,
  type: TeamType,
};

export type MutationClaimInviteArgs = {
  inviteId: Scalars['ID'],
};

export type MutationClaimNonCompanyTeamArgs = {
  name: Scalars['String'],
  referringInviteId?: InputMaybe<Scalars['ID']>,
  type: TeamType,
};

export type MutationCreateArchitectProfileArgs = {
  input: CreateArchitectProfile,
};

export type MutationCreateChatRoomArgs = {
  input: CreateChatRoom,
};

export type MutationCreateContractorProfileArgs = {
  input: CreateContractorProfile,
};

export type MutationCreateContractorProfileWorkHistoryArgs = {
  input: CreateContractorProfileWorkHistory,
};

export type MutationCreateContractorProfileWorkHistoryPhotoArgs = {
  uploadedFileId: Scalars['ID'],
  workHistoryId: Scalars['ID'],
};

export type MutationCreateContractorProfileWorkHistoryReferenceArgs = {
  input: CreateContractorProfileWorkHistoryReferenceForWorkHistory,
};

export type MutationCreateHomeownerProfileArgs = {
  input: CreateHomeownerProfile,
};

export type MutationCreateNewProjectStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
};

export type MutationCreateNoteArgs = {
  note: CreateNoteInput,
};

export type MutationCreateProjectArgs = {
  project: CreateProjectInput,
};

export type MutationCreateProjectTaskArgs = {
  input: CreateProjectTaskInput,
};

export type MutationCreateStripeSessionForLeadPaymentArgs = {
  leadId: Scalars['ID'],
  product: StripeProduct,
  redirectBaseURL: Scalars['String'],
};

export type MutationCreateUploadedFileArgs = {
  input: CreateUploadedFileInput,
};

export type MutationDeleteNoteArgs = {
  id: Scalars['ID'],
};

export type MutationIncrementChatRoomMessageDocumentCountArgs = {
  input: IncrementChatRoomMessageDocumentCount,
};

export type MutationIncrementProjectDocumentCountArgs = {
  input: IncrementProjectDocumentCount,
};

export type MutationInviteToMyProjectArgs = {
  input: InviteToMyProjectInput,
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
};

export type MutationJoinChatRoomArgs = {
  chatRoomId: Scalars['ID'],
};

export type MutationJoinChatRoomForIndividualArgs = {
  chatRoomId: Scalars['ID'],
  individualId: Scalars['ID'],
};

export type MutationProcessProjectActionArgs = {
  action: ProjectActionInput,
};

export type MutationRegisterMyDeviceForPushNotificationsArgs = {
  deviceToken: Scalars['String'],
};

export type MutationRejectLeadArgs = {
  projectId: Scalars['ID'],
};

export type MutationRemoveIndividualFromTeamArgs = {
  individualId: Scalars['String'],
  teamId: Scalars['String'],
};

export type MutationRemoveProjectInviteArgs = {
  id: Scalars['ID'],
};

export type MutationRemoveProjectMemberArgs = {
  id: Scalars['ID'],
};

export type MutationRemoveProjectSlotArgs = {
  id: Scalars['ID'],
};

export type MutationRemoveUploadedFileTagsArgs = {
  id: Scalars['ID'],
};

export type MutationSelectContractorCompanyCheckStatusArgs = {
  contractorCompanyCheckStatusId: Scalars['ID'],
  contractorProfileId: Scalars['ID'],
};

export type MutationSelectContractorProfileWorkHistoryReferenceStatusArgs = {
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
  contractorProfileWorkHistoryReferenceStatusId: Scalars['ID'],
};

export type MutationSelectContractorStatusArgs = {
  contractorProfileId: Scalars['ID'],
  contractorStatusId: Scalars['ID'],
};

export type MutationSelectProjectStatusArgs = {
  parentId: Scalars['ID'],
  statusId: Scalars['ID'],
};

export type MutationSendChatRoomMessageArgs = {
  message: SendChatRoomMessage,
};

export type MutationSetIndividualPhoneNumberArgs = {
  individualId?: InputMaybe<Scalars['ID']>,
  phoneNumber: Scalars['String'],
};

export type MutationSetOnboardingCompleteArgs = {
  isOnboardingComplete: Scalars['Boolean'],
  teamId: Scalars['String'],
};

export type MutationUpdateArchitectProfileArgs = {
  input: UpdateArchitectProfile,
};

export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoom,
};

export type MutationUpdateContractorCompanyCheckStatusArgs = {
  color: Scalars['String'],
  contractorCompanyCheckStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorCompanyCheckProgress,
};

export type MutationUpdateContractorProfileArgs = {
  input: UpdateContractorProfile,
};

export type MutationUpdateContractorProfileWorkHistoryArgs = {
  input: UpdateContractorProfileWorkHistory,
};

export type MutationUpdateContractorProfileWorkHistoryReferenceArgs = {
  input: UpdateContractorProfileWorkHistoryReferenceForWorkHistory,
};

export type MutationUpdateContractorProfileWorkHistoryReferenceStatusArgs = {
  color: Scalars['String'],
  contractorProfileWorkHistoryReferenceStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ProgressionStatus,
};

export type MutationUpdateContractorStatusArgs = {
  color: Scalars['String'],
  contractorStatusId: Scalars['ID'],
  label: Scalars['String'],
  progress: ContractorProgress,
};

export type MutationUpdateHomeownerProfileArgs = {
  input: UpdateHomeownerProfile,
};

export type MutationUpdateNoteArgs = {
  note: UpdateNoteInput,
};

export type MutationUpdateProjectStatusArgs = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
  statusId: Scalars['ID'],
};

export type MutationUpdateProjectTaskStatusArgs = {
  input: UpdateProjectTaskStatus,
};

export type MutationUpdateProjectWorkflowArgs = {
  input: UpdateProjectWorkflowInput,
};

export type MutationUpdateUploadedFileArgs = {
  input: UpdateUploadedFileInput,
};

export type Note = {
  __typename?: 'Note',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  text: Scalars['String'],
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
};

export type OpsNote = {
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type OpsWorkHistoryReference = {
  __typename?: 'OpsWorkHistoryReference',
  _notesCount?: Maybe<Scalars['Int']>,
  companyName?: Maybe<Scalars['String']>,
  companyRegisteredName: Scalars['String'],
  companyTradingAs?: Maybe<Scalars['String']>,
  constructionValue?: Maybe<Money>,
  contractorProfileId: Scalars['ID'],
  contractorProfileWorkHistoryId: Scalars['ID'],
  contractorProfileWorkHistoryReferenceStatusId?: Maybe<Scalars['ID']>,
  email: Scalars['String'],
  familyName: Scalars['String'],
  givenName: Scalars['String'],
  id: Scalars['ID'],
  phoneNumber: Scalars['String'],
  status: ProgressionStatus,
  teamType: TeamType,
};

export enum ProgressionStatus {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  WaitingOnUser = 'WaitingOnUser',
  WaitingOnWeaver = 'WaitingOnWeaver'
}

export type Project = {
  __typename?: 'Project',
  _notesCount?: Maybe<Scalars['Int']>,
  acceptedQuoteId?: Maybe<Scalars['ID']>,
  address?: Maybe<WeaverAddress>,
  assigneeTeamId?: Maybe<Scalars['ID']>,
  budgetCategory: Scalars['String'],
  budgetValue: Money,
  createdAt: Scalars['AWSDateTime'],
  description: Scalars['String'],
  documentCount?: Maybe<Scalars['Int']>,
  documentIds?: Maybe<Array<Scalars['String']>>,
  documents?: Maybe<Array<UploadedFile>>,
  id: Scalars['ID'],
  lastAccessed?: Maybe<Scalars['AWSDateTime']>,
  lastActivity?: Maybe<Scalars['AWSDateTime']>,
  memberInvites: Array<ProjectMemberInvite>,
  memberSlots: Array<ProjectMemberSlot>,
  members: Array<ProjectMember>,
  note?: Maybe<Scalars['String']>,
  projectTypes: Array<ProjectType>,
  quotes?: Maybe<Array<Quote>>,
  reference: Scalars['String'],
  siteVisits?: Maybe<Array<SiteVisit>>,
  status?: Maybe<ProjectProgress>,
  statusId?: Maybe<Scalars['ID']>,
  tasks: Array<Task>,
  tenderReturnDate: Scalars['AWSDateTime'],
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  workStartEstimate: WorkStartEstimate,
};

export type ProjectMemberInvitesArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type ProjectMembersArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type ProjectTasksArgs = {
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type ProjectAction = {
  __typename?: 'ProjectAction',
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
  teamId?: Maybe<Scalars['ID']>,
};

export type ProjectActionInput = {
  actionId: Scalars['String'],
  projectId: Scalars['ID'],
};

export type ProjectMember = {
  __typename?: 'ProjectMember',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  inviteReferral?: Maybe<Referral>,
  projectId: Scalars['ID'],
  projectRole: ProjectMemberRole,
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectMemberInvite = {
  __typename?: 'ProjectMemberInvite',
  companyName?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  email?: Maybe<Scalars['String']>,
  familyName?: Maybe<Scalars['String']>,
  givenName?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  phone?: Maybe<Scalars['String']>,
  projectId: Scalars['ID'],
  referral?: Maybe<Referral>,
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  team?: Maybe<Team>,
  teamId?: Maybe<Scalars['ID']>,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectMemberRole {
  CandidateProfessional = 'CANDIDATE_PROFESSIONAL',
  Owner = 'OWNER',
  Professional = 'PROFESSIONAL'
}

export type ProjectMemberSlot = {
  __typename?: 'ProjectMemberSlot',
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  requiredProjectRole: ProjectMemberRole,
  requiredTeamType: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type ProjectNote = OpsNote & {
  __typename?: 'ProjectNote',
  body: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  createdByIndividual: Individual,
  createdByIndividualId: Scalars['ID'],
  id: Scalars['ID'],
  projectId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectProgress {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type ProjectStatus = {
  __typename?: 'ProjectStatus',
  color: Scalars['String'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  label: Scalars['String'],
  progress: ProjectProgress,
  updatedAt: Scalars['AWSDateTime'],
};

export enum ProjectType {
  BasementConversion = 'BasementConversion',
  ChangeOfUse = 'ChangeOfUse',
  Conservatory = 'Conservatory',
  FullRenovation = 'FullRenovation',
  LoftConversion = 'LoftConversion',
  NewBuild = 'NewBuild',
  RearExtension = 'RearExtension',
  Refurbishment = 'Refurbishment',
  SideExtension = 'SideExtension'
}

export type ProjectWorkflow = {
  __typename?: 'ProjectWorkflow',
  createdAt: Scalars['AWSDateTime'],
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTrigger>,
  updatedAt: Scalars['AWSDateTime'],
};

export type PushNotificationDevice = {
  __typename?: 'PushNotificationDevice',
  createdAt: Scalars['AWSDateTime'],
  deviceToken: Scalars['String'],
  id: Scalars['ID'],
  individualId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum PushNotificationType {
  NewChatRoomMessage = 'new_ChatRoomMessage'
}

export type Query = {
  __typename?: 'Query',
  getArchitectProfile?: Maybe<ArchitectProfile>,
  getChatRoom?: Maybe<ChatRoom>,
  getChatRoomMessages?: Maybe<Array<ChatRoomMessage>>,
  getContractorProfile?: Maybe<ContractorProfile>,
  getCoordinatesForWeaverAddress: WeaverCoordinates,
  getHomeownerProfile?: Maybe<HomeownerProfile>,
  getLead: Lead,
  getMyIndividual?: Maybe<Individual>,
  getNote?: Maybe<Note>,
  getPossibleCompanyNumbersForPartialCompanyName: CompaniesHouseResponse,
  getProject: Project,
  getReleaseInfo: ReleaseInfo,
  getStripeProductConfigs: Array<StripeProductConfig>,
  getUploadedFile: UploadedFile,
  hasCompanyNumberBeenClaimed: Scalars['Boolean'],
  listAllContractorCompanyCheckStatus: Array<ContractorCompanyCheckStatus>,
  listAllContractorProfileWorkHistoryReferenceNotes: Array<ContractorProfileWorkHistoryReferenceNote>,
  listAllContractorProfileWorkHistoryReferenceStatuses: Array<ContractorProfileWorkHistoryReferenceStatus>,
  listAllContractorProfiles: Array<ContractorProfile>,
  listAllContractorStatus: Array<ContractorStatus>,
  listAllIndividuals: Array<Individual>,
  listAllOpsWorkHistoryReferences: Array<OpsWorkHistoryReference>,
  listAllProjectStatus: Array<ProjectStatus>,
  listAllProjects: Array<Project>,
  listAllTeams: Array<Team>,
  listAvailableChatRooms?: Maybe<Array<Maybe<ChatRoom>>>,
  listContractorProfileNotes: Array<ContractorProfileNote>,
  listContractorSuggestionsForProject: Array<ContractorProfile>,
  listMyChatRooms?: Maybe<Array<Maybe<IndividualChatRoomConnection>>>,
  listMyLeads: Array<Lead>,
  listMyProjects: Array<Project>,
  listNotes?: Maybe<Array<Maybe<Note>>>,
  listProjectNotes: Array<ProjectNote>,
  listProjectTasks: Array<Task>,
  listTeamChatRooms?: Maybe<Array<Maybe<ChatRoom>>>,
  lookupContractorProfile: Array<ContractorProfileLookup>,
};

export type QueryGetArchitectProfileArgs = {
  architectTeamId: Scalars['ID'],
};

export type QueryGetChatRoomArgs = {
  id: Scalars['ID'],
};

export type QueryGetChatRoomMessagesArgs = {
  chatRoomId: Scalars['ID'],
};

export type QueryGetContractorProfileArgs = {
  contractorTeamId: Scalars['ID'],
};

export type QueryGetCoordinatesForWeaverAddressArgs = {
  address: WeaverAddressInput,
  exact?: InputMaybe<Scalars['Boolean']>,
};

export type QueryGetHomeownerProfileArgs = {
  homeownerTeamId: Scalars['ID'],
};

export type QueryGetLeadArgs = {
  id: Scalars['ID'],
};

export type QueryGetNoteArgs = {
  id: Scalars['ID'],
};

export type QueryGetPossibleCompanyNumbersForPartialCompanyNameArgs = {
  partialCompanyName: Scalars['String'],
};

export type QueryGetProjectArgs = {
  id: Scalars['ID'],
  ignoreScope?: InputMaybe<Scalars['Boolean']>,
};

export type QueryGetUploadedFileArgs = {
  id: Scalars['ID'],
};

export type QueryHasCompanyNumberBeenClaimedArgs = {
  companyNumber: Scalars['String'],
};

export type QueryListAllContractorProfileWorkHistoryReferenceNotesArgs = {
  contractorProfileWorkHistoryReferenceId: Scalars['ID'],
};

export type QueryListAllContractorProfilesArgs = {
  filter?: InputMaybe<ListAllContractorProfilesFilter>,
};

export type QueryListAllOpsWorkHistoryReferencesArgs = {
  filter?: InputMaybe<ListAllOpsWorkHistoryReferencesFilter>,
};

export type QueryListAllProjectsArgs = {
  filter?: InputMaybe<ListAllProjectsFilter>,
};

export type QueryListContractorProfileNotesArgs = {
  contractorProfileId: Scalars['ID'],
};

export type QueryListContractorSuggestionsForProjectArgs = {
  projectId: Scalars['ID'],
};

export type QueryListProjectNotesArgs = {
  projectId: Scalars['ID'],
};

export type QueryListProjectTasksArgs = {
  projectId: Scalars['ID'],
};

export type QueryListTeamChatRoomsArgs = {
  team1Id: Scalars['ID'],
  team2Id: Scalars['ID'],
};

export type QueryLookupContractorProfileArgs = {
  input: ContractorProfileLookupInput,
};

export type Quote = {
  __typename?: 'Quote',
  createdByTeamId: Scalars['ID'],
  id: Scalars['ID'],
};

export enum ReferenceRoleType {
  Architect = 'Architect',
  Homeowner = 'Homeowner'
}

export type Referral = {
  __typename?: 'Referral',
  teamId?: Maybe<Scalars['ID']>,
  type: ReferralType,
};

export enum ReferralType {
  NewUser = 'NewUser',
  ProjectOwner = 'ProjectOwner',
  Weaver = 'Weaver'
}

export type ReleaseInfo = {
  __typename?: 'ReleaseInfo',
  version: Scalars['String'],
};

export type SendChatRoomMessage = {
  chatRoomId: Scalars['ID'],
  content: Scalars['String'],
  documentCount?: InputMaybe<Scalars['Int']>,
  documentIds?: InputMaybe<Array<Scalars['ID']>>,
  idFromClient: Scalars['ID'],
  sentAt: Scalars['AWSDateTime'],
};

export type SignedUrl = {
  __typename?: 'SignedUrl',
  status: SignedUrlStatus,
  url?: Maybe<Scalars['String']>,
};

export enum SignedUrlStatus {
  ConfigError = 'ConfigError',
  FileNotFound = 'FileNotFound',
  Success = 'Success',
  TransformationMissing = 'TransformationMissing',
  UnsupportedOperationError = 'UnsupportedOperationError'
}

export type SiteVisit = {
  __typename?: 'SiteVisit',
  assignedTeamId: Scalars['ID'],
  dueDate: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  status: SiteVisitStatus,
};

export enum SiteVisitStatus {
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

export enum StatusColor {
  Blue = 'Blue',
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type StatusInput = {
  color: Scalars['String'],
  label: Scalars['String'],
  progress: ProjectProgress,
};

export enum StripeCallback {
  Default = 'Default',
  LeadPayment = 'LeadPayment'
}

export enum StripeProduct {
  SingleLeadPaymentF30T100 = 'SingleLeadPaymentF30T100',
  SingleLeadPaymentF100T500 = 'SingleLeadPaymentF100T500',
  SingleLeadPaymentF500T1000 = 'SingleLeadPaymentF500T1000',
  SingleLeadPaymentF1000T3000 = 'SingleLeadPaymentF1000T3000',
  UnlimitedLeadSubscriptionF30T100 = 'UnlimitedLeadSubscriptionF30T100',
  UnlimitedLeadSubscriptionF100T500 = 'UnlimitedLeadSubscriptionF100T500',
  UnlimitedLeadSubscriptionF500T1000 = 'UnlimitedLeadSubscriptionF500T1000',
  UnlimitedLeadSubscriptionF1000T3000 = 'UnlimitedLeadSubscriptionF1000T3000'
}

export type StripeProductConfig = {
  __typename?: 'StripeProductConfig',
  isLive: Scalars['Boolean'],
  isRecurring: Scalars['Boolean'],
  price: Money,
  priceId: Scalars['String'],
  product: StripeProduct,
  productId: Scalars['String'],
};

export type StripeSession = {
  __typename?: 'StripeSession',
  callbackCancelAt?: Maybe<Scalars['AWSDateTime']>,
  callbackSuccessAt?: Maybe<Scalars['AWSDateTime']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individual: Individual,
  individualId: Scalars['ID'],
  payload: Scalars['AWSJSON'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export type StripeSessionResponse = {
  __typename?: 'StripeSessionResponse',
  id: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type Task = {
  __typename?: 'Task',
  actionableType: Scalars['String'],
  activeStatus: TaskStatus,
  assignedTeam?: Maybe<Team>,
  assignedTeamId?: Maybe<Scalars['ID']>,
  childTasks: Array<Task>,
  createdAt: Scalars['AWSDateTime'],
  dueAt?: Maybe<Scalars['AWSDateTime']>,
  icon?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isConfidential?: Maybe<Scalars['Boolean']>,
  order: Scalars['Int'],
  parentTask?: Maybe<Task>,
  parentTaskId?: Maybe<Scalars['ID']>,
  project: Project,
  projectId: Scalars['ID'],
  status: TaskStatus,
  title: Scalars['String'],
  updatedAt: Scalars['AWSDateTime'],
  url?: Maybe<Scalars['String']>,
};

export enum TaskStatus {
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE'
}

export type Team = {
  __typename?: 'Team',
  companyNumber?: Maybe<Scalars['String']>,
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  individualConnections: Array<IndividualTeamConnection>,
  initialReferral?: Maybe<Referral>,
  isOnboardingComplete?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  type: TeamType,
  updatedAt: Scalars['AWSDateTime'],
};

export type TeamChatRoomConnection = {
  __typename?: 'TeamChatRoomConnection',
  chatRoom: ChatRoom,
  chatRoomId: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  id: Scalars['ID'],
  team: Team,
  teamId: Scalars['ID'],
  updatedAt: Scalars['AWSDateTime'],
};

export enum TeamKind {
  Company = 'COMPANY',
  NonCompany = 'NON_COMPANY'
}

export enum TeamType {
  Architect = 'ARCHITECT',
  Contractor = 'CONTRACTOR',
  Homeowner = 'HOMEOWNER',
  Weaver = 'WEAVER'
}

export type UpdateArchitectProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type UpdateChatRoom = {
  icon?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  name?: InputMaybe<Scalars['String']>,
};

export type UpdateContractorProfile = {
  companyAddress?: InputMaybe<WeaverAddressInput>,
  companyTradingAs?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  locationAreas?: InputMaybe<Array<Scalars['String']>>,
  onboardingPaymentEvent?: InputMaybe<EventInput>,
};

export type UpdateContractorProfileWorkHistory = {
  constructionValue?: InputMaybe<MoneyInput>,
  id: Scalars['ID'],
  projectAddress?: InputMaybe<WeaverAddressInput>,
  projectTypes?: InputMaybe<Array<ProjectType>>,
};

export type UpdateContractorProfileWorkHistoryReference = {
  companyName?: InputMaybe<Scalars['String']>,
  email?: InputMaybe<Scalars['String']>,
  familyName?: InputMaybe<Scalars['String']>,
  givenName?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  phoneNumber?: InputMaybe<Scalars['String']>,
  status?: InputMaybe<ProgressionStatus>,
  teamType?: InputMaybe<TeamType>,
};

export type UpdateContractorProfileWorkHistoryReferenceForWorkHistory = {
  id: Scalars['ID'],
  reference: UpdateContractorProfileWorkHistoryReference,
};

export type UpdateHomeownerProfile = {
  id: Scalars['ID'],
  propertyAddress?: InputMaybe<WeaverAddressInput>,
  teamName?: InputMaybe<Scalars['String']>,
};

export type UpdateNoteInput = {
  id: Scalars['ID'],
  text: Scalars['String'],
  title: Scalars['String'],
};

export type UpdateProjectTaskStatus = {
  projectId: Scalars['ID'],
  status: TaskStatus,
  taskId?: InputMaybe<Scalars['ID']>,
};

export type UpdateProjectWorkflowInput = {
  projectId: Scalars['ID'],
  triggers: Array<WorkflowTriggerInput>,
};

export type UpdateUploadedFileInput = {
  description?: InputMaybe<Scalars['String']>,
  id: Scalars['ID'],
  status?: InputMaybe<UploadedFileStatus>,
  tags?: InputMaybe<Array<UpdateUploadedFileTagInput>>,
  title?: InputMaybe<Scalars['String']>,
};

export type UpdateUploadedFileTagInput = {
  confidence?: InputMaybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export type UploadedFile = {
  __typename?: 'UploadedFile',
  createdAt: Scalars['AWSDateTime'],
  description?: Maybe<Scalars['String']>,
  fileContentType?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  fileSizeInBytes?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  s3Bucket: Scalars['String'],
  s3Key: Scalars['String'],
  signedUrlForDownload: SignedUrl,
  signedUrlForUpload: SignedUrl,
  status?: Maybe<UploadedFileStatus>,
  tags?: Maybe<Array<UploadedFileTag>>,
  title?: Maybe<Scalars['String']>,
  transformations?: Maybe<Array<FileTransformation>>,
  updatedAt: Scalars['AWSDateTime'],
  uploadedByIndividual?: Maybe<Individual>,
  uploadedByIndividualId: Scalars['ID'],
  uploadedByTeam?: Maybe<Team>,
  uploadedByTeamId: Scalars['ID'],
};

export type UploadedFileSignedUrlForDownloadArgs = {
  config?: InputMaybe<DownloadConfigInput>,
};

export enum UploadedFileStatus {
  Archived = 'Archived',
  Completed = 'Completed',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Uploading = 'Uploading'
}

export type UploadedFileTag = {
  __typename?: 'UploadedFileTag',
  confidence?: Maybe<Scalars['Float']>,
  name: Scalars['String'],
  source: UploadedFileTagSource,
};

export enum UploadedFileTagSource {
  MachineLearning = 'MachineLearning',
  User = 'User'
}

export type WeaverAddress = {
  __typename?: 'WeaverAddress',
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  careOf?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  county?: Maybe<Scalars['String']>,
  poBox?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: Maybe<Scalars['String']>,
};

export type WeaverAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>,
  addressLine2?: InputMaybe<Scalars['String']>,
  careOf?: InputMaybe<Scalars['String']>,
  country?: InputMaybe<Scalars['String']>,
  county?: InputMaybe<Scalars['String']>,
  poBox?: InputMaybe<Scalars['String']>,
  postCode: Scalars['String'],
  postTown?: InputMaybe<Scalars['String']>,
};

export type WeaverCoordinates = {
  __typename?: 'WeaverCoordinates',
  lat: Scalars['String'],
  lng: Scalars['String'],
};

export enum WorkStartEstimate {
  EndOfMonth = 'END_OF_MONTH',
  OneThreeMonths = 'ONE_THREE_MONTHS',
  OverSixMonths = 'OVER_SIX_MONTHS',
  ThreeSixMonths = 'THREE_SIX_MONTHS',
  Unknown = 'UNKNOWN'
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger',
  actionId: Scalars['String'],
  actionableType: Scalars['String'],
  taskStatus: TaskStatus,
};

export type WorkflowTriggerInput = {
  actionId: Scalars['String'],
  actionableType: Scalars['String'],
  taskStatus: TaskStatus,
};

export type GetMyIndividualQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyIndividualQuery = { __typename?: 'Query', getMyIndividual?: { __typename?: 'Individual', id: string, createdAt: string, updatedAt: string, givenName: string, familyName: string, pictureURL: string, lastSyncedAt: string, teamConnections: Array<{ __typename?: 'IndividualTeamConnection', id: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType, companyNumber?: string | null, isOnboardingComplete?: boolean | null } }> } | null };

export type GetReleaseInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetReleaseInfoQuery = { __typename?: 'Query', getReleaseInfo: { __typename?: 'ReleaseInfo', version: string } };

export type FlushCachesFrontendSpaMutationVariables = Exact<{ [key: string]: never }>;

export type FlushCachesFrontendSpaMutation = { __typename?: 'Mutation', flushCachesFrontendSPA: boolean };

export type ListMyChatRoomsQueryVariables = Exact<{ [key: string]: never }>;

export type ListMyChatRoomsQuery = { __typename?: 'Query', listMyChatRooms?: Array<{ __typename?: 'IndividualChatRoomConnection', id: string, chatRoomId: string, chatRoom: { __typename?: 'ChatRoom', id: string, name?: string | null, icon?: string | null, lastMessageAt: string, messages?: Array<{ __typename?: 'ChatRoomMessage', sentAt: string, content: string }> | null } } | null> | null };

export type ListAvailableChatRoomsQueryVariables = Exact<{ [key: string]: never }>;

export type ListAvailableChatRoomsQuery = { __typename?: 'Query', listAvailableChatRooms?: Array<{ __typename?: 'ChatRoom', id: string, name?: string | null, icon?: string | null, lastMessageAt: string } | null> | null };

export type GetChatRoomWithMyMessagesQueryVariables = Exact<{
  chatRoomId: Scalars['ID'],
  config?: InputMaybe<DownloadConfigInput>,
}>;

export type GetChatRoomWithMyMessagesQuery = { __typename?: 'Query', getChatRoom?: { __typename?: 'ChatRoom', id: string, name?: string | null, icon?: string | null, lastMessageAt: string, messages?: Array<{ __typename?: 'ChatRoomMessage', id: string, idFromClient: string, content: string, documentCount?: number | null, sentAt: string, readAt?: string | null, sentByIndividual: { __typename?: 'Individual', id: string, givenName: string, familyName: string, pictureURL: string }, documents?: Array<{ __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, fileSizeInBytes?: number | null, signedUrlForDownload: { __typename?: 'SignedUrl', status: SignedUrlStatus, url?: string | null }, signedUrlForUpload: { __typename?: 'SignedUrl', status: SignedUrlStatus, url?: string | null } }> | null }> | null } | null };

export type GetChatRoomQueryVariables = Exact<{
  chatRoomId: Scalars['ID'],
}>;

export type GetChatRoomQuery = { __typename?: 'Query', getChatRoom?: { __typename?: 'ChatRoom', id: string, name?: string | null, icon?: string | null, lastMessageAt: string } | null };

export type ListTeamChatRoomsQueryVariables = Exact<{
  team1Id: Scalars['ID'],
  team2Id: Scalars['ID'],
}>;

export type ListTeamChatRoomsQuery = { __typename?: 'Query', listTeamChatRooms?: Array<{ __typename?: 'ChatRoom', id: string } | null> | null };

export type GetChatRoomMessagesQueryVariables = Exact<{
  chatRoomId: Scalars['ID'],
}>;

export type GetChatRoomMessagesQuery = { __typename?: 'Query', getChatRoomMessages?: Array<{ __typename?: 'ChatRoomMessage', id: string, idFromClient: string, content: string, sentAt: string, documentCount?: number | null, readAt?: string | null, sentByIndividual: { __typename?: 'Individual', id: string, givenName: string, familyName: string, pictureURL: string }, documents?: Array<{ __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, fileSizeInBytes?: number | null, signedUrlForDownload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus }, signedUrlForUpload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus } }> | null }> | null };

export type CreateChatRoomMutationVariables = Exact<{
  input: CreateChatRoom,
}>;

export type CreateChatRoomMutation = { __typename?: 'Mutation', createChatRoom: { __typename?: 'ChatRoom', id: string } };

export type UpdateChatRoomMutationVariables = Exact<{
  input: UpdateChatRoom,
}>;

export type UpdateChatRoomMutation = { __typename?: 'Mutation', updateChatRoom?: { __typename?: 'ChatRoom', id: string } | null };

export type JoinChatRoomMutationVariables = Exact<{
  chatRoomId: Scalars['ID'],
}>;

export type JoinChatRoomMutation = { __typename?: 'Mutation', joinChatRoom?: { __typename?: 'ChatRoom', id: string } | null };

export type SendChatRoomMessageMutationVariables = Exact<{
  message: SendChatRoomMessage,
}>;

export type SendChatRoomMessageMutation = { __typename?: 'Mutation', sendChatRoomMessage?: { __typename?: 'MessageSentInfo', recipients?: Array<{ __typename?: 'MessageSentInfoRecipient', individualId: string, teamId: string, familyName: string, givenName: string } | null> | null } | null };

export type CreateChatRoomMessageUploadedFileMutationVariables = Exact<{
  input: CreateUploadedFileInput,
}>;

export type CreateChatRoomMessageUploadedFileMutation = { __typename?: 'Mutation', createUploadedFile: { __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, fileSizeInBytes?: number | null, signedUrlForUpload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus }, signedUrlForDownload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus } } };

export type UpdateChatRoomMessageUploadedFileMutationVariables = Exact<{
  input: UpdateUploadedFileInput,
}>;

export type UpdateChatRoomMessageUploadedFileMutation = { __typename?: 'Mutation', updateUploadedFile: { __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, fileSizeInBytes?: number | null } };

export type ListNotesQueryVariables = Exact<{ [key: string]: never }>;

export type ListNotesQuery = { __typename?: 'Query', listNotes?: Array<{ __typename?: 'Note', id: string, title: string, text: string } | null> | null };

export type CreateNoteMutationVariables = Exact<{
  note: CreateNoteInput,
}>;

export type CreateNoteMutation = { __typename?: 'Mutation', createNote?: { __typename?: 'Note', id: string, title: string, text: string } | null };

export type UpdateNoteMutationVariables = Exact<{
  note: UpdateNoteInput,
}>;

export type UpdateNoteMutation = { __typename?: 'Mutation', updateNote?: { __typename?: 'Note', id: string, title: string, text: string } | null };

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'],
}>;

export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote?: { __typename?: 'Note', id: string, title: string, text: string } | null };

export type RegisterMyDeviceForPushNotificationsMutationVariables = Exact<{
  deviceToken: Scalars['String'],
}>;

export type RegisterMyDeviceForPushNotificationsMutation = { __typename?: 'Mutation', registerMyDeviceForPushNotifications: { __typename?: 'PushNotificationDevice', id: string, deviceToken: string, individualId: string, updatedAt: string, createdAt: string } };

export type ClaimInviteMutationVariables = Exact<{
  inviteId: Scalars['ID'],
}>;

export type ClaimInviteMutation = { __typename?: 'Mutation', claimInvite: boolean };

export type DevListProjectsForSelectQueryVariables = Exact<{ [key: string]: never }>;

export type DevListProjectsForSelectQuery = { __typename?: 'Query', listMyProjects: Array<{ __typename?: 'Project', id: string, title: string }> };

export type DevListAllProjectsForSelectQueryVariables = Exact<{ [key: string]: never }>;

export type DevListAllProjectsForSelectQuery = { __typename?: 'Query', listAllProjects: Array<{ __typename?: 'Project', id: string, title: string }> };

export type DevProcessProjectActionMutationVariables = Exact<{
  action: ProjectActionInput,
}>;

export type DevProcessProjectActionMutation = { __typename?: 'Mutation', processProjectAction: boolean };

export type DevRemoveIndividualFromTeamMutationVariables = Exact<{
  individualId: Scalars['String'],
  teamId: Scalars['String'],
}>;

export type DevRemoveIndividualFromTeamMutation = { __typename?: 'Mutation', removeIndividualFromTeam: boolean };

export type CreateStripeSessionForLeadPaymentMutationVariables = Exact<{
  product: StripeProduct,
  leadId: Scalars['ID'],
  redirectBaseURL: Scalars['String'],
}>;

export type CreateStripeSessionForLeadPaymentMutation = { __typename?: 'Mutation', createStripeSessionForLeadPayment: { __typename?: 'StripeSessionResponse', id: string, url?: string | null } };

export type ClaimNonCompanyTeamMutationVariables = Exact<{
  type: TeamType,
  name: Scalars['String'],
}>;

export type ClaimNonCompanyTeamMutation = { __typename?: 'Mutation', claimNonCompanyTeam: { __typename?: 'Team', id: string } };

export type ClaimCompanyTeamMutationVariables = Exact<{
  type: TeamType,
  name: Scalars['String'],
  companyNumber: Scalars['String'],
}>;

export type ClaimCompanyTeamMutation = { __typename?: 'Mutation', claimCompanyTeam: { __typename?: 'Team', id: string } };

export type JoinWeaverTeamMutationVariables = Exact<{ [key: string]: never }>;

export type JoinWeaverTeamMutation = { __typename?: 'Mutation', joinWeaverTeam: { __typename?: 'Team', id: string } };

export type CreateArchitectProfileMutationVariables = Exact<{
  input: CreateArchitectProfile,
}>;

export type CreateArchitectProfileMutation = { __typename?: 'Mutation', createArchitectProfile: { __typename?: 'ArchitectProfile', id: string } };

export type GetOnboardingArchitectProfileQueryVariables = Exact<{
  architectTeamId: Scalars['ID'],
}>;

export type GetOnboardingArchitectProfileQuery = { __typename?: 'Query', getArchitectProfile?: { __typename?: 'ArchitectProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, companyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null } | null };

export type CreateContractorProfileMutationVariables = Exact<{
  input: CreateContractorProfile,
}>;

export type CreateContractorProfileMutation = { __typename?: 'Mutation', createContractorProfile: { __typename?: 'ContractorProfile', id: string } };

export type GetOnboardingContractorProfileQueryVariables = Exact<{
  contractorTeamId: Scalars['ID'],
}>;

export type GetOnboardingContractorProfileQuery = { __typename?: 'Query', getContractorProfile?: { __typename?: 'ContractorProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, companyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null, onboardingPaymentEvent?: { __typename?: 'Event', actionedByIndividualId?: string | null, actionedAt?: string | null } | null } | null };

export type CreateHomeownerProfileMutationVariables = Exact<{
  input: CreateHomeownerProfile,
}>;

export type CreateHomeownerProfileMutation = { __typename?: 'Mutation', createHomeownerProfile: { __typename?: 'HomeownerProfile', id: string } };

export type GetOnboardingHomeownerProfileQueryVariables = Exact<{
  homeownerTeamId: Scalars['ID'],
}>;

export type GetOnboardingHomeownerProfileQuery = { __typename?: 'Query', getHomeownerProfile?: { __typename?: 'HomeownerProfile', id: string, teamName?: string | null, propertyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null } | null };

export type GetPossibleCompanyNumbersForPartialCompanyNameQueryVariables = Exact<{
  partialCompanyName: Scalars['String'],
}>;

export type GetPossibleCompanyNumbersForPartialCompanyNameQuery = { __typename?: 'Query', getPossibleCompanyNumbersForPartialCompanyName: { __typename?: 'CompaniesHouseResponse', apiHealthy: boolean, results: Array<{ __typename?: 'CompanySummary', companyNumber: string, companyName: string, companyStatus?: string | null, sicCode_SicText_1?: string | null, incorporationDate?: string | null, regAddress_CareOf?: string | null, regAddress_POBox?: string | null, regAddress_AddressLine1?: string | null, regAddress_AddressLine2?: string | null, regAddress_PostTown?: string | null, regAddress_County?: string | null, regAddress_Country?: string | null, regAddress_PostCode?: string | null }> } };

export type SetOnboardingCompleteMutationVariables = Exact<{
  teamId: Scalars['String'],
  isOnboardingComplete: Scalars['Boolean'],
}>;

export type SetOnboardingCompleteMutation = { __typename?: 'Mutation', setOnboardingComplete: boolean };

export type ResetContractorProfileWorkHistoryMutationVariables = Exact<{ [key: string]: never }>;

export type ResetContractorProfileWorkHistoryMutation = { __typename?: 'Mutation', resetContractorProfileWorkHistory: boolean };

export type LookupContractorProfileQueryVariables = Exact<{
  input: ContractorProfileLookupInput,
}>;

export type LookupContractorProfileQuery = { __typename?: 'Query', lookupContractorProfile: Array<{ __typename?: 'ContractorProfileLookup', contractorProfileId?: string | null, workHistoryId?: string | null, workHistoryReferenceId?: string | null }> };

export type GetContractorProfileQueryVariables = Exact<{
  contractorTeamId: Scalars['ID'],
}>;

export type GetContractorProfileQuery = { __typename?: 'Query', getContractorProfile?: { __typename?: 'ContractorProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, locationAreas?: Array<string> | null, companyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null, workHistory?: Array<{ __typename?: 'ContractorProfileWorkHistory', id: string, projectTypes?: Array<ProjectType> | null, projectAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null, constructionValue?: { __typename?: 'Money', currency: Currency, amountInPence: string } | null, references?: Array<{ __typename?: 'ContractorProfileWorkHistoryReference', id: string, teamType: TeamType, companyName?: string | null, givenName: string, familyName: string, email: string, phoneNumber: string, status: ProgressionStatus }> | null, photos?: Array<{ __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, fileSizeInBytes?: number | null, createdAt: string, updatedAt: string, signedUrlForDownload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus } }> | null }> | null, budgetRangeBadges?: Array<{ __typename?: 'ContractorProfileBudgetRangeBadge', budgetRange: BudgetRange }> | null, budgetRangeSubscriptions?: Array<{ __typename?: 'ContractorProfileBudgetRangeSubscription', id: string, budgetRange: BudgetRange }> | null } | null };

export type UpdateContractorProfileMutationVariables = Exact<{
  input: UpdateContractorProfile,
}>;

export type UpdateContractorProfileMutation = { __typename?: 'Mutation', updateContractorProfile: { __typename?: 'ContractorProfile', id: string, companyTradingAs?: string | null } };

export type CreateContractorProfileWorkHistoryMutationVariables = Exact<{
  input: CreateContractorProfileWorkHistory,
}>;

export type CreateContractorProfileWorkHistoryMutation = { __typename?: 'Mutation', createContractorProfileWorkHistory: { __typename?: 'ContractorProfileWorkHistory', id: string } };

export type UpdateContractorProfileWorkHistoryMutationVariables = Exact<{
  input: UpdateContractorProfileWorkHistory,
}>;

export type UpdateContractorProfileWorkHistoryMutation = { __typename?: 'Mutation', updateContractorProfileWorkHistory: { __typename?: 'ContractorProfileWorkHistory', id: string } };

export type CreateContractorProfileWorkHistoryReferenceMutationVariables = Exact<{
  input: CreateContractorProfileWorkHistoryReferenceForWorkHistory,
}>;

export type CreateContractorProfileWorkHistoryReferenceMutation = { __typename?: 'Mutation', createContractorProfileWorkHistoryReference: { __typename?: 'ContractorProfileWorkHistoryReference', id: string } };

export type UpdateContractorProfileWorkHistoryReferenceMutationVariables = Exact<{
  input: UpdateContractorProfileWorkHistoryReferenceForWorkHistory,
}>;

export type UpdateContractorProfileWorkHistoryReferenceMutation = { __typename?: 'Mutation', updateContractorProfileWorkHistoryReference: { __typename?: 'ContractorProfileWorkHistoryReference', id: string } };

export type CreateContractorProfileWorkHistoryPhotoMutationVariables = Exact<{
  workHistoryId: Scalars['ID'],
  uploadedFileId: Scalars['ID'],
}>;

export type CreateContractorProfileWorkHistoryPhotoMutation = { __typename?: 'Mutation', createContractorProfileWorkHistoryPhoto?: { __typename?: 'UploadedFile', id: string } | null };

export type AwardContractorProfileBudgetRangeBadgeMutationVariables = Exact<{
  budgetRange: BudgetRange,
}>;

export type AwardContractorProfileBudgetRangeBadgeMutation = { __typename?: 'Mutation', awardContractorProfileBudgetRangeBadge: boolean };

export type GetArchitectProfileQueryVariables = Exact<{
  architectTeamId: Scalars['ID'],
}>;

export type GetArchitectProfileQuery = { __typename?: 'Query', getArchitectProfile?: { __typename?: 'ArchitectProfile', id: string, companyNumber: string, companyRegisteredName: string, companyTradingAs?: string | null, companyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null } | null };

export type GetHomeownerProfileQueryVariables = Exact<{
  homeownerTeamId: Scalars['ID'],
}>;

export type GetHomeownerProfileQuery = { __typename?: 'Query', getHomeownerProfile?: { __typename?: 'HomeownerProfile', id: string, teamName?: string | null, propertyAddress?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null } | null };

export type GetAllTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTeamsQuery = { __typename?: 'Query', listAllTeams: Array<{ __typename?: 'Team', id: string, name?: string | null, type: TeamType, companyNumber?: string | null, individualConnections: Array<{ __typename?: 'IndividualTeamConnection', id: string, individual: { __typename?: 'Individual', id: string, givenName: string, familyName: string, pictureURL: string } }> }> };

export type GetUploadedFileQueryVariables = Exact<{
  id: Scalars['ID'],
  config?: InputMaybe<DownloadConfigInput>,
}>;

export type GetUploadedFileQuery = { __typename?: 'Query', getUploadedFile: { __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, fileName?: string | null, fileContentType?: string | null, updatedAt: string, fileSizeInBytes?: number | null, signedUrlForDownload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus }, uploadedByTeam?: { __typename?: 'Team', name?: string | null } | null } };

export type UpdateArchitectProfileMutationVariables = Exact<{
  input: UpdateArchitectProfile,
}>;

export type UpdateArchitectProfileMutation = { __typename?: 'Mutation', updateArchitectProfile: { __typename?: 'ArchitectProfile', id: string, companyTradingAs?: string | null } };

export type UpdateHomeownerProfileMutationVariables = Exact<{
  input: UpdateHomeownerProfile,
}>;

export type UpdateHomeownerProfileMutation = { __typename?: 'Mutation', updateHomeownerProfile: { __typename?: 'HomeownerProfile', id: string, teamName?: string | null } };

export type CreateUploadedFileMutationVariables = Exact<{
  input: CreateUploadedFileInput,
}>;

export type CreateUploadedFileMutation = { __typename?: 'Mutation', createUploadedFile: { __typename?: 'UploadedFile', id: string, status?: UploadedFileStatus | null, signedUrlForUpload: { __typename?: 'SignedUrl', url?: string | null, status: SignedUrlStatus } } };

export type UpdateUploadedFileMutationVariables = Exact<{
  input: UpdateUploadedFileInput,
}>;

export type UpdateUploadedFileMutation = { __typename?: 'Mutation', updateUploadedFile: { __typename?: 'UploadedFile', id: string } };

export type GetStripeProductConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStripeProductConfigsQuery = { __typename?: 'Query', getStripeProductConfigs: Array<{ __typename?: 'StripeProductConfig', product: StripeProduct, productId: string, priceId: string, isRecurring: boolean, isLive: boolean, price: { __typename?: 'Money', currency: Currency, amountInPence: string } }> };

export type ListMyProjectsAndLeadsQueryVariables = Exact<{ [key: string]: never }>;

export type ListMyProjectsAndLeadsQuery = { __typename?: 'Query', listMyLeads: Array<{ __typename?: 'Lead', id: string, title: string, description: string, approximateLocation: string, lastAccessed?: string | null }>, listMyProjects: Array<{ __typename?: 'Project', id: string, title: string, description: string, status?: ProjectProgress | null, lastAccessed?: string | null }> };

export type ShowProjectQueryVariables = Exact<{
  id: Scalars['ID'],
  config?: InputMaybe<DownloadConfigInput>,
}>;

export type ShowProjectQuery = { __typename?: 'Query', getProject: { __typename?: 'Project', id: string, title: string, description: string, status?: ProjectProgress | null, projectTypes: Array<ProjectType>, budgetCategory: string, tenderReturnDate: string, workStartEstimate: WorkStartEstimate, lastAccessed?: string | null, members: Array<{ __typename?: 'ProjectMember', id: string, projectRole: ProjectMemberRole, createdAt: string, team: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } }>, memberSlots: Array<{ __typename?: 'ProjectMemberSlot', id: string, requiredTeamType: TeamType, requiredProjectRole: ProjectMemberRole }>, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string, requiredTeamType: TeamType, requiredProjectRole: ProjectMemberRole, companyName?: string | null, givenName?: string | null, familyName?: string | null, createdAt: string, team?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, address?: { __typename?: 'WeaverAddress', careOf?: string | null, poBox?: string | null, addressLine1?: string | null, addressLine2?: string | null, postTown?: string | null, county?: string | null, country?: string | null, postCode: string } | null, budgetValue: { __typename?: 'Money', currency: Currency, amountInPence: string }, documents?: Array<{ __typename?: 'UploadedFile', id: string, fileName?: string | null, fileSizeInBytes?: number | null, fileContentType?: string | null, updatedAt: string, status?: UploadedFileStatus | null, signedUrlForDownload: { __typename?: 'SignedUrl', status: SignedUrlStatus, url?: string | null }, uploadedByTeam?: { __typename?: 'Team', name?: string | null } | null }> | null } };

export type ListAllTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type ListAllTeamsQuery = { __typename?: 'Query', listAllTeams: Array<{ __typename?: 'Team', id: string, name?: string | null }> };

export type CreateProjectMutationVariables = Exact<{
  project: CreateProjectInput,
}>;

export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, memberInvites: Array<{ __typename?: 'ProjectMemberInvite', id: string }> } };

export type AddProjectInviteMutationVariables = Exact<{
  projectId: Scalars['ID'],
  teamId: Scalars['ID'],
  projectMemberRole: ProjectMemberRole,
}>;

export type AddProjectInviteMutation = { __typename?: 'Mutation', addProjectInvite: boolean };

export type JoinChatRoomForIndividualMutationVariables = Exact<{
  chatRoomId: Scalars['ID'],
  individualId: Scalars['ID'],
}>;

export type JoinChatRoomForIndividualMutation = { __typename?: 'Mutation', joinChatRoomForIndividual?: { __typename?: 'ChatRoom', id: string } | null };

export type AddUploadedFileToProjectFilesMutationVariables = Exact<{
  projectId: Scalars['ID'],
  uploadedFileId: Scalars['ID'],
}>;

export type AddUploadedFileToProjectFilesMutation = { __typename?: 'Mutation', addUploadedFileToProjectFiles?: { __typename?: 'UploadedFile', id: string } | null };

export type IncrementProjectDocumentCountMutationVariables = Exact<{
  input: IncrementProjectDocumentCount,
}>;

export type IncrementProjectDocumentCountMutation = { __typename?: 'Mutation', incrementProjectDocumentCount: boolean };

export type InviteContractorToMyProjectMutationVariables = Exact<{
  input: InviteToMyProjectInput,
}>;

export type InviteContractorToMyProjectMutation = { __typename?: 'Mutation', inviteToMyProject: { __typename?: 'ProjectMemberInvite', id: string } };

export type TaskFieldsFragment = { __typename?: 'Task', id: string, title: string, assignedTeamId?: string | null, url?: string | null, dueAt?: string | null, icon?: string | null, isConfidential?: boolean | null, status: TaskStatus, assignedTeam?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null };

export type ListProjectTasksQueryVariables = Exact<{
  id: Scalars['ID'],
}>;

export type ListProjectTasksQuery = { __typename?: 'Query', listProjectTasks: Array<{ __typename?: 'Task', id: string, title: string, assignedTeamId?: string | null, url?: string | null, dueAt?: string | null, icon?: string | null, isConfidential?: boolean | null, status: TaskStatus, childTasks: Array<{ __typename?: 'Task', id: string, title: string, assignedTeamId?: string | null, url?: string | null, dueAt?: string | null, icon?: string | null, isConfidential?: boolean | null, status: TaskStatus, childTasks: Array<{ __typename?: 'Task', id: string, title: string, assignedTeamId?: string | null, url?: string | null, dueAt?: string | null, icon?: string | null, isConfidential?: boolean | null, status: TaskStatus, assignedTeam?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, assignedTeam?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }>, assignedTeam?: { __typename?: 'Team', id: string, name?: string | null, type: TeamType } | null }> };

export type UpdateProjectTaskStatusMutationVariables = Exact<{
  input: UpdateProjectTaskStatus,
}>;

export type UpdateProjectTaskStatusMutation = { __typename?: 'Mutation', updateProjectTaskStatus: boolean };

export type ShowLeadQueryVariables = Exact<{
  id: Scalars['ID'],
}>;

export type ShowLeadQuery = { __typename?: 'Query', getLead: { __typename?: 'Lead', id: string, title: string, description: string, approximateLocation: string, budgetCategory: string, projectTypes: Array<ProjectType>, tenderReturnDate: string, workStartEstimate: WorkStartEstimate, lastAccessed?: string | null, budgetValue: { __typename?: 'Money', currency: Currency, amountInPence: string }, referral?: { __typename?: 'Referral', type: ReferralType } | null } };

export type AcceptLeadMutationVariables = Exact<{
  id: Scalars['ID'],
}>;

export type AcceptLeadMutation = { __typename?: 'Mutation', acceptLead: boolean };

export type RejectLeadMutationVariables = Exact<{
  id: Scalars['ID'],
}>;

export type RejectLeadMutation = { __typename?: 'Mutation', rejectLead: boolean };

export type SetIndividualPhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars['String'],
  individualId?: InputMaybe<Scalars['ID']>,
}>;

export type SetIndividualPhoneNumberMutation = { __typename?: 'Mutation', setIndividualPhoneNumber: boolean };

export const TaskFieldsFragmentDoc = `
    fragment taskFields on Task {
  id
  title
  assignedTeamId
  assignedTeam {
    id
    name
    type
  }
  url
  dueAt
  status: activeStatus
  icon
  isConfidential
}
    `
export const GetMyIndividualDocument = `
    query getMyIndividual {
  getMyIndividual {
    id
    createdAt
    updatedAt
    givenName
    familyName
    pictureURL
    lastSyncedAt
    teamConnections {
      id
      team {
        id
        name
        type
        companyNumber
        isOnboardingComplete
      }
    }
  }
}
    `
export const useGetMyIndividualQuery = <
  TData = GetMyIndividualQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetMyIndividualQueryVariables,
  options?: UseQueryOptions<GetMyIndividualQuery, TError, TData>,
) =>
  useQuery<GetMyIndividualQuery, TError, TData>(
    variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ],
    fetcher<GetMyIndividualQuery, GetMyIndividualQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetMyIndividualDocument, variables),
    options,
  )
useGetMyIndividualQuery.document = GetMyIndividualDocument

useGetMyIndividualQuery.getKey = (variables?: GetMyIndividualQueryVariables) => variables === undefined ? [ 'getMyIndividual' ] : [ 'getMyIndividual', variables ]

export const GetReleaseInfoDocument = `
    query getReleaseInfo {
  getReleaseInfo {
    version
  }
}
    `
export const useGetReleaseInfoQuery = <
  TData = GetReleaseInfoQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetReleaseInfoQueryVariables,
  options?: UseQueryOptions<GetReleaseInfoQuery, TError, TData>,
) =>
  useQuery<GetReleaseInfoQuery, TError, TData>(
    variables === undefined ? [ 'getReleaseInfo' ] : [ 'getReleaseInfo', variables ],
    fetcher<GetReleaseInfoQuery, GetReleaseInfoQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetReleaseInfoDocument, variables),
    options,
  )
useGetReleaseInfoQuery.document = GetReleaseInfoDocument

useGetReleaseInfoQuery.getKey = (variables?: GetReleaseInfoQueryVariables) => variables === undefined ? [ 'getReleaseInfo' ] : [ 'getReleaseInfo', variables ]

export const FlushCachesFrontendSpaDocument = `
    mutation flushCachesFrontendSPA {
  flushCachesFrontendSPA
}
    `
export const useFlushCachesFrontendSpaMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<FlushCachesFrontendSpaMutation, TError, FlushCachesFrontendSpaMutationVariables, TContext>,
) =>
  useMutation<FlushCachesFrontendSpaMutation, TError, FlushCachesFrontendSpaMutationVariables, TContext>(
    [ 'flushCachesFrontendSPA' ],
    (variables?: FlushCachesFrontendSpaMutationVariables) => fetcher<FlushCachesFrontendSpaMutation, FlushCachesFrontendSpaMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, FlushCachesFrontendSpaDocument, variables)(),
    options,
  )
export const ListMyChatRoomsDocument = `
    query listMyChatRooms {
  listMyChatRooms {
    id
    chatRoomId
    chatRoom {
      id
      name
      icon
      lastMessageAt
      messages {
        sentAt
        content
      }
    }
  }
}
    `
export const useListMyChatRoomsQuery = <
  TData = ListMyChatRoomsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListMyChatRoomsQueryVariables,
  options?: UseQueryOptions<ListMyChatRoomsQuery, TError, TData>,
) =>
  useQuery<ListMyChatRoomsQuery, TError, TData>(
    variables === undefined ? [ 'listMyChatRooms' ] : [ 'listMyChatRooms', variables ],
    fetcher<ListMyChatRoomsQuery, ListMyChatRoomsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListMyChatRoomsDocument, variables),
    options,
  )
useListMyChatRoomsQuery.document = ListMyChatRoomsDocument

useListMyChatRoomsQuery.getKey = (variables?: ListMyChatRoomsQueryVariables) => variables === undefined ? [ 'listMyChatRooms' ] : [ 'listMyChatRooms', variables ]

export const ListAvailableChatRoomsDocument = `
    query listAvailableChatRooms {
  listAvailableChatRooms {
    id
    name
    icon
    lastMessageAt
  }
}
    `
export const useListAvailableChatRoomsQuery = <
  TData = ListAvailableChatRoomsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAvailableChatRoomsQueryVariables,
  options?: UseQueryOptions<ListAvailableChatRoomsQuery, TError, TData>,
) =>
  useQuery<ListAvailableChatRoomsQuery, TError, TData>(
    variables === undefined ? [ 'listAvailableChatRooms' ] : [ 'listAvailableChatRooms', variables ],
    fetcher<ListAvailableChatRoomsQuery, ListAvailableChatRoomsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAvailableChatRoomsDocument, variables),
    options,
  )
useListAvailableChatRoomsQuery.document = ListAvailableChatRoomsDocument

useListAvailableChatRoomsQuery.getKey = (variables?: ListAvailableChatRoomsQueryVariables) => variables === undefined ? [ 'listAvailableChatRooms' ] : [ 'listAvailableChatRooms', variables ]

export const GetChatRoomWithMyMessagesDocument = `
    query getChatRoomWithMyMessages($chatRoomId: ID!, $config: DownloadConfigInput) {
  getChatRoom(id: $chatRoomId) {
    id
    name
    icon
    lastMessageAt
    messages {
      id
      idFromClient
      content
      documentCount
      sentAt
      sentByIndividual {
        id
        givenName
        familyName
        pictureURL
      }
      documents {
        id
        status
        fileName
        fileContentType
        fileSizeInBytes
        signedUrlForDownload(config: $config) {
          status
          url
        }
        signedUrlForUpload {
          status
          url
        }
      }
      readAt
    }
  }
}
    `
export const useGetChatRoomWithMyMessagesQuery = <
  TData = GetChatRoomWithMyMessagesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetChatRoomWithMyMessagesQueryVariables,
  options?: UseQueryOptions<GetChatRoomWithMyMessagesQuery, TError, TData>,
) =>
  useQuery<GetChatRoomWithMyMessagesQuery, TError, TData>(
    [ 'getChatRoomWithMyMessages', variables ],
    fetcher<GetChatRoomWithMyMessagesQuery, GetChatRoomWithMyMessagesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetChatRoomWithMyMessagesDocument, variables),
    options,
  )
useGetChatRoomWithMyMessagesQuery.document = GetChatRoomWithMyMessagesDocument

useGetChatRoomWithMyMessagesQuery.getKey = (variables: GetChatRoomWithMyMessagesQueryVariables) => [ 'getChatRoomWithMyMessages', variables ]

export const GetChatRoomDocument = `
    query getChatRoom($chatRoomId: ID!) {
  getChatRoom(id: $chatRoomId) {
    id
    name
    icon
    lastMessageAt
  }
}
    `
export const useGetChatRoomQuery = <
  TData = GetChatRoomQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetChatRoomQueryVariables,
  options?: UseQueryOptions<GetChatRoomQuery, TError, TData>,
) =>
  useQuery<GetChatRoomQuery, TError, TData>(
    [ 'getChatRoom', variables ],
    fetcher<GetChatRoomQuery, GetChatRoomQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetChatRoomDocument, variables),
    options,
  )
useGetChatRoomQuery.document = GetChatRoomDocument

useGetChatRoomQuery.getKey = (variables: GetChatRoomQueryVariables) => [ 'getChatRoom', variables ]

export const ListTeamChatRoomsDocument = `
    query listTeamChatRooms($team1Id: ID!, $team2Id: ID!) {
  listTeamChatRooms(team1Id: $team1Id, team2Id: $team2Id) {
    id
  }
}
    `
export const useListTeamChatRoomsQuery = <
  TData = ListTeamChatRoomsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListTeamChatRoomsQueryVariables,
  options?: UseQueryOptions<ListTeamChatRoomsQuery, TError, TData>,
) =>
  useQuery<ListTeamChatRoomsQuery, TError, TData>(
    [ 'listTeamChatRooms', variables ],
    fetcher<ListTeamChatRoomsQuery, ListTeamChatRoomsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListTeamChatRoomsDocument, variables),
    options,
  )
useListTeamChatRoomsQuery.document = ListTeamChatRoomsDocument

useListTeamChatRoomsQuery.getKey = (variables: ListTeamChatRoomsQueryVariables) => [ 'listTeamChatRooms', variables ]

export const GetChatRoomMessagesDocument = `
    query getChatRoomMessages($chatRoomId: ID!) {
  getChatRoomMessages(chatRoomId: $chatRoomId) {
    id
    idFromClient
    content
    sentAt
    documentCount
    sentByIndividual {
      id
      givenName
      familyName
      pictureURL
    }
    documents {
      id
      status
      fileName
      fileContentType
      fileSizeInBytes
      signedUrlForDownload {
        url
        status
      }
      signedUrlForUpload {
        url
        status
      }
    }
    readAt
  }
}
    `
export const useGetChatRoomMessagesQuery = <
  TData = GetChatRoomMessagesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetChatRoomMessagesQueryVariables,
  options?: UseQueryOptions<GetChatRoomMessagesQuery, TError, TData>,
) =>
  useQuery<GetChatRoomMessagesQuery, TError, TData>(
    [ 'getChatRoomMessages', variables ],
    fetcher<GetChatRoomMessagesQuery, GetChatRoomMessagesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetChatRoomMessagesDocument, variables),
    options,
  )
useGetChatRoomMessagesQuery.document = GetChatRoomMessagesDocument

useGetChatRoomMessagesQuery.getKey = (variables: GetChatRoomMessagesQueryVariables) => [ 'getChatRoomMessages', variables ]

export const CreateChatRoomDocument = `
    mutation createChatRoom($input: CreateChatRoom!) {
  createChatRoom(input: $input) {
    id
  }
}
    `
export const useCreateChatRoomMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateChatRoomMutation, TError, CreateChatRoomMutationVariables, TContext>,
) =>
  useMutation<CreateChatRoomMutation, TError, CreateChatRoomMutationVariables, TContext>(
    [ 'createChatRoom' ],
    (variables?: CreateChatRoomMutationVariables) => fetcher<CreateChatRoomMutation, CreateChatRoomMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateChatRoomDocument, variables)(),
    options,
  )
export const UpdateChatRoomDocument = `
    mutation updateChatRoom($input: UpdateChatRoom!) {
  updateChatRoom(input: $input) {
    id
  }
}
    `
export const useUpdateChatRoomMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateChatRoomMutation, TError, UpdateChatRoomMutationVariables, TContext>,
) =>
  useMutation<UpdateChatRoomMutation, TError, UpdateChatRoomMutationVariables, TContext>(
    [ 'updateChatRoom' ],
    (variables?: UpdateChatRoomMutationVariables) => fetcher<UpdateChatRoomMutation, UpdateChatRoomMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateChatRoomDocument, variables)(),
    options,
  )
export const JoinChatRoomDocument = `
    mutation joinChatRoom($chatRoomId: ID!) {
  joinChatRoom(chatRoomId: $chatRoomId) {
    id
  }
}
    `
export const useJoinChatRoomMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<JoinChatRoomMutation, TError, JoinChatRoomMutationVariables, TContext>,
) =>
  useMutation<JoinChatRoomMutation, TError, JoinChatRoomMutationVariables, TContext>(
    [ 'joinChatRoom' ],
    (variables?: JoinChatRoomMutationVariables) => fetcher<JoinChatRoomMutation, JoinChatRoomMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, JoinChatRoomDocument, variables)(),
    options,
  )
export const SendChatRoomMessageDocument = `
    mutation sendChatRoomMessage($message: SendChatRoomMessage!) {
  sendChatRoomMessage(message: $message) {
    recipients {
      individualId
      teamId
      familyName
      givenName
    }
  }
}
    `
export const useSendChatRoomMessageMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SendChatRoomMessageMutation, TError, SendChatRoomMessageMutationVariables, TContext>,
) =>
  useMutation<SendChatRoomMessageMutation, TError, SendChatRoomMessageMutationVariables, TContext>(
    [ 'sendChatRoomMessage' ],
    (variables?: SendChatRoomMessageMutationVariables) => fetcher<SendChatRoomMessageMutation, SendChatRoomMessageMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SendChatRoomMessageDocument, variables)(),
    options,
  )
export const CreateChatRoomMessageUploadedFileDocument = `
    mutation createChatRoomMessageUploadedFile($input: CreateUploadedFileInput!) {
  createUploadedFile(input: $input) {
    id
    status
    fileName
    fileContentType
    fileSizeInBytes
    signedUrlForUpload {
      url
      status
    }
    signedUrlForDownload {
      url
      status
    }
  }
}
    `
export const useCreateChatRoomMessageUploadedFileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateChatRoomMessageUploadedFileMutation, TError, CreateChatRoomMessageUploadedFileMutationVariables, TContext>,
) =>
  useMutation<CreateChatRoomMessageUploadedFileMutation, TError, CreateChatRoomMessageUploadedFileMutationVariables, TContext>(
    [ 'createChatRoomMessageUploadedFile' ],
    (variables?: CreateChatRoomMessageUploadedFileMutationVariables) => fetcher<CreateChatRoomMessageUploadedFileMutation, CreateChatRoomMessageUploadedFileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateChatRoomMessageUploadedFileDocument, variables)(),
    options,
  )
export const UpdateChatRoomMessageUploadedFileDocument = `
    mutation updateChatRoomMessageUploadedFile($input: UpdateUploadedFileInput!) {
  updateUploadedFile(input: $input) {
    id
    status
    fileName
    fileContentType
    fileSizeInBytes
  }
}
    `
export const useUpdateChatRoomMessageUploadedFileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateChatRoomMessageUploadedFileMutation, TError, UpdateChatRoomMessageUploadedFileMutationVariables, TContext>,
) =>
  useMutation<UpdateChatRoomMessageUploadedFileMutation, TError, UpdateChatRoomMessageUploadedFileMutationVariables, TContext>(
    [ 'updateChatRoomMessageUploadedFile' ],
    (variables?: UpdateChatRoomMessageUploadedFileMutationVariables) => fetcher<UpdateChatRoomMessageUploadedFileMutation, UpdateChatRoomMessageUploadedFileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateChatRoomMessageUploadedFileDocument, variables)(),
    options,
  )
export const ListNotesDocument = `
    query listNotes {
  listNotes {
    id
    title
    text
  }
}
    `
export const useListNotesQuery = <
  TData = ListNotesQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListNotesQueryVariables,
  options?: UseQueryOptions<ListNotesQuery, TError, TData>,
) =>
  useQuery<ListNotesQuery, TError, TData>(
    variables === undefined ? [ 'listNotes' ] : [ 'listNotes', variables ],
    fetcher<ListNotesQuery, ListNotesQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListNotesDocument, variables),
    options,
  )
useListNotesQuery.document = ListNotesDocument

useListNotesQuery.getKey = (variables?: ListNotesQueryVariables) => variables === undefined ? [ 'listNotes' ] : [ 'listNotes', variables ]

export const CreateNoteDocument = `
    mutation createNote($note: CreateNoteInput!) {
  createNote(note: $note) {
    id
    title
    text
  }
}
    `
export const useCreateNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateNoteMutation, TError, CreateNoteMutationVariables, TContext>,
) =>
  useMutation<CreateNoteMutation, TError, CreateNoteMutationVariables, TContext>(
    [ 'createNote' ],
    (variables?: CreateNoteMutationVariables) => fetcher<CreateNoteMutation, CreateNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateNoteDocument, variables)(),
    options,
  )
export const UpdateNoteDocument = `
    mutation updateNote($note: UpdateNoteInput!) {
  updateNote(note: $note) {
    id
    title
    text
  }
}
    `
export const useUpdateNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateNoteMutation, TError, UpdateNoteMutationVariables, TContext>,
) =>
  useMutation<UpdateNoteMutation, TError, UpdateNoteMutationVariables, TContext>(
    [ 'updateNote' ],
    (variables?: UpdateNoteMutationVariables) => fetcher<UpdateNoteMutation, UpdateNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateNoteDocument, variables)(),
    options,
  )
export const DeleteNoteDocument = `
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id) {
    id
    title
    text
  }
}
    `
export const useDeleteNoteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<DeleteNoteMutation, TError, DeleteNoteMutationVariables, TContext>,
) =>
  useMutation<DeleteNoteMutation, TError, DeleteNoteMutationVariables, TContext>(
    [ 'deleteNote' ],
    (variables?: DeleteNoteMutationVariables) => fetcher<DeleteNoteMutation, DeleteNoteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, DeleteNoteDocument, variables)(),
    options,
  )
export const RegisterMyDeviceForPushNotificationsDocument = `
    mutation registerMyDeviceForPushNotifications($deviceToken: String!) {
  registerMyDeviceForPushNotifications(deviceToken: $deviceToken) {
    id
    deviceToken
    individualId
    updatedAt
    createdAt
  }
}
    `
export const useRegisterMyDeviceForPushNotificationsMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RegisterMyDeviceForPushNotificationsMutation, TError, RegisterMyDeviceForPushNotificationsMutationVariables, TContext>,
) =>
  useMutation<RegisterMyDeviceForPushNotificationsMutation, TError, RegisterMyDeviceForPushNotificationsMutationVariables, TContext>(
    [ 'registerMyDeviceForPushNotifications' ],
    (variables?: RegisterMyDeviceForPushNotificationsMutationVariables) => fetcher<RegisterMyDeviceForPushNotificationsMutation, RegisterMyDeviceForPushNotificationsMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RegisterMyDeviceForPushNotificationsDocument, variables)(),
    options,
  )
export const ClaimInviteDocument = `
    mutation claimInvite($inviteId: ID!) {
  claimInvite(inviteId: $inviteId)
}
    `
export const useClaimInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<ClaimInviteMutation, TError, ClaimInviteMutationVariables, TContext>,
) =>
  useMutation<ClaimInviteMutation, TError, ClaimInviteMutationVariables, TContext>(
    [ 'claimInvite' ],
    (variables?: ClaimInviteMutationVariables) => fetcher<ClaimInviteMutation, ClaimInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ClaimInviteDocument, variables)(),
    options,
  )
export const DevListProjectsForSelectDocument = `
    query devListProjectsForSelect {
  listMyProjects {
    id
    title
  }
}
    `
export const useDevListProjectsForSelectQuery = <
  TData = DevListProjectsForSelectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: DevListProjectsForSelectQueryVariables,
  options?: UseQueryOptions<DevListProjectsForSelectQuery, TError, TData>,
) =>
  useQuery<DevListProjectsForSelectQuery, TError, TData>(
    variables === undefined ? [ 'devListProjectsForSelect' ] : [ 'devListProjectsForSelect', variables ],
    fetcher<DevListProjectsForSelectQuery, DevListProjectsForSelectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, DevListProjectsForSelectDocument, variables),
    options,
  )
useDevListProjectsForSelectQuery.document = DevListProjectsForSelectDocument

useDevListProjectsForSelectQuery.getKey = (variables?: DevListProjectsForSelectQueryVariables) => variables === undefined ? [ 'devListProjectsForSelect' ] : [ 'devListProjectsForSelect', variables ]

export const DevListAllProjectsForSelectDocument = `
    query devListAllProjectsForSelect {
  listAllProjects {
    id
    title
  }
}
    `
export const useDevListAllProjectsForSelectQuery = <
  TData = DevListAllProjectsForSelectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: DevListAllProjectsForSelectQueryVariables,
  options?: UseQueryOptions<DevListAllProjectsForSelectQuery, TError, TData>,
) =>
  useQuery<DevListAllProjectsForSelectQuery, TError, TData>(
    variables === undefined ? [ 'devListAllProjectsForSelect' ] : [ 'devListAllProjectsForSelect', variables ],
    fetcher<DevListAllProjectsForSelectQuery, DevListAllProjectsForSelectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, DevListAllProjectsForSelectDocument, variables),
    options,
  )
useDevListAllProjectsForSelectQuery.document = DevListAllProjectsForSelectDocument

useDevListAllProjectsForSelectQuery.getKey = (variables?: DevListAllProjectsForSelectQueryVariables) => variables === undefined ? [ 'devListAllProjectsForSelect' ] : [ 'devListAllProjectsForSelect', variables ]

export const DevProcessProjectActionDocument = `
    mutation devProcessProjectAction($action: ProjectActionInput!) {
  processProjectAction(action: $action)
}
    `
export const useDevProcessProjectActionMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<DevProcessProjectActionMutation, TError, DevProcessProjectActionMutationVariables, TContext>,
) =>
  useMutation<DevProcessProjectActionMutation, TError, DevProcessProjectActionMutationVariables, TContext>(
    [ 'devProcessProjectAction' ],
    (variables?: DevProcessProjectActionMutationVariables) => fetcher<DevProcessProjectActionMutation, DevProcessProjectActionMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, DevProcessProjectActionDocument, variables)(),
    options,
  )
export const DevRemoveIndividualFromTeamDocument = `
    mutation devRemoveIndividualFromTeam($individualId: String!, $teamId: String!) {
  removeIndividualFromTeam(individualId: $individualId, teamId: $teamId)
}
    `
export const useDevRemoveIndividualFromTeamMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<DevRemoveIndividualFromTeamMutation, TError, DevRemoveIndividualFromTeamMutationVariables, TContext>,
) =>
  useMutation<DevRemoveIndividualFromTeamMutation, TError, DevRemoveIndividualFromTeamMutationVariables, TContext>(
    [ 'devRemoveIndividualFromTeam' ],
    (variables?: DevRemoveIndividualFromTeamMutationVariables) => fetcher<DevRemoveIndividualFromTeamMutation, DevRemoveIndividualFromTeamMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, DevRemoveIndividualFromTeamDocument, variables)(),
    options,
  )
export const CreateStripeSessionForLeadPaymentDocument = `
    mutation createStripeSessionForLeadPayment($product: StripeProduct!, $leadId: ID!, $redirectBaseURL: String!) {
  createStripeSessionForLeadPayment(
    product: $product
    leadId: $leadId
    redirectBaseURL: $redirectBaseURL
  ) {
    id
    url
  }
}
    `
export const useCreateStripeSessionForLeadPaymentMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateStripeSessionForLeadPaymentMutation, TError, CreateStripeSessionForLeadPaymentMutationVariables, TContext>,
) =>
  useMutation<CreateStripeSessionForLeadPaymentMutation, TError, CreateStripeSessionForLeadPaymentMutationVariables, TContext>(
    [ 'createStripeSessionForLeadPayment' ],
    (variables?: CreateStripeSessionForLeadPaymentMutationVariables) => fetcher<CreateStripeSessionForLeadPaymentMutation, CreateStripeSessionForLeadPaymentMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateStripeSessionForLeadPaymentDocument, variables)(),
    options,
  )
export const ClaimNonCompanyTeamDocument = `
    mutation claimNonCompanyTeam($type: TeamType!, $name: String!) {
  claimNonCompanyTeam(type: $type, name: $name) {
    id
  }
}
    `
export const useClaimNonCompanyTeamMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<ClaimNonCompanyTeamMutation, TError, ClaimNonCompanyTeamMutationVariables, TContext>,
) =>
  useMutation<ClaimNonCompanyTeamMutation, TError, ClaimNonCompanyTeamMutationVariables, TContext>(
    [ 'claimNonCompanyTeam' ],
    (variables?: ClaimNonCompanyTeamMutationVariables) => fetcher<ClaimNonCompanyTeamMutation, ClaimNonCompanyTeamMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ClaimNonCompanyTeamDocument, variables)(),
    options,
  )
export const ClaimCompanyTeamDocument = `
    mutation claimCompanyTeam($type: TeamType!, $name: String!, $companyNumber: String!) {
  claimCompanyTeam(type: $type, name: $name, companyNumber: $companyNumber) {
    id
  }
}
    `
export const useClaimCompanyTeamMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<ClaimCompanyTeamMutation, TError, ClaimCompanyTeamMutationVariables, TContext>,
) =>
  useMutation<ClaimCompanyTeamMutation, TError, ClaimCompanyTeamMutationVariables, TContext>(
    [ 'claimCompanyTeam' ],
    (variables?: ClaimCompanyTeamMutationVariables) => fetcher<ClaimCompanyTeamMutation, ClaimCompanyTeamMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ClaimCompanyTeamDocument, variables)(),
    options,
  )
export const JoinWeaverTeamDocument = `
    mutation joinWeaverTeam {
  joinWeaverTeam {
    id
  }
}
    `
export const useJoinWeaverTeamMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<JoinWeaverTeamMutation, TError, JoinWeaverTeamMutationVariables, TContext>,
) =>
  useMutation<JoinWeaverTeamMutation, TError, JoinWeaverTeamMutationVariables, TContext>(
    [ 'joinWeaverTeam' ],
    (variables?: JoinWeaverTeamMutationVariables) => fetcher<JoinWeaverTeamMutation, JoinWeaverTeamMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, JoinWeaverTeamDocument, variables)(),
    options,
  )
export const CreateArchitectProfileDocument = `
    mutation createArchitectProfile($input: CreateArchitectProfile!) {
  createArchitectProfile(input: $input) {
    id
  }
}
    `
export const useCreateArchitectProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateArchitectProfileMutation, TError, CreateArchitectProfileMutationVariables, TContext>,
) =>
  useMutation<CreateArchitectProfileMutation, TError, CreateArchitectProfileMutationVariables, TContext>(
    [ 'createArchitectProfile' ],
    (variables?: CreateArchitectProfileMutationVariables) => fetcher<CreateArchitectProfileMutation, CreateArchitectProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateArchitectProfileDocument, variables)(),
    options,
  )
export const GetOnboardingArchitectProfileDocument = `
    query getOnboardingArchitectProfile($architectTeamId: ID!) {
  getArchitectProfile(architectTeamId: $architectTeamId) {
    id
    companyNumber
    companyRegisteredName
    companyTradingAs
    companyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
  }
}
    `
export const useGetOnboardingArchitectProfileQuery = <
  TData = GetOnboardingArchitectProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetOnboardingArchitectProfileQueryVariables,
  options?: UseQueryOptions<GetOnboardingArchitectProfileQuery, TError, TData>,
) =>
  useQuery<GetOnboardingArchitectProfileQuery, TError, TData>(
    [ 'getOnboardingArchitectProfile', variables ],
    fetcher<GetOnboardingArchitectProfileQuery, GetOnboardingArchitectProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOnboardingArchitectProfileDocument, variables),
    options,
  )
useGetOnboardingArchitectProfileQuery.document = GetOnboardingArchitectProfileDocument

useGetOnboardingArchitectProfileQuery.getKey = (variables: GetOnboardingArchitectProfileQueryVariables) => [ 'getOnboardingArchitectProfile', variables ]

export const CreateContractorProfileDocument = `
    mutation createContractorProfile($input: CreateContractorProfile!) {
  createContractorProfile(input: $input) {
    id
  }
}
    `
export const useCreateContractorProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateContractorProfileMutation, TError, CreateContractorProfileMutationVariables, TContext>,
) =>
  useMutation<CreateContractorProfileMutation, TError, CreateContractorProfileMutationVariables, TContext>(
    [ 'createContractorProfile' ],
    (variables?: CreateContractorProfileMutationVariables) => fetcher<CreateContractorProfileMutation, CreateContractorProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateContractorProfileDocument, variables)(),
    options,
  )
export const GetOnboardingContractorProfileDocument = `
    query getOnboardingContractorProfile($contractorTeamId: ID!) {
  getContractorProfile(contractorTeamId: $contractorTeamId) {
    id
    companyNumber
    companyRegisteredName
    companyTradingAs
    companyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
    onboardingPaymentEvent {
      actionedByIndividualId
      actionedAt
    }
  }
}
    `
export const useGetOnboardingContractorProfileQuery = <
  TData = GetOnboardingContractorProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetOnboardingContractorProfileQueryVariables,
  options?: UseQueryOptions<GetOnboardingContractorProfileQuery, TError, TData>,
) =>
  useQuery<GetOnboardingContractorProfileQuery, TError, TData>(
    [ 'getOnboardingContractorProfile', variables ],
    fetcher<GetOnboardingContractorProfileQuery, GetOnboardingContractorProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOnboardingContractorProfileDocument, variables),
    options,
  )
useGetOnboardingContractorProfileQuery.document = GetOnboardingContractorProfileDocument

useGetOnboardingContractorProfileQuery.getKey = (variables: GetOnboardingContractorProfileQueryVariables) => [ 'getOnboardingContractorProfile', variables ]

export const CreateHomeownerProfileDocument = `
    mutation createHomeownerProfile($input: CreateHomeownerProfile!) {
  createHomeownerProfile(input: $input) {
    id
  }
}
    `
export const useCreateHomeownerProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateHomeownerProfileMutation, TError, CreateHomeownerProfileMutationVariables, TContext>,
) =>
  useMutation<CreateHomeownerProfileMutation, TError, CreateHomeownerProfileMutationVariables, TContext>(
    [ 'createHomeownerProfile' ],
    (variables?: CreateHomeownerProfileMutationVariables) => fetcher<CreateHomeownerProfileMutation, CreateHomeownerProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateHomeownerProfileDocument, variables)(),
    options,
  )
export const GetOnboardingHomeownerProfileDocument = `
    query getOnboardingHomeownerProfile($homeownerTeamId: ID!) {
  getHomeownerProfile(homeownerTeamId: $homeownerTeamId) {
    id
    teamName
    propertyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
  }
}
    `
export const useGetOnboardingHomeownerProfileQuery = <
  TData = GetOnboardingHomeownerProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetOnboardingHomeownerProfileQueryVariables,
  options?: UseQueryOptions<GetOnboardingHomeownerProfileQuery, TError, TData>,
) =>
  useQuery<GetOnboardingHomeownerProfileQuery, TError, TData>(
    [ 'getOnboardingHomeownerProfile', variables ],
    fetcher<GetOnboardingHomeownerProfileQuery, GetOnboardingHomeownerProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOnboardingHomeownerProfileDocument, variables),
    options,
  )
useGetOnboardingHomeownerProfileQuery.document = GetOnboardingHomeownerProfileDocument

useGetOnboardingHomeownerProfileQuery.getKey = (variables: GetOnboardingHomeownerProfileQueryVariables) => [ 'getOnboardingHomeownerProfile', variables ]

export const GetPossibleCompanyNumbersForPartialCompanyNameDocument = `
    query getPossibleCompanyNumbersForPartialCompanyName($partialCompanyName: String!) {
  getPossibleCompanyNumbersForPartialCompanyName(
    partialCompanyName: $partialCompanyName
  ) {
    apiHealthy
    results {
      companyNumber
      companyName
      companyStatus
      sicCode_SicText_1
      incorporationDate
      regAddress_CareOf
      regAddress_POBox
      regAddress_AddressLine1
      regAddress_AddressLine2
      regAddress_PostTown
      regAddress_County
      regAddress_Country
      regAddress_PostCode
    }
  }
}
    `
export const useGetPossibleCompanyNumbersForPartialCompanyNameQuery = <
  TData = GetPossibleCompanyNumbersForPartialCompanyNameQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetPossibleCompanyNumbersForPartialCompanyNameQueryVariables,
  options?: UseQueryOptions<GetPossibleCompanyNumbersForPartialCompanyNameQuery, TError, TData>,
) =>
  useQuery<GetPossibleCompanyNumbersForPartialCompanyNameQuery, TError, TData>(
    [ 'getPossibleCompanyNumbersForPartialCompanyName', variables ],
    fetcher<GetPossibleCompanyNumbersForPartialCompanyNameQuery, GetPossibleCompanyNumbersForPartialCompanyNameQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetPossibleCompanyNumbersForPartialCompanyNameDocument, variables),
    options,
  )
useGetPossibleCompanyNumbersForPartialCompanyNameQuery.document = GetPossibleCompanyNumbersForPartialCompanyNameDocument

useGetPossibleCompanyNumbersForPartialCompanyNameQuery.getKey = (variables: GetPossibleCompanyNumbersForPartialCompanyNameQueryVariables) => [ 'getPossibleCompanyNumbersForPartialCompanyName', variables ]

export const SetOnboardingCompleteDocument = `
    mutation setOnboardingComplete($teamId: String!, $isOnboardingComplete: Boolean!) {
  setOnboardingComplete(
    teamId: $teamId
    isOnboardingComplete: $isOnboardingComplete
  )
}
    `
export const useSetOnboardingCompleteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SetOnboardingCompleteMutation, TError, SetOnboardingCompleteMutationVariables, TContext>,
) =>
  useMutation<SetOnboardingCompleteMutation, TError, SetOnboardingCompleteMutationVariables, TContext>(
    [ 'setOnboardingComplete' ],
    (variables?: SetOnboardingCompleteMutationVariables) => fetcher<SetOnboardingCompleteMutation, SetOnboardingCompleteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SetOnboardingCompleteDocument, variables)(),
    options,
  )
export const ResetContractorProfileWorkHistoryDocument = `
    mutation resetContractorProfileWorkHistory {
  resetContractorProfileWorkHistory
}
    `
export const useResetContractorProfileWorkHistoryMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<ResetContractorProfileWorkHistoryMutation, TError, ResetContractorProfileWorkHistoryMutationVariables, TContext>,
) =>
  useMutation<ResetContractorProfileWorkHistoryMutation, TError, ResetContractorProfileWorkHistoryMutationVariables, TContext>(
    [ 'resetContractorProfileWorkHistory' ],
    (variables?: ResetContractorProfileWorkHistoryMutationVariables) => fetcher<ResetContractorProfileWorkHistoryMutation, ResetContractorProfileWorkHistoryMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ResetContractorProfileWorkHistoryDocument, variables)(),
    options,
  )
export const LookupContractorProfileDocument = `
    query lookupContractorProfile($input: ContractorProfileLookupInput!) {
  lookupContractorProfile(input: $input) {
    contractorProfileId
    workHistoryId
    workHistoryReferenceId
  }
}
    `
export const useLookupContractorProfileQuery = <
  TData = LookupContractorProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: LookupContractorProfileQueryVariables,
  options?: UseQueryOptions<LookupContractorProfileQuery, TError, TData>,
) =>
  useQuery<LookupContractorProfileQuery, TError, TData>(
    [ 'lookupContractorProfile', variables ],
    fetcher<LookupContractorProfileQuery, LookupContractorProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, LookupContractorProfileDocument, variables),
    options,
  )
useLookupContractorProfileQuery.document = LookupContractorProfileDocument

useLookupContractorProfileQuery.getKey = (variables: LookupContractorProfileQueryVariables) => [ 'lookupContractorProfile', variables ]

export const GetContractorProfileDocument = `
    query getContractorProfile($contractorTeamId: ID!) {
  getContractorProfile(contractorTeamId: $contractorTeamId) {
    id
    companyNumber
    companyRegisteredName
    companyTradingAs
    companyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
    workHistory {
      id
      projectAddress {
        careOf
        poBox
        addressLine1
        addressLine2
        postTown
        county
        country
        postCode
      }
      constructionValue {
        currency
        amountInPence
      }
      projectTypes
      references {
        id
        teamType
        companyName
        givenName
        familyName
        email
        phoneNumber
        status
      }
      photos {
        id
        status
        fileName
        fileContentType
        fileSizeInBytes
        signedUrlForDownload {
          url
          status
        }
        createdAt
        updatedAt
      }
    }
    budgetRangeBadges {
      budgetRange
    }
    budgetRangeSubscriptions {
      id
      budgetRange
    }
    locationAreas
  }
}
    `
export const useGetContractorProfileQuery = <
  TData = GetContractorProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetContractorProfileQueryVariables,
  options?: UseQueryOptions<GetContractorProfileQuery, TError, TData>,
) =>
  useQuery<GetContractorProfileQuery, TError, TData>(
    [ 'getContractorProfile', variables ],
    fetcher<GetContractorProfileQuery, GetContractorProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetContractorProfileDocument, variables),
    options,
  )
useGetContractorProfileQuery.document = GetContractorProfileDocument

useGetContractorProfileQuery.getKey = (variables: GetContractorProfileQueryVariables) => [ 'getContractorProfile', variables ]

export const UpdateContractorProfileDocument = `
    mutation updateContractorProfile($input: UpdateContractorProfile!) {
  updateContractorProfile(input: $input) {
    id
    companyTradingAs
  }
}
    `
export const useUpdateContractorProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorProfileMutation, TError, UpdateContractorProfileMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorProfileMutation, TError, UpdateContractorProfileMutationVariables, TContext>(
    [ 'updateContractorProfile' ],
    (variables?: UpdateContractorProfileMutationVariables) => fetcher<UpdateContractorProfileMutation, UpdateContractorProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorProfileDocument, variables)(),
    options,
  )
export const CreateContractorProfileWorkHistoryDocument = `
    mutation createContractorProfileWorkHistory($input: CreateContractorProfileWorkHistory!) {
  createContractorProfileWorkHistory(input: $input) {
    id
  }
}
    `
export const useCreateContractorProfileWorkHistoryMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateContractorProfileWorkHistoryMutation, TError, CreateContractorProfileWorkHistoryMutationVariables, TContext>,
) =>
  useMutation<CreateContractorProfileWorkHistoryMutation, TError, CreateContractorProfileWorkHistoryMutationVariables, TContext>(
    [ 'createContractorProfileWorkHistory' ],
    (variables?: CreateContractorProfileWorkHistoryMutationVariables) => fetcher<CreateContractorProfileWorkHistoryMutation, CreateContractorProfileWorkHistoryMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateContractorProfileWorkHistoryDocument, variables)(),
    options,
  )
export const UpdateContractorProfileWorkHistoryDocument = `
    mutation updateContractorProfileWorkHistory($input: UpdateContractorProfileWorkHistory!) {
  updateContractorProfileWorkHistory(input: $input) {
    id
  }
}
    `
export const useUpdateContractorProfileWorkHistoryMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorProfileWorkHistoryMutation, TError, UpdateContractorProfileWorkHistoryMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorProfileWorkHistoryMutation, TError, UpdateContractorProfileWorkHistoryMutationVariables, TContext>(
    [ 'updateContractorProfileWorkHistory' ],
    (variables?: UpdateContractorProfileWorkHistoryMutationVariables) => fetcher<UpdateContractorProfileWorkHistoryMutation, UpdateContractorProfileWorkHistoryMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorProfileWorkHistoryDocument, variables)(),
    options,
  )
export const CreateContractorProfileWorkHistoryReferenceDocument = `
    mutation createContractorProfileWorkHistoryReference($input: CreateContractorProfileWorkHistoryReferenceForWorkHistory!) {
  createContractorProfileWorkHistoryReference(input: $input) {
    id
  }
}
    `
export const useCreateContractorProfileWorkHistoryReferenceMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateContractorProfileWorkHistoryReferenceMutation, TError, CreateContractorProfileWorkHistoryReferenceMutationVariables, TContext>,
) =>
  useMutation<CreateContractorProfileWorkHistoryReferenceMutation, TError, CreateContractorProfileWorkHistoryReferenceMutationVariables, TContext>(
    [ 'createContractorProfileWorkHistoryReference' ],
    (variables?: CreateContractorProfileWorkHistoryReferenceMutationVariables) => fetcher<CreateContractorProfileWorkHistoryReferenceMutation, CreateContractorProfileWorkHistoryReferenceMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateContractorProfileWorkHistoryReferenceDocument, variables)(),
    options,
  )
export const UpdateContractorProfileWorkHistoryReferenceDocument = `
    mutation updateContractorProfileWorkHistoryReference($input: UpdateContractorProfileWorkHistoryReferenceForWorkHistory!) {
  updateContractorProfileWorkHistoryReference(input: $input) {
    id
  }
}
    `
export const useUpdateContractorProfileWorkHistoryReferenceMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateContractorProfileWorkHistoryReferenceMutation, TError, UpdateContractorProfileWorkHistoryReferenceMutationVariables, TContext>,
) =>
  useMutation<UpdateContractorProfileWorkHistoryReferenceMutation, TError, UpdateContractorProfileWorkHistoryReferenceMutationVariables, TContext>(
    [ 'updateContractorProfileWorkHistoryReference' ],
    (variables?: UpdateContractorProfileWorkHistoryReferenceMutationVariables) => fetcher<UpdateContractorProfileWorkHistoryReferenceMutation, UpdateContractorProfileWorkHistoryReferenceMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateContractorProfileWorkHistoryReferenceDocument, variables)(),
    options,
  )
export const CreateContractorProfileWorkHistoryPhotoDocument = `
    mutation createContractorProfileWorkHistoryPhoto($workHistoryId: ID!, $uploadedFileId: ID!) {
  createContractorProfileWorkHistoryPhoto(
    workHistoryId: $workHistoryId
    uploadedFileId: $uploadedFileId
  ) {
    id
  }
}
    `
export const useCreateContractorProfileWorkHistoryPhotoMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateContractorProfileWorkHistoryPhotoMutation, TError, CreateContractorProfileWorkHistoryPhotoMutationVariables, TContext>,
) =>
  useMutation<CreateContractorProfileWorkHistoryPhotoMutation, TError, CreateContractorProfileWorkHistoryPhotoMutationVariables, TContext>(
    [ 'createContractorProfileWorkHistoryPhoto' ],
    (variables?: CreateContractorProfileWorkHistoryPhotoMutationVariables) => fetcher<CreateContractorProfileWorkHistoryPhotoMutation, CreateContractorProfileWorkHistoryPhotoMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateContractorProfileWorkHistoryPhotoDocument, variables)(),
    options,
  )
export const AwardContractorProfileBudgetRangeBadgeDocument = `
    mutation awardContractorProfileBudgetRangeBadge($budgetRange: BudgetRange!) {
  awardContractorProfileBudgetRangeBadge(budgetRange: $budgetRange)
}
    `
export const useAwardContractorProfileBudgetRangeBadgeMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AwardContractorProfileBudgetRangeBadgeMutation, TError, AwardContractorProfileBudgetRangeBadgeMutationVariables, TContext>,
) =>
  useMutation<AwardContractorProfileBudgetRangeBadgeMutation, TError, AwardContractorProfileBudgetRangeBadgeMutationVariables, TContext>(
    [ 'awardContractorProfileBudgetRangeBadge' ],
    (variables?: AwardContractorProfileBudgetRangeBadgeMutationVariables) => fetcher<AwardContractorProfileBudgetRangeBadgeMutation, AwardContractorProfileBudgetRangeBadgeMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AwardContractorProfileBudgetRangeBadgeDocument, variables)(),
    options,
  )
export const GetArchitectProfileDocument = `
    query getArchitectProfile($architectTeamId: ID!) {
  getArchitectProfile(architectTeamId: $architectTeamId) {
    id
    companyNumber
    companyRegisteredName
    companyTradingAs
    companyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
  }
}
    `
export const useGetArchitectProfileQuery = <
  TData = GetArchitectProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetArchitectProfileQueryVariables,
  options?: UseQueryOptions<GetArchitectProfileQuery, TError, TData>,
) =>
  useQuery<GetArchitectProfileQuery, TError, TData>(
    [ 'getArchitectProfile', variables ],
    fetcher<GetArchitectProfileQuery, GetArchitectProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetArchitectProfileDocument, variables),
    options,
  )
useGetArchitectProfileQuery.document = GetArchitectProfileDocument

useGetArchitectProfileQuery.getKey = (variables: GetArchitectProfileQueryVariables) => [ 'getArchitectProfile', variables ]

export const GetHomeownerProfileDocument = `
    query getHomeownerProfile($homeownerTeamId: ID!) {
  getHomeownerProfile(homeownerTeamId: $homeownerTeamId) {
    id
    teamName
    propertyAddress {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
  }
}
    `
export const useGetHomeownerProfileQuery = <
  TData = GetHomeownerProfileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetHomeownerProfileQueryVariables,
  options?: UseQueryOptions<GetHomeownerProfileQuery, TError, TData>,
) =>
  useQuery<GetHomeownerProfileQuery, TError, TData>(
    [ 'getHomeownerProfile', variables ],
    fetcher<GetHomeownerProfileQuery, GetHomeownerProfileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetHomeownerProfileDocument, variables),
    options,
  )
useGetHomeownerProfileQuery.document = GetHomeownerProfileDocument

useGetHomeownerProfileQuery.getKey = (variables: GetHomeownerProfileQueryVariables) => [ 'getHomeownerProfile', variables ]

export const GetAllTeamsDocument = `
    query getAllTeams {
  listAllTeams {
    id
    name
    type
    companyNumber
    individualConnections {
      id
      individual {
        id
        givenName
        familyName
        pictureURL
      }
    }
  }
}
    `
export const useGetAllTeamsQuery = <
  TData = GetAllTeamsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetAllTeamsQueryVariables,
  options?: UseQueryOptions<GetAllTeamsQuery, TError, TData>,
) =>
  useQuery<GetAllTeamsQuery, TError, TData>(
    variables === undefined ? [ 'getAllTeams' ] : [ 'getAllTeams', variables ],
    fetcher<GetAllTeamsQuery, GetAllTeamsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetAllTeamsDocument, variables),
    options,
  )
useGetAllTeamsQuery.document = GetAllTeamsDocument

useGetAllTeamsQuery.getKey = (variables?: GetAllTeamsQueryVariables) => variables === undefined ? [ 'getAllTeams' ] : [ 'getAllTeams', variables ]

export const GetUploadedFileDocument = `
    query getUploadedFile($id: ID!, $config: DownloadConfigInput) {
  getUploadedFile(id: $id) {
    id
    status
    fileName
    fileContentType
    updatedAt
    fileSizeInBytes
    signedUrlForDownload(config: $config) {
      url
      status
    }
    status
    uploadedByTeam {
      name
    }
  }
}
    `
export const useGetUploadedFileQuery = <
  TData = GetUploadedFileQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: GetUploadedFileQueryVariables,
  options?: UseQueryOptions<GetUploadedFileQuery, TError, TData>,
) =>
  useQuery<GetUploadedFileQuery, TError, TData>(
    [ 'getUploadedFile', variables ],
    fetcher<GetUploadedFileQuery, GetUploadedFileQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetUploadedFileDocument, variables),
    options,
  )
useGetUploadedFileQuery.document = GetUploadedFileDocument

useGetUploadedFileQuery.getKey = (variables: GetUploadedFileQueryVariables) => [ 'getUploadedFile', variables ]

export const UpdateArchitectProfileDocument = `
    mutation updateArchitectProfile($input: UpdateArchitectProfile!) {
  updateArchitectProfile(input: $input) {
    id
    companyTradingAs
  }
}
    `
export const useUpdateArchitectProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateArchitectProfileMutation, TError, UpdateArchitectProfileMutationVariables, TContext>,
) =>
  useMutation<UpdateArchitectProfileMutation, TError, UpdateArchitectProfileMutationVariables, TContext>(
    [ 'updateArchitectProfile' ],
    (variables?: UpdateArchitectProfileMutationVariables) => fetcher<UpdateArchitectProfileMutation, UpdateArchitectProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateArchitectProfileDocument, variables)(),
    options,
  )
export const UpdateHomeownerProfileDocument = `
    mutation updateHomeownerProfile($input: UpdateHomeownerProfile!) {
  updateHomeownerProfile(input: $input) {
    id
    teamName
  }
}
    `
export const useUpdateHomeownerProfileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateHomeownerProfileMutation, TError, UpdateHomeownerProfileMutationVariables, TContext>,
) =>
  useMutation<UpdateHomeownerProfileMutation, TError, UpdateHomeownerProfileMutationVariables, TContext>(
    [ 'updateHomeownerProfile' ],
    (variables?: UpdateHomeownerProfileMutationVariables) => fetcher<UpdateHomeownerProfileMutation, UpdateHomeownerProfileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateHomeownerProfileDocument, variables)(),
    options,
  )
export const CreateUploadedFileDocument = `
    mutation createUploadedFile($input: CreateUploadedFileInput!) {
  createUploadedFile(input: $input) {
    id
    status
    signedUrlForUpload {
      url
      status
    }
  }
}
    `
export const useCreateUploadedFileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateUploadedFileMutation, TError, CreateUploadedFileMutationVariables, TContext>,
) =>
  useMutation<CreateUploadedFileMutation, TError, CreateUploadedFileMutationVariables, TContext>(
    [ 'createUploadedFile' ],
    (variables?: CreateUploadedFileMutationVariables) => fetcher<CreateUploadedFileMutation, CreateUploadedFileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateUploadedFileDocument, variables)(),
    options,
  )
export const UpdateUploadedFileDocument = `
    mutation updateUploadedFile($input: UpdateUploadedFileInput!) {
  updateUploadedFile(input: $input) {
    id
  }
}
    `
export const useUpdateUploadedFileMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateUploadedFileMutation, TError, UpdateUploadedFileMutationVariables, TContext>,
) =>
  useMutation<UpdateUploadedFileMutation, TError, UpdateUploadedFileMutationVariables, TContext>(
    [ 'updateUploadedFile' ],
    (variables?: UpdateUploadedFileMutationVariables) => fetcher<UpdateUploadedFileMutation, UpdateUploadedFileMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateUploadedFileDocument, variables)(),
    options,
  )
export const GetStripeProductConfigsDocument = `
    query getStripeProductConfigs {
  getStripeProductConfigs {
    product
    productId
    priceId
    price {
      currency
      amountInPence
    }
    isRecurring
    isLive
  }
}
    `
export const useGetStripeProductConfigsQuery = <
  TData = GetStripeProductConfigsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: GetStripeProductConfigsQueryVariables,
  options?: UseQueryOptions<GetStripeProductConfigsQuery, TError, TData>,
) =>
  useQuery<GetStripeProductConfigsQuery, TError, TData>(
    variables === undefined ? [ 'getStripeProductConfigs' ] : [ 'getStripeProductConfigs', variables ],
    fetcher<GetStripeProductConfigsQuery, GetStripeProductConfigsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetStripeProductConfigsDocument, variables),
    options,
  )
useGetStripeProductConfigsQuery.document = GetStripeProductConfigsDocument

useGetStripeProductConfigsQuery.getKey = (variables?: GetStripeProductConfigsQueryVariables) => variables === undefined ? [ 'getStripeProductConfigs' ] : [ 'getStripeProductConfigs', variables ]

export const ListMyProjectsAndLeadsDocument = `
    query listMyProjectsAndLeads {
  listMyLeads {
    id
    title
    description
    approximateLocation
    lastAccessed
  }
  listMyProjects {
    id
    title
    description
    status
    lastAccessed
  }
}
    `
export const useListMyProjectsAndLeadsQuery = <
  TData = ListMyProjectsAndLeadsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListMyProjectsAndLeadsQueryVariables,
  options?: UseQueryOptions<ListMyProjectsAndLeadsQuery, TError, TData>,
) =>
  useQuery<ListMyProjectsAndLeadsQuery, TError, TData>(
    variables === undefined ? [ 'listMyProjectsAndLeads' ] : [ 'listMyProjectsAndLeads', variables ],
    fetcher<ListMyProjectsAndLeadsQuery, ListMyProjectsAndLeadsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListMyProjectsAndLeadsDocument, variables),
    options,
  )
useListMyProjectsAndLeadsQuery.document = ListMyProjectsAndLeadsDocument

useListMyProjectsAndLeadsQuery.getKey = (variables?: ListMyProjectsAndLeadsQueryVariables) => variables === undefined ? [ 'listMyProjectsAndLeads' ] : [ 'listMyProjectsAndLeads', variables ]

export const ShowProjectDocument = `
    query showProject($id: ID!, $config: DownloadConfigInput) {
  getProject(id: $id) {
    id
    title
    description
    status
    projectTypes
    members {
      id
      projectRole
      team {
        id
        name
        type
      }
      createdAt
    }
    memberSlots {
      id
      requiredTeamType
      requiredProjectRole
    }
    memberInvites {
      id
      requiredTeamType
      requiredProjectRole
      team {
        id
        name
        type
      }
      companyName
      givenName
      familyName
      createdAt
    }
    address {
      careOf
      poBox
      addressLine1
      addressLine2
      postTown
      county
      country
      postCode
    }
    budgetCategory
    budgetValue {
      currency
      amountInPence
    }
    documents {
      id
      fileName
      fileSizeInBytes
      fileContentType
      updatedAt
      signedUrlForDownload(config: $config) {
        status
        url
      }
      status
      uploadedByTeam {
        name
      }
    }
    tenderReturnDate
    workStartEstimate
    lastAccessed
  }
}
    `
export const useShowProjectQuery = <
  TData = ShowProjectQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ShowProjectQueryVariables,
  options?: UseQueryOptions<ShowProjectQuery, TError, TData>,
) =>
  useQuery<ShowProjectQuery, TError, TData>(
    [ 'showProject', variables ],
    fetcher<ShowProjectQuery, ShowProjectQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ShowProjectDocument, variables),
    options,
  )
useShowProjectQuery.document = ShowProjectDocument

useShowProjectQuery.getKey = (variables: ShowProjectQueryVariables) => [ 'showProject', variables ]

export const ListAllTeamsDocument = `
    query listAllTeams {
  listAllTeams {
    id
    name
  }
}
    `
export const useListAllTeamsQuery = <
  TData = ListAllTeamsQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables?: ListAllTeamsQueryVariables,
  options?: UseQueryOptions<ListAllTeamsQuery, TError, TData>,
) =>
  useQuery<ListAllTeamsQuery, TError, TData>(
    variables === undefined ? [ 'listAllTeams' ] : [ 'listAllTeams', variables ],
    fetcher<ListAllTeamsQuery, ListAllTeamsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListAllTeamsDocument, variables),
    options,
  )
useListAllTeamsQuery.document = ListAllTeamsDocument

useListAllTeamsQuery.getKey = (variables?: ListAllTeamsQueryVariables) => variables === undefined ? [ 'listAllTeams' ] : [ 'listAllTeams', variables ]

export const CreateProjectDocument = `
    mutation createProject($project: CreateProjectInput!) {
  createProject(project: $project) {
    id
    memberInvites {
      id
    }
  }
}
    `
export const useCreateProjectMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<CreateProjectMutation, TError, CreateProjectMutationVariables, TContext>,
) =>
  useMutation<CreateProjectMutation, TError, CreateProjectMutationVariables, TContext>(
    [ 'createProject' ],
    (variables?: CreateProjectMutationVariables) => fetcher<CreateProjectMutation, CreateProjectMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, CreateProjectDocument, variables)(),
    options,
  )
export const AddProjectInviteDocument = `
    mutation addProjectInvite($projectId: ID!, $teamId: ID!, $projectMemberRole: ProjectMemberRole!) {
  addProjectInvite(
    projectId: $projectId
    teamId: $teamId
    projectMemberRole: $projectMemberRole
  )
}
    `
export const useAddProjectInviteMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>,
) =>
  useMutation<AddProjectInviteMutation, TError, AddProjectInviteMutationVariables, TContext>(
    [ 'addProjectInvite' ],
    (variables?: AddProjectInviteMutationVariables) => fetcher<AddProjectInviteMutation, AddProjectInviteMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddProjectInviteDocument, variables)(),
    options,
  )
export const JoinChatRoomForIndividualDocument = `
    mutation joinChatRoomForIndividual($chatRoomId: ID!, $individualId: ID!) {
  joinChatRoomForIndividual(chatRoomId: $chatRoomId, individualId: $individualId) {
    id
  }
}
    `
export const useJoinChatRoomForIndividualMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<JoinChatRoomForIndividualMutation, TError, JoinChatRoomForIndividualMutationVariables, TContext>,
) =>
  useMutation<JoinChatRoomForIndividualMutation, TError, JoinChatRoomForIndividualMutationVariables, TContext>(
    [ 'joinChatRoomForIndividual' ],
    (variables?: JoinChatRoomForIndividualMutationVariables) => fetcher<JoinChatRoomForIndividualMutation, JoinChatRoomForIndividualMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, JoinChatRoomForIndividualDocument, variables)(),
    options,
  )
export const AddUploadedFileToProjectFilesDocument = `
    mutation addUploadedFileToProjectFiles($projectId: ID!, $uploadedFileId: ID!) {
  addUploadedFileToProjectFiles(
    projectId: $projectId
    uploadedFileId: $uploadedFileId
  ) {
    id
  }
}
    `
export const useAddUploadedFileToProjectFilesMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AddUploadedFileToProjectFilesMutation, TError, AddUploadedFileToProjectFilesMutationVariables, TContext>,
) =>
  useMutation<AddUploadedFileToProjectFilesMutation, TError, AddUploadedFileToProjectFilesMutationVariables, TContext>(
    [ 'addUploadedFileToProjectFiles' ],
    (variables?: AddUploadedFileToProjectFilesMutationVariables) => fetcher<AddUploadedFileToProjectFilesMutation, AddUploadedFileToProjectFilesMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AddUploadedFileToProjectFilesDocument, variables)(),
    options,
  )
export const IncrementProjectDocumentCountDocument = `
    mutation incrementProjectDocumentCount($input: IncrementProjectDocumentCount!) {
  incrementProjectDocumentCount(input: $input)
}
    `
export const useIncrementProjectDocumentCountMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<IncrementProjectDocumentCountMutation, TError, IncrementProjectDocumentCountMutationVariables, TContext>,
) =>
  useMutation<IncrementProjectDocumentCountMutation, TError, IncrementProjectDocumentCountMutationVariables, TContext>(
    [ 'incrementProjectDocumentCount' ],
    (variables?: IncrementProjectDocumentCountMutationVariables) => fetcher<IncrementProjectDocumentCountMutation, IncrementProjectDocumentCountMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, IncrementProjectDocumentCountDocument, variables)(),
    options,
  )
export const InviteContractorToMyProjectDocument = `
    mutation inviteContractorToMyProject($input: InviteToMyProjectInput!) {
  inviteToMyProject(
    requiredTeamType: CONTRACTOR
    requiredProjectRole: CANDIDATE_PROFESSIONAL
    input: $input
  ) {
    id
  }
}
    `
export const useInviteContractorToMyProjectMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<InviteContractorToMyProjectMutation, TError, InviteContractorToMyProjectMutationVariables, TContext>,
) =>
  useMutation<InviteContractorToMyProjectMutation, TError, InviteContractorToMyProjectMutationVariables, TContext>(
    [ 'inviteContractorToMyProject' ],
    (variables?: InviteContractorToMyProjectMutationVariables) => fetcher<InviteContractorToMyProjectMutation, InviteContractorToMyProjectMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InviteContractorToMyProjectDocument, variables)(),
    options,
  )
export const ListProjectTasksDocument = `
    query listProjectTasks($id: ID!) {
  listProjectTasks(projectId: $id) {
    ...taskFields
    childTasks {
      ...taskFields
      childTasks {
        ...taskFields
      }
    }
  }
}
    ${TaskFieldsFragmentDoc}`
export const useListProjectTasksQuery = <
  TData = ListProjectTasksQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ListProjectTasksQueryVariables,
  options?: UseQueryOptions<ListProjectTasksQuery, TError, TData>,
) =>
  useQuery<ListProjectTasksQuery, TError, TData>(
    [ 'listProjectTasks', variables ],
    fetcher<ListProjectTasksQuery, ListProjectTasksQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ListProjectTasksDocument, variables),
    options,
  )
useListProjectTasksQuery.document = ListProjectTasksDocument

useListProjectTasksQuery.getKey = (variables: ListProjectTasksQueryVariables) => [ 'listProjectTasks', variables ]

export const UpdateProjectTaskStatusDocument = `
    mutation updateProjectTaskStatus($input: UpdateProjectTaskStatus!) {
  updateProjectTaskStatus(input: $input)
}
    `
export const useUpdateProjectTaskStatusMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<UpdateProjectTaskStatusMutation, TError, UpdateProjectTaskStatusMutationVariables, TContext>,
) =>
  useMutation<UpdateProjectTaskStatusMutation, TError, UpdateProjectTaskStatusMutationVariables, TContext>(
    [ 'updateProjectTaskStatus' ],
    (variables?: UpdateProjectTaskStatusMutationVariables) => fetcher<UpdateProjectTaskStatusMutation, UpdateProjectTaskStatusMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateProjectTaskStatusDocument, variables)(),
    options,
  )
export const ShowLeadDocument = `
    query showLead($id: ID!) {
  getLead(id: $id) {
    id
    title
    description
    approximateLocation
    budgetCategory
    projectTypes
    budgetValue {
      currency
      amountInPence
    }
    tenderReturnDate
    workStartEstimate
    lastAccessed
    referral {
      type
    }
  }
}
    `
export const useShowLeadQuery = <
  TData = ShowLeadQuery,
  TError = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  variables: ShowLeadQueryVariables,
  options?: UseQueryOptions<ShowLeadQuery, TError, TData>,
) =>
  useQuery<ShowLeadQuery, TError, TData>(
    [ 'showLead', variables ],
    fetcher<ShowLeadQuery, ShowLeadQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, ShowLeadDocument, variables),
    options,
  )
useShowLeadQuery.document = ShowLeadDocument

useShowLeadQuery.getKey = (variables: ShowLeadQueryVariables) => [ 'showLead', variables ]

export const AcceptLeadDocument = `
    mutation acceptLead($id: ID!) {
  acceptLead(projectId: $id)
}
    `
export const useAcceptLeadMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<AcceptLeadMutation, TError, AcceptLeadMutationVariables, TContext>,
) =>
  useMutation<AcceptLeadMutation, TError, AcceptLeadMutationVariables, TContext>(
    [ 'acceptLead' ],
    (variables?: AcceptLeadMutationVariables) => fetcher<AcceptLeadMutation, AcceptLeadMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, AcceptLeadDocument, variables)(),
    options,
  )
export const RejectLeadDocument = `
    mutation rejectLead($id: ID!) {
  rejectLead(projectId: $id)
}
    `
export const useRejectLeadMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<RejectLeadMutation, TError, RejectLeadMutationVariables, TContext>,
) =>
  useMutation<RejectLeadMutation, TError, RejectLeadMutationVariables, TContext>(
    [ 'rejectLead' ],
    (variables?: RejectLeadMutationVariables) => fetcher<RejectLeadMutation, RejectLeadMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, RejectLeadDocument, variables)(),
    options,
  )
export const SetIndividualPhoneNumberDocument = `
    mutation setIndividualPhoneNumber($phoneNumber: String!, $individualId: ID) {
  setIndividualPhoneNumber(phoneNumber: $phoneNumber, individualId: $individualId)
}
    `
export const useSetIndividualPhoneNumberMutation = <
  TError = unknown,
  TContext = unknown
>(
  dataSource: { endpoint: string, fetchParams?: RequestInit },
  options?: UseMutationOptions<SetIndividualPhoneNumberMutation, TError, SetIndividualPhoneNumberMutationVariables, TContext>,
) =>
  useMutation<SetIndividualPhoneNumberMutation, TError, SetIndividualPhoneNumberMutationVariables, TContext>(
    [ 'setIndividualPhoneNumber' ],
    (variables?: SetIndividualPhoneNumberMutationVariables) => fetcher<SetIndividualPhoneNumberMutation, SetIndividualPhoneNumberMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, SetIndividualPhoneNumberDocument, variables)(),
    options,
  )