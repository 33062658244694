import { useFlags } from "launchdarkly-react-client-sdk"
import { z } from "zod"

/**
 * The schema for the LaunchDarkly flags that might come back.
 */
export const zWeaverFlags = z.object({
  'miscellaneous-menu': z.object({
    enabled: z.boolean(),
  }),
  'minimum-individual-profile': z.object({
    enabled: z.boolean(),
    disableAspect: z.object({
      'require-phone-number': z.boolean().optional(),
    }).optional(),
  }),
  'contractor-profile-budget-preferences': z.object({
    enabled: z.boolean(),
  }),
  'contractor-lead-acceptor': z.object({
    enabled: z.boolean(),
  }),
  'create-project-contractors': z.object({
    // We can't disable this one (as the text is used)
    contractorCount: z.number(),
    title: z.string(),
    description: z.string(),
    originalPrice: z.string(),
    currentPrice: z.string(),
    guaranteeText: z.string(),
  }),
  'project-leads': z.object({
    // We can't disable this one
    showMap: z.boolean(),
  }),
  'tasks': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showProjectList: z.boolean(),
      showChatNextAction: z.boolean(), // For future use
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'files': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showChatMessageFiles: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
})

export type WeaverFlags = z.infer<typeof zWeaverFlags>

export const defaultWeaverFlags: WeaverFlags = {
  'miscellaneous-menu': {
    enabled: false,
  },
  'minimum-individual-profile': {
    enabled: false,
  },
  'contractor-profile-budget-preferences': {
    enabled: false,
  },
  'contractor-lead-acceptor': {
    enabled: false,
  },
  'create-project-contractors': {
    title: "Weaver will match up to 3 Contractors for your project.",
    description: "Weaver recommends a maximum of 3 contractors per tender for the best results.",
    contractorCount: 3,
    originalPrice: "£2,995",
    currentPrice: "FREE!",
    guaranteeText: "We guarantee the quality of our contractor quotes. If they don’t meet your expectations, we will refund your fee.",
  },
  'project-leads': {
    showMap: false,
  },
  'tasks': {
    enabled: false,
  },
  'files': {
    enabled: true,
    showChatMessageFiles: true,
  },
}

/**
 * Returns a strongly typed set of LaunchDarkly flags, or throws an error.
 * WARNING: Changes to LaunchDarkly config will change this behaviour!
 */
export const useWeaverFlags = (): WeaverFlags => {
  const parsedFlags = zWeaverFlags.safeParse(useFlags())

  if (!parsedFlags.success) {
    console.error('[useWeaverFlags] Unable to parse the flags.', parsedFlags.error)
    return defaultWeaverFlags
  }

  return parsedFlags.data
}
