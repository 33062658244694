import React from "react"
import buildersPng from '../../../assets/images/builders.png'
import TitleWithBulletsPage from "../../../domains/onboarding/team/pages/TitleWithBulletsPage"

const ContactSupportScreen = () =>
  <TitleWithBulletsPage
    title="You are about to be transferred to our Live Support Team "
    imgProps={{
      src: buildersPng,
    }}
    bullets={[]}
  />

export default ContactSupportScreen
