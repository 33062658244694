import React from 'react'
import { IonItem, IonGrid, IonRow, IonCol } from '@ionic/react'
import { isSomething } from '../../utils'
import Styles from "./GenericSelectorList.module.scss"

/**
 * This Generic Selector List is a strongly typed component renders a selectable list of components.
 *
 * It takes a list of `GenericSelectorOption<T>` as `options` to choose from.
 * The options are typed based on the `option.value` passed in. This value is what is returned when selected.
 * An optional `render` function is called when rendering them, by default it just renderes the optional `option.label`.
 *
 * The controller supports both single and multi select, toggling the state of the single value or value within the list of selected values.
 */

export type GenericSelectorOption<T> = {
  key: string,
  label?: string,
  itemSubtext?: string,
  value: T,
  itemClassName?: string,
  itemSuffix?: JSX.Element,
}

type MultiSelectProps<T> = {
  multiSelect: true,
  /** will be empty array `[]` if the selection is empty */
  selected: T[],
  onSelect: (values: T[]) => void,
  showItemDetail?: boolean,
  colSize?: string,
  maxSelected?: number,
} | {
  multiSelect?: false,
  /**  will be undefined if the selection is empty */
  selected: T | undefined,
  onSelect: (value: T | undefined) => void,
  showItemDetail?: boolean,
  colSize?: string,
}

type GenericSelectorListProps<T> = {
  options: GenericSelectorOption<T>[],
  render?: (option: GenericSelectorOption<T>) => React.ReactNode | JSX.Element,
  disabled?: boolean,
} & MultiSelectProps<T>

const GenericSelectorList = <T,>(props: GenericSelectorListProps<T>) => {
  const selectedValues = props.multiSelect
    ? props.selected
    : [ props.selected ]

  const maxSelected = props.multiSelect && props.maxSelected

  const hasMaxSelected = (updatedSelected: T[]) => {
    if (typeof maxSelected === 'number'){
      return updatedSelected.length > maxSelected
    }
    return false
  }

  const toggleSelectedValue = (value: T) => {

    if (props.multiSelect) {
      const updatedSelected = props.selected.includes(value)
        ? props.selected.filter(selectedValue => selectedValue !== value)
        : [ ...props.selected, value ]

      if (hasMaxSelected(updatedSelected)) return

      props.onSelect(updatedSelected)

    } else {
      const updatedSelected = props.selected === value
        ? undefined
        : value

      props.onSelect(updatedSelected)
    }
  }

  return <IonGrid>
    <IonRow>
      {props.options.map(option => (
        <IonCol key={option.key} size={props.colSize ?? '12'}>
          <IonItem
            button
            className={[
              // Required Styling
              Styles.childItem,
              // Toggle the selected style
              selectedValues?.includes(option.value) ? Styles.selected : undefined,
              // Optionally provided class name
              option.itemClassName ? option.itemClassName : undefined,
            ].filter(isSomething).join(' ')}
            lines="none"
            detail={props.showItemDetail}
            disabled={props.disabled}
            onClick={() => toggleSelectedValue(option.value)}
          >
            <div className={Styles.labelContainer}>
              <p>
                {props.render ? props.render(option) : option.label}
                {option.itemSuffix && option.itemSuffix}
              </p>
              {option.itemSubtext && <p>{option.itemSubtext}</p>}
            </div>
          </IonItem>
        </IonCol>
      ))}
    </IonRow>
  </IonGrid>
}

export default GenericSelectorList
