import React, { useCallback, useState } from "react"
import { IonButton, IonIcon, IonImg, IonItem, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { useScreens } from "../../../../../../common/hooks/useScreens"
import { zodResolver } from "@hookform/resolvers/zod"
import { zWorkHistoryReference } from "../workHistoryTypes"
import { useForm } from "react-hook-form"
import { getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import { alwaysArray } from "../../../../../../common/utils"
import { z } from "zod"
import { BudgetRange, TeamType, useCreateContractorProfileWorkHistoryReferenceMutation } from "../../../../../../graphql/generated"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { arrowBackOutline, arrowForwardOutline, personAddOutline } from "ionicons/icons"
import ProjectAvatar from "../../../../../projects/ProjectAvatar"
import FormInputAndErrors from "./FormInputAndErrors"
import MobileNumberCapture from "../../../../../../common/components/MobileNumberCapture"
import { E164Number } from "libphonenumber-js/types"
import searchSvg from "../../../../../../assets/icons/search.svg"
import IndicatorBar from "../../../../../projects/CreateProjectPage/IndicatorBar"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import Styles from "./WorkHistoryReferences.module.scss"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import SingleClickButton from "../../../../../../common/components/SingleClickButton"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import { useShouldAwardContractorProfileBudgetRangeBadge } from "../ContractorProfileBudgetRangeBadge/hooks"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"

enum WorkHistoryReferencesScreens {
  ListReferences = "ListReferences",
  ReferenceNameInstructions = "ReferenceNameInstructions",
  SelectReferenceRole = "SelectReferenceRole",
  EnterArchitectDetails = "EnterArchitectDetails",
  EnterHomeownerDetails = "EnterHomeownerDetails",
  Finished = "Finished",
}

const zWorkHistoryReferenceForm = z.object({ reference: zWorkHistoryReference })
type WorkHistoryReferenceForm = z.infer<typeof zWorkHistoryReferenceForm>

const WorkHistoryReferencesPage: React.FC = () => {
  const history = useHistory()
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const triggerEventWorkHistoryReferencesAddedPastProjectReference = useAnalyticsEvent('WorkHistory_References_Added_Reference')

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  const { reset, register, trigger, getValues, setValue, formState: { errors } } = useForm<WorkHistoryReferenceForm>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(zWorkHistoryReferenceForm),
  })

  const [ Screen, activeScreen ] = useScreens<WorkHistoryReferencesScreens>({
    init: () => {
      reset()

      const references = alwaysArray(workHistory?.references)
      return references.length === 0
        ? WorkHistoryReferencesScreens.ReferenceNameInstructions
        : WorkHistoryReferencesScreens.ListReferences
    },
    resetWhenValueChanges: history.location.search,
    screens: {

      ListReferences: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)
          const onClickFinish = useChangeScreen(WorkHistoryReferencesScreens.Finished)
          const references = alwaysArray(workHistory?.references)

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className={`${Styles.addReferenceContainer} ion-padding`} fullscreen>
              <h3 className="ion-padding-start"><b>References</b></h3>
              {references.map(reference =>
                <IonItem
                  key={reference.id}
                  button
                  detail={false}
                  className={Styles.referenceDetailItem}>
                  <ProjectAvatar title={reference.givenName} slot={"start"} />
                  <div className={Styles.labelContainer}>
                    <p key={reference.id}>{reference.givenName} {reference.familyName}</p>
                    <p>{reference.teamType}</p>
                  </div>
                </IonItem>,
              )}
              <IonItem
                button
                detail={false}
                className={Styles.addReferenceButton}
                onClick={onClickNext}>
                <IonIcon icon={personAddOutline} />
                <p>Add {references.length ? 'another ' : 'a '}reference name for this project</p>
              </IonItem>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange({ budgetRange })}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                {references.length > 0 && <IonButton slot="end" onClick={onClickFinish}>Finish<IonIcon icon={arrowForwardOutline} /></IonButton>}
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ workHistory, budgetRange ]),
      },

      ReferenceNameInstructions: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const goToListReferences = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)

          const references = alwaysArray(workHistory?.references)
          const onClickBack = references.length === 0
            ? goToUnlockContractorBudgetRange({ budgetRange })
            : goToListReferences

          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent fullscreen className={Styles.referenceInputCoverPage}>
              <TitleWithDescriptionPage
                topDivContents={<IonImg src={searchSvg} className={Styles.customIonImg} />}
                title='Input a reference name'
                titleClassName='ion-text-center'
                description={`Obtaining references helps build trust with the project owners and makes your profile stand out from the rest.
  Note, you will only be able to accept leads once this step is marked as complete.`}
              />
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      SelectReferenceRole: {
        render: useCallback(({ useChangeScreen, previousScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickArchitect = useChangeScreen(WorkHistoryReferencesScreens.EnterArchitectDetails)
          const onClickHomeowner = useChangeScreen(WorkHistoryReferencesScreens.EnterHomeownerDetails)

          const resetFormThen = (then: () => unknown) => () => {
            reset()
            then()
          }

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className={`${Styles.selectReferenceRoleContainer} ion-padding`} fullscreen>
              <div className={Styles.indicatorContainer}>
                <IndicatorBar currentPageIndex={0} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              </div>
              <h3 className="ion-padding-start">Select the reference role for this past project</h3>
              <div className={Styles.referenceButtonsContainer}>
                <IonButton onClick={resetFormThen(onClickArchitect)}><span>Architect</span></IonButton>
                <IonButton onClick={resetFormThen(onClickHomeowner)}><span>Homeowner</span></IonButton>
              </div>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar} >
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      EnterArchitectDetails: {
        render: useCallback(({ useChangeScreen }) => {
          const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
          const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)
          const [ isSubmitting, setSubmitting ] = useState(false)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const references = alwaysArray(workHistory?.references)

          const handleChange = (value: E164Number | undefined) => {
            const result = value !== undefined ? `${value}` : ""
            setValue('reference.phoneNumber', result)
          }

          const onClickNextIfFieldIsValid = async () => {

            const isValid = await trigger('reference')

            if (isValid) {
              setSubmitting(true)
              await createMutation.mutateAsync({
                input: {
                  id: workHistoryId,
                  reference: {
                    teamType: getValues('reference.teamType'),
                    companyName: getValues('reference.companyName'),
                    givenName: getValues('reference.givenName'),
                    familyName: getValues('reference.familyName'),
                    email: getValues('reference.email'),
                    phoneNumber: getValues('reference.phoneNumber'),
                  },
                },
              })

              await triggerEventWorkHistoryReferencesAddedPastProjectReference({
                type: 'Architect',
                totalNumberOfReferences: references.length,
                referenceType: getValues('reference.teamType'),
                referenceId: workHistoryId,
                referenceEmail: getValues('reference.email'),
                referencePhone: getValues('reference.phoneNumber'),
                referenceGivenName: getValues('reference.givenName'),
                referenceFamilyName: getValues('reference.familyName'),
                referenceCompanyName: getValues('reference.companyName'),
              })

              await invalidateMyContractorProfile()

              onClickNext()
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
              <div className={Styles.indicatorContainer}>
                <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              </div>
              <h3>Enter Architect&apos;s Details</h3>
              <FormInputAndErrors
                label={"Architect Company Name*"}
                name={"reference.companyName"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.companyName} />
              <FormInputAndErrors
                label={"First name*"}
                name={"reference.givenName"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.givenName} />
              <FormInputAndErrors
                label={"Last name*"}
                name={"reference.familyName"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.familyName} />
              <FormInputAndErrors
                label={"Email*"}
                name={"reference.email"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.email} type={"email"} />
              <MobileNumberCapture
                customClassName={Styles.customMobileInput}
                textHeading="Phone number*"
                textFieldPhoneNumberTitle=""
                value={getValues('reference.phoneNumber')}
                onChange={handleChange} error={errors.reference && errors.reference.phoneNumber}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.phoneNumber?.message} />
              <input value={TeamType.Architect} {...register("reference.teamType")} type="hidden" />
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack} disabled={isSubmitting}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <SingleClickButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ workHistory, trigger, errors ]),
      },

      EnterHomeownerDetails: {
        render: useCallback(({ useChangeScreen }) => {
          const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
          const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)
          const [ isSubmitting, setSubmitting ] = useState(false)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const references = alwaysArray(workHistory?.references)

          const handleChange = (value: E164Number | undefined) => {
            const result = value !== undefined ? `${value}` : ""
            setValue('reference.phoneNumber', result)
          }

          const onClickNextIfFieldIsValid = async () => {

            const isValid = await trigger('reference')
            if (isValid) {
              setSubmitting(true)
              await createMutation.mutateAsync({
                input: {
                  id: workHistoryId,
                  reference: {
                    teamType: getValues('reference.teamType'),
                    givenName: getValues('reference.givenName'),
                    familyName: getValues('reference.familyName'),
                    email: getValues('reference.email'),
                    phoneNumber: getValues('reference.phoneNumber'),
                  },
                },
              })

              await triggerEventWorkHistoryReferencesAddedPastProjectReference({
                type: 'Homeowner',
                totalNumberOfReferences: references.length,
                referenceType: getValues('reference.teamType'),
                referenceId: workHistoryId,
                referenceEmail: getValues('reference.email'),
                referencePhone: getValues('reference.phoneNumber'),
                referenceGivenName: getValues('reference.givenName'),
                referenceFamilyName: getValues('reference.familyName'),
                referenceCompanyName: getValues('reference.companyName'),
              })

              await invalidateMyContractorProfile()

              onClickNext()
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
              <div className={Styles.indicatorContainer}>
                <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
              </div>
              <h3>Enter Homeowner Details</h3>
              <input  {...register("reference.teamType")} value={TeamType.Homeowner} type="hidden" />
              <FormInputAndErrors
                label={"First name*"}
                name={"reference.givenName"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.givenName} />
              <FormInputAndErrors
                label={"Last name*"}
                name={"reference.familyName"}
                register={register}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.familyName} />
              <FormInputAndErrors
                label={"Email*"}
                name={"reference.email"}
                register={register}
                type={"email"}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.email} />
              <MobileNumberCapture
                customClassName={Styles.customMobileInput}
                textHeading="Phone number*"
                textFieldPhoneNumberTitle=""
                value={getValues('reference.phoneNumber')}
                onChange={handleChange} error={errors.reference && errors.reference.phoneNumber}
                disabled={isSubmitting}
                errorMessage={errors.reference && errors.reference.phoneNumber?.message} />
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack} disabled={isSubmitting}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <SingleClickButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ workHistory, trigger, errors ]),
      },

      Finished: {
        render: useCallback(() => {
          const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)
          const goToAwardBadge = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge.path)

          const onClickNext =
            shouldAwardContractorProfileBudgetRangeBadge
              ? goToAwardBadge({ budgetRange, workHistoryId })
              : goToUnlockContractorBudgetRange({ budgetRange })

          console.debug("[WorkHistoryReferences.Finished] Render: ", { shouldAwardContractorProfileBudgetRangeBadge })

          return <>
            <WeaverIonContent>
              <BrilliantScreen title='Amazing!!!' description='
            You can speed things along by letting your reference know that Weaver will will be in touch.
            You will get a notification once the details have been verified.
            This process usually takes about a week, depending on how quickly your reference responds to us.'/>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={onClickNext}>Continue</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ budgetRange ]),
      },

    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default WorkHistoryReferencesPage
