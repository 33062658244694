import React, { useState } from "react"
import { downloadUploadedFile, fileIconSelector, openFile } from "../../common/utils/files"
import { cloudDownloadOutline } from "ionicons/icons"
import { DateTime } from 'luxon'
import { formatBytes } from "../../common/utils"
import { IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonSpinner, IonThumbnail, useIonModal } from "@ionic/react"
import { ContentDisposition, SignedUrlStatus, useGetUploadedFileQuery } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"
import LoadingSpinner from "../../common/components/LoadingSpinner"

import DownloadUploadedFileModalStyles from "./DownloadUploadedFileModal/DownloadUploadedFileModal.module.scss"
import Styles from "./ShowProjectPage.module.scss"

import { fullSizeTransformation } from "../../common/utils/imageTransformations"
import { DownloadUploadedFileModal } from "./DownloadUploadedFileModal/DownloadUploadedFileModal"
import { ShowProjectDocument } from "./ShowProjectTypes"

type ProjectDocumentRowProps = {
  document: ShowProjectDocument,
  isDownloadingAll: boolean,
  onDownload: (document: ShowProjectDocument) => void | unknown,
}

const ProjectDocumentRow: React.FC<ProjectDocumentRowProps> = ({ document, isDownloadingAll = false, onDownload }) => {
  const { id: projectDocumentId } = document
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(isDownloadingAll)

  const [ present ] = useIonModal(DownloadUploadedFileModal, {
    uploadedFileId: projectDocumentId,
  })
  const { isLoading, data } = useGetUploadedFileQuery(gqlDataSource, { id: projectDocumentId, config: { disposition: ContentDisposition.Attachment, transformation: fullSizeTransformation  } })

  if (isLoading) return <LoadingSpinner name={"ProjectDocumentRow"} />

  const uploadedFile = data?.getUploadedFile

  if (!uploadedFile) return <></>

  const fileName = uploadedFile.fileName

  if (!fileName) return <></>

  const urlStatus = uploadedFile?.signedUrlForDownload.status

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const openFullSizeImageModal = () => {
    present({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const handleDownload = () => {
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openFullSizeImageModal()
    } else {
      downloadUploadedFile(uploadedFile, onProgressUpdate)
      onDownload(document)
    }
  }

  return (
    <IonCard className={Styles.projectDocumentCard}>
      <IonItem>
        <IonThumbnail onClick={() => openFile(fileName, uploadedFile.fileContentType || "")}>
          <IonImg src={fileIconSelector(fileName)} />
        </IonThumbnail>
        <IonLabel>
          { uploadedFile.uploadedByTeam && <p>Author: {uploadedFile.uploadedByTeam?.name}</p> }
          <h3>{uploadedFile.fileName}</h3>
          { uploadedFile.fileSizeInBytes && <p>{formatBytes(uploadedFile.fileSizeInBytes)}</p>}
        </IonLabel>
        <div className={Styles.iconDateContainer}>
          <IonLabel>
            <p>{DateTime.fromISO(uploadedFile.updatedAt || "").toLocaleString(DateTime.DATE_SHORT)}</p>
          </IonLabel>
          {
            isDownloadingInProgress
              ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
              : <IonButton
                className={Styles.downloadButton}
                fill='clear' size='small'
                onClick={handleDownload}
              >
                <IonIcon icon={cloudDownloadOutline}/>
              </IonButton>
          }
        </div>
      </IonItem>
    </IonCard>
  )
}
export default ProjectDocumentRow

