import React from 'react'
import { v4 as uuid } from 'uuid'
import { IonButton, IonTitle, IonToolbar } from '@ionic/react'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { ChatRoomMessage as TChatRoomMessage } from '../../../api/services/chat/useRealtimeChatMessages'
import { ChatRoom as TChatRoom, useGetChatRoom } from '../../../api/services/chat/useGetChatRoom'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import ChatRoomProvider, { useChatRoom } from '../../chats/chatRoom/ChatRoom/services/ChatRoomProvider'

const PACKAGE = 'TechDemoPage.ChatterPage/ChatRoom'

const ChatRoomLoader: React.FC<{ chatRoomId: string }> = ({ chatRoomId }) => {
  const LOCAL_PACKAGE = `${PACKAGE}.ChatRoom`

  const { chatRoom } = useGetChatRoom({ chatRoomId })
  const { chatRoomMessages, sendChatRoomMessage } = useChatRoom()

  console.debug(`[${LOCAL_PACKAGE}] Render `, { chatRoomId, chatRoom })

  return (
    <ChatRoomProvider chatRoomId={chatRoomId} chatRoomName={chatRoom?.name}>
      <WeaverIonHeader>
        <IonToolbar>
          <IonTitle>Tech Demo: Chatter</IonTitle>
        </IonToolbar>
      </WeaverIonHeader>
      <WeaverIonContent>
        <div style={{ marginLeft: '1rem' }}>
          {chatRoom === undefined || chatRoomMessages === undefined
            ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useChatRoomWithMessages`} />
            : chatRoom === null
              ? <p>There is an error loading the chatroom!</p>
              : <ChatRoom chatRoom={chatRoom} chatRoomMessages={chatRoomMessages} />
          }
          <IonButton style={{ marginTop: '2em' }} onClick={() => sendChatRoomMessage({
            chatRoomId,
            idFromClient: uuid(),
            content: "A Tech Demo Message",
            sentAt: new Date().toISOString(),
          }, [])}>Send Message</IonButton>
        </div>
      </WeaverIonContent>
    </ChatRoomProvider>
  )
}

const ChatRoom: React.FC<{ chatRoom: TChatRoom, chatRoomMessages: TChatRoomMessage[] }> = ({ chatRoom, chatRoomMessages }) => {
  return <>
    <h2>Chat Room: {chatRoom.name}</h2>
    {chatRoomMessages.map(message => <ChatRoomMessage key={message.idFromClient} message={message} />)}
  </>
}

const ChatRoomMessage: React.FC<{ message: TChatRoomMessage }> = ({ message }) => {
  return <>
    <h3>Message</h3>
    <div>{message.content}</div>
  </>
}

export default ChatRoomLoader
