import React, { ComponentProps } from 'react'
import ErrorBlock from './ErrorBlock'
import WeaverIonPage from '../WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../WeaverIonWrappers/WeaverIonContent'

const ErrorBlockPage: React.FC<ComponentProps<typeof ErrorBlock>> = (props) =>
  <WeaverIonPage>
    <WeaverIonContent>
      <ErrorBlock {...props} />
    </WeaverIonContent>
  </WeaverIonPage>

export default ErrorBlockPage
