import React, { useState } from 'react'
import { IonButton, IonIcon, IonItem, IonRadio, IonRadioGroup, IonToolbar } from "@ionic/react"
import RangeBadge from "../../profile/ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge"
import Styles from "./SelectMembershipPlan.module.scss"
import { arrowForwardOutline } from 'ionicons/icons'
import { BudgetRange } from '../../../graphql/generated'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { FeeOption } from './ContractorLeadAcceptorPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'

type SelectMembershipPlanProps = {
  budgetRange: BudgetRange,
  budgetRangeLabel: string,
  budgetRangeMonthlySubscriptionFee?: string | null,
  budgetRangeLeadFee?: string | null,
  handleNextClick: (selected:FeeOption | undefined) => void,
}

const SelectMembershipPlan: React.FC<SelectMembershipPlanProps> = ({ budgetRange, budgetRangeMonthlySubscriptionFee, budgetRangeLeadFee, handleNextClick }) => {
  const [ selected, setSelected ] = useState(undefined)
  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Select membership plan' />
    </WeaverIonHeader>
    <WeaverIonContent>
      <div className={`${Styles.topBackground} ion-text-center`}>
        <RangeBadge budgetRange={budgetRange} />
        <h5 className={Styles.headerSubtitle}>{budgetRangeLabels[budgetRange]} leads</h5>
      </div>
      <div className={`${Styles.selectMembershipBody} ion-padding`}>
        <h3 className={`ion-margin-bottom ${Styles.titleClassName}`}><b>Select your membership plan</b></h3>

        <IonRadioGroup onIonChange={(e) => setSelected(e.detail.value)}>
          <IonItem lines='none'>
            <div>
              <h5>{budgetRangeMonthlySubscriptionFee} per month</h5>
              <p>Subscribe to get unlimited free leads in {budgetRangeLabels[budgetRange]} range</p>
            </div>
            <IonRadio slot="start" color={'success'} value={FeeOption.Subscription} defaultChecked={true} ></IonRadio>
          </IonItem>

          <IonItem lines='none'>
            <div>
              <h5>Pay per lead</h5>
              <p>Pay {budgetRangeLeadFee} per lead</p>
            </div>
            <IonRadio slot="start" color={'success'} value={FeeOption.PerLead}></IonRadio>
          </IonItem>
        </IonRadioGroup>
      </div>
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton slot="end" onClick={() => handleNextClick(selected)} disabled={false}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

export default SelectMembershipPlan
