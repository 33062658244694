import React from 'react'
import { IonButton, IonText, RouteAction, useIonRouter } from '@ionic/react'
import checkMarkCircleSharp from "./checkmark-circle-sharp.svg"
import Styles from "./SuccessPage.module.scss"
import WeaverIonPage from '../WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../WeaverIonWrappers/WeaverIonContent'

type SuccessPageProps = {
  routerLink?: string,
  header?: string,
  text?: string,
  buttonText?: string,
  routerAction?: RouteAction,
}

const SuccessPage: React.FC<SuccessPageProps> = ({ routerLink, header, text, buttonText, routerAction }) => {
  const ionRouter = useIonRouter()

  /**
   * When the continue button is clicked take the user to the specified router link or to the root route.
   */
  const onContinueClicked = () => {
    if (routerLink){
      ionRouter.push(routerLink, 'forward', routerAction ? routerAction : 'push')
    } else {
      ionRouter.push('/')
    }
  }

  return (
    <WeaverIonPage >
      <WeaverIonContent>
        <div className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
          <img src={checkMarkCircleSharp} className={Styles.checkMarkCircleSharpImage} alt="Green Tick" />
          {header && <h3 className={Styles.uploadYourDocumentsText}>{header}</h3>}
          {text && <IonText className={Styles.supportedFormatsText}>{text}</IonText>}
          { (routerLink && buttonText) &&
            <IonButton className={Styles.continueToRouterLinkButton} onClick={() => onContinueClicked()}>
              {buttonText}
            </IonButton>
          }
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}
export default SuccessPage
