import { IonAlert, IonButton, IonInput, IonItem, IonText, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetContractorProfileQuery, useUpdateContractorProfileMutation } from '../../../graphql/generated'
import Styles from '../EditProfile.module.scss'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import EditProfileHeader from '../EditProfileHeader'
import { useQueryClient } from 'react-query'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

type EditContractorProfileInputs = {
  companyTradingAs: string,
}

export const EditContractorProfileSchema = z.object({
  companyTradingAs: z.string().nonempty('Required'),
})

type EditContractorProfilePageProps = {
  id: string,
}

const EditContractorProfilePage: React.FC<EditContractorProfilePageProps> = ({ id }) => {
  const queryClient = useQueryClient()
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: id }, { staleTime: 5 * 60 * 1000 })
  const updateContractorProfile = useUpdateContractorProfileMutation(gqlDataSource)
  const router = useIonRouter()

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<EditContractorProfileInputs>({ resolver: zodResolver(EditContractorProfileSchema), reValidateMode: 'onChange' })

  if (isFetching) {
    return <LoadingSpinnerPage name="EditContractorProfile" />
  }

  if (error) {
    console.error(`[EditContractorProfile] Failed to load Trading As: `, { data, error })
    return <ErrorBlockPage onRefresh={refetch} />
  }

  if (data?.getContractorProfile?.companyTradingAs == null) {
    console.warn(`[EditContractorProfile] Trading As is missing: `, { data, error })
    return <ErrorBlockPage><p>Unable to find the Contract Profile details.</p></ErrorBlockPage>

  }

  const { companyTradingAs } = data.getContractorProfile
  setValue('companyTradingAs', companyTradingAs)

  const goBack = () => router.goBack()

  const onSubmit = async (data: EditContractorProfileInputs) => {
    await updateContractorProfile.mutateAsync({ input: { id, companyTradingAs: data.companyTradingAs } }, {
      onSuccess: () => {
        queryClient.invalidateQueries('getContractorProfile')
        goBack()
      },
      onError: () => {
        setShowUnsuccessfulAlert(true)
      },
    })
  }

  return <WeaverIonPage>
    <WeaverIonHeader>
      <EditProfileHeader title='Trading Name' />
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonText>
        <h5> Change your Trading Name</h5>
      </IonText>
      <IonText className={Styles.lightGrey}>
        <p> This is the name clients will see on your profile </p>
      </IonText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem className={Styles.ionItemInput} lines="none">
          <IonInput type='text' {...register("companyTradingAs", { required: true })} />
        </IonItem>

        <IonItem lines='none' className={`${Styles.ionItem} ion-no-padding`}>
          {errors.companyTradingAs && <IonText color='danger'>
            <p>This field is required</p>
          </IonText>
          }
        </IonItem>

        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <IonButton type='submit' className={`${Styles.confirmButton} ion-no-padding`}>CONFIRM</IonButton>
        </IonItem>

        <IonAlert
          isOpen={showUnsuccessfulAlert}
          onDidDismiss={() => setShowUnsuccessfulAlert(false)}
          header={'Oops! Something has gone wrong.'}
          buttons={[ 'Dismiss' ]}
        />
      </form>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default EditContractorProfilePage
