import React from "react"
import { IonInput, InputChangeEventDetail } from "@ionic/react"
import { Currency, Money } from "../../../graphql/generated"
import { asMoney, moneyAmountAsNumber, moneyToText } from "../../utils/currency"

const limitKeyPressesToPattern = (pattern: RegExp): React.KeyboardEventHandler<HTMLIonInputElement> => event => {
  // If invalid character, prevent input
  if (!pattern.test(event.key)) event.preventDefault()
}

type WeaverMoneyIonInputProps = {
  value: Money | undefined | null,
  setValue: (value?: Money | undefined | null) => Promise<void> | void,
  minValue?: Money,
  maxValue?: Money,
  withPennies: boolean,
} & Omit<React.ComponentProps<typeof IonInput>, 'type' | 'value' | 'placeholder' | 'min' | 'max' | 'onIonChange' | 'onKeyPress'>

const WeaverMoneyIonInput: React.FC<WeaverMoneyIonInputProps> = ({ value, setValue, minValue, maxValue, withPennies, ...ionInputProps }) => {
  const valueInPence = value == null ? value : moneyAmountAsNumber(value)
  const valueInPounds = valueInPence == null ? valueInPence : valueInPence / 100

  const minValueInPounds = minValue == null ? undefined : (moneyAmountAsNumber(minValue) ?? 0) / 100    // NOTE: `minValue` should never be undefined/null because of the guard
  const maxValueInPounds = maxValue == null ? undefined : (moneyAmountAsNumber(maxValue) ?? Number.MAX_VALUE) / 100    // NOTE: `maxValue` should never be undefined/null because of the guard

  const handleOnIonChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const updatedAmountInPounds = Number(e.detail.value)
    const updatedAmountInPence = updatedAmountInPounds * 100
    const updatedValue = asMoney(value?.currency ?? Currency.Gbp, updatedAmountInPence)
    setValue(updatedValue)
  }

  return (
    <IonInput
      {...ionInputProps}
      type="number"
      value={valueInPounds}
      min={minValueInPounds}
      max={maxValueInPounds}
      placeholder={moneyToText(value, { withPennies }) ?? undefined}
      onIonChange={handleOnIonChange}
      onKeyPress={limitKeyPressesToPattern(/[0-9]/)}
    />
  )
}

export default WeaverMoneyIonInput
