import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WorkHistoryForReferencePage from './WorkHistoryForReferencePage'
import { matchPath, useRouteMatch } from 'react-router'
import { getKnownRoutePathForPage, Page } from '../../../routes'
import { useLookupContractorProfileQuery } from '../../../graphql/generated'

type WorkHistoryForReferenceRouteParams = {referenceId: string}

const WorkHistoryForReference: React.FC = () => {

  const routeMatch = useRouteMatch<WorkHistoryForReferenceRouteParams>()
  const knownPath = getKnownRoutePathForPage(Page.WorkHistoryForReference)
  const internalRouteMatch = matchPath<WorkHistoryForReferenceRouteParams>(routeMatch.url, {
    path: getKnownRoutePathForPage(Page.WorkHistoryForReference),
    exact: true,
    strict: false,
  })

  if (!internalRouteMatch) throw new Error(`[WorkHistoryForReference] internal route match failed, route provided: ${JSON.stringify(routeMatch)}, attempted path: ${knownPath}, `)
  if (!routeMatch.params.referenceId) {
    console.warn("[WorkHistoryForReference] required route param was missing. route match:", JSON.stringify(routeMatch), "internally parsed match:", JSON.stringify(internalRouteMatch))
  }

  const { referenceId } = internalRouteMatch.params

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const { data, isLoading, error, refetch } = useLookupContractorProfileQuery(gqlDataSource, { input:{ workHistoryReferenceId: referenceId } })

  const lookupContractorProfile = data?.lookupContractorProfile

  if (isLoading) {
    return <LoadingSpinnerPage name="WorkHistoryForReferencePage" />
  }

  if (error || lookupContractorProfile?.length !== 1) {
    console.error(`[WorkHistoryForReferencePage] Failed to load Contractor WorkHistory item: `, { data, isLoading, error, referenceId })
    return <ErrorBlockPage onRefresh={refetch} />
  }
  const workHistoryId = lookupContractorProfile[0].workHistoryId
  const contractorProfileId = lookupContractorProfile[0].contractorProfileId

  console.debug(`[WorkHistoryForReferencePage] Render: `, { referenceId, workHistoryId, data, contractorProfileId })

  return <WorkHistoryForReferencePage
    contractorProfileId={contractorProfileId}
    workHistoryId={workHistoryId}
  />
}

export default WorkHistoryForReference
