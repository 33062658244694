import React from 'react'
import { IonButton } from '@ionic/react'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { pageConfig_TechDemo_Chatter, pageConfig_TechDemo_ForgetMeNote, useRouteTo } from '../../routes'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'

const TechDemoPage: React.FC = () => {
  const routeToForgetMeNote = useRouteTo(pageConfig_TechDemo_ForgetMeNote.path)
  const routeToChatter = useRouteTo(pageConfig_TechDemo_Chatter.path)

  return (
    <WeaverIonPage>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <h1>Tech Demos</h1>
        <h2>Demos</h2>
        <div>
          <IonButton onClick={routeToForgetMeNote({})}>Forget Me Note</IonButton>
          <IonButton onClick={routeToChatter({})}>Chatter</IonButton>
        </div>
        <h2>Dev Tools</h2>
        <div>
          Nothing here yet.
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default TechDemoPage
