import React from "react"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"
import GlobalHeader from "./GlobalHeader/GlobalHeader"
import WeaverIonPage from "./WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "./WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonContent from "./WeaverIonWrappers/WeaverIonContent"

/**
 * Add a generic "this is clearly broken"/404 page For development purposes.
 * This should be useful as a placeholder for deeplink functionality
 */
export const NotImplementedPage: React.FC = () => (
  <WeaverIonPage>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Help'/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Not Implemented</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div>Not Found</div>
          <IonButton expand='full' routerLink='/'>Back</IonButton>
        </IonCardContent>
      </IonCard>
    </WeaverIonContent>
  </WeaverIonPage>
)

export default NotImplementedPage
