import React from 'react'
import { GetContractorProfileQuery, useGetContractorProfileQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import ScreenHome from './ScreenHome'
import ScreenWorkHistory from './ScreenWorkHistory'
import { ScreenRenderProps, useScreensWithProps } from '../../../common/hooks/useScreens'
import MyContractorProfileScreenState from './MyContractorProfileScreenState'

/**
 * This component finds my team and loads the GetContractorProfileQuery data.
 * Once the data is ready, it loads the screens component.
 * @returns `<WeaverIonPage>` with the selected screen component.
 */
const MyContractorProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isLoading, refetch } = useGetContractorProfileQuery(
    gqlDataSource,
    { contractorTeamId: myTeam?.id ?? '' },
    {
      staleTime: 5 * 60 * 1000,
      enabled: myTeam !== undefined,
    },
  )

  console.debug(`[MyContractorProfilePage] Render: `, { myTeam, data })

  if (myTeam === undefined || isLoading) {
    return <LoadingSpinnerPage name="MyContractorProfilePage" />
  }

  if (error) {
    console.error(`[MyContractorProfilePage] Failed to load Contractor Profile: `, { myTeam, data, error })
    return <ErrorBlockPage onRefresh={refetch} />
  }

  if (data?.getContractorProfile == null) {
    console.warn(`[MyContractorProfilePage] Contractor Profile is missing: `, { myTeam, data, error })
    return <ErrorBlockPage><p>Unable to find the Contractor Profile.</p></ErrorBlockPage>
  }

  return <MyContractorProfileScreens {...{ profile: data.getContractorProfile, myTeam }} />
}

export enum MyContractorScreenNames {
  Home = "Home",
  WorkHistory = "WorkHistory",
}

export type MyContractorProfileScreenProps = {
  profile: NonNullable<GetContractorProfileQuery['getContractorProfile']>,
}

export type MyContractorProfileScreenWithRenderProps = MyContractorProfileScreenProps & ScreenRenderProps<MyContractorScreenNames>

/**
 * This component manages the screens within the profile.
 * @param screenProps Props that are passed to each individual screen component.
 * @returns `<WeaverIonPage>` with the selected screen component.
 */
const MyContractorProfileScreens: React.FC<MyContractorProfileScreenProps> = (screenProps) => {
  const [ Screen, activeScreen ] = useScreensWithProps<MyContractorScreenNames, MyContractorProfileScreenProps>({
    init: MyContractorScreenNames.Home,
    screenProps,
    screens: {
      Home: { render: ScreenHome },
      WorkHistory: { render: ScreenWorkHistory },
    },
  })

  console.debug('[MyContractorProfileScreens] Display Screen: ', { screenProps, activeScreen })

  return (
    <MyContractorProfileScreenState>
      <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
        {Screen}
      </WeaverIonPage>
    </MyContractorProfileScreenState>
  )
}

export default MyContractorProfilePage
