import React, { useContext } from "react"
import { useChatRoomMembersPresence } from "../../../../../api/services/chat/useChatRoomMembersPresence"
import { TMutationSendChatRoomMessageFn, useRealtimeChatMessages } from "../../../../../api/services/chat/useRealtimeChatMessages"
import useMessageImagesCache from "./useMessageImagesCache"
import useNewMessageDocumentFiles from "./useNewMessageFiles"

export type ChatRoom = {
  chatRoomId: string,
  chatRoomName: string | null | undefined ,
  chatRoomMessages: ReturnType<typeof useRealtimeChatMessages>['chatRoomMessages'],
  sendChatRoomMessage: TMutationSendChatRoomMessageFn,
  newMessageFiles: File[],
  addFileToNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['addFileToNewMessage'],
  addFilesToNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['addFilesToNewMessage'],
  removeFileFromNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['removeFileFromNewMessage'],
  clearFilesFromNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['clearFilesFromNewMessage'],
  readFromCache: ReturnType<typeof useMessageImagesCache>['readFromCache'],
  saveToCache: ReturnType<typeof useMessageImagesCache>['saveToCache'],
  emptyCache: ReturnType<typeof useMessageImagesCache>['emptyCache'],
  enterChatRoom: ReturnType<typeof useChatRoomMembersPresence>['enterChatRoom'],
  leaveChatRoom: ReturnType<typeof useChatRoomMembersPresence>['leaveChatRoom'],
  getMembersPresentInChatRoom: ReturnType<typeof useChatRoomMembersPresence>['getMembersPresentInChatRoom'],
}

export const ChatRoomContext = React.createContext<ChatRoom | undefined>(undefined)

export const useChatRoom = () => {
  const chatRoom = useContext(ChatRoomContext)

  if (chatRoom === undefined) {
    const errorMessage = '[ChatRoomProvider.useChatRoom] ChatRoom is not yet available'
    console.error(errorMessage, { chatRoom })
    throw new Error(errorMessage)
  }
  return chatRoom
}

const ChatRoomProvider: React.FC<React.ReactNode & { chatRoomId: string, chatRoomName: string | null | undefined  }> = ({ chatRoomId, chatRoomName, children }) => {
  const {
    chatRoomMessages,
    sendChatRoomMessage,
  } = useRealtimeChatMessages({ chatRoomId })

  const {
    enterChatRoom,
    leaveChatRoom,
    getMembersPresentInChatRoom,
  } = useChatRoomMembersPresence({ chatRoomId })

  const {
    newMessageFiles,
    addFileToNewMessage,
    addFilesToNewMessage,
    removeFileFromNewMessage,
    clearFilesFromNewMessage,
  } = useNewMessageDocumentFiles()

  const {
    readFromCache,
    saveToCache,
    emptyCache,
  } = useMessageImagesCache()

  const chatRoom = {
    /* Chat Messages */
    chatRoomId,
    chatRoomName,
    chatRoomMessages,
    sendChatRoomMessage,
    /*  Chat Members presence */
    enterChatRoom,
    leaveChatRoom,
    getMembersPresentInChatRoom,
    /* Chat Message Files */
    newMessageFiles,
    addFileToNewMessage,
    addFilesToNewMessage,
    removeFileFromNewMessage,
    clearFilesFromNewMessage,
    /* Chat Message Images Cache */
    readFromCache,
    saveToCache,
    emptyCache,
  }

  return (
    <ChatRoomContext.Provider value={chatRoom}>
      {children}
    </ChatRoomContext.Provider>
  )
}

export default ChatRoomProvider
