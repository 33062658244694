import React, { useState } from 'react'
import { IonGrid, IonRow, IonCol, IonIcon, IonSpinner, IonButton, useIonModal } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import Styles from "./Chat.module.scss"
import { formatBytes } from '../../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { downloadUploadedFile, openFile } from '../../../../common/utils/files'
import { ContentDisposition, SignedUrlStatus, useGetUploadedFileQuery } from '../../../../graphql/generated'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useAutoRetryGetUploadedFileQuery } from '../../../../common/hooks/useAutoRetryGetUploadedFileQuery'
import { DownloadUploadedFileModal } from '../../../projects/DownloadUploadedFileModal/DownloadUploadedFileModal'
import DownloadUploadedFileModalStyles from "../../../projects/DownloadUploadedFileModal/DownloadUploadedFileModal.module.scss"

const ChatMessageDocumentItem: React.FC<TChatRoomMessageDocument> = ({ id, fileName, fileSizeInBytes, fileContentType }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const query = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment } })
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)

  const { isLoading, data } = query

  const uploadedFile = data?.getUploadedFile

  useAutoRetryGetUploadedFileQuery(query)

  const [ present ] = useIonModal(DownloadUploadedFileModal, {
    uploadedFileId: id,
  })

  const openFullSizeImageModal = () => {
    present({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const handleDownload = async () => {
    if (!uploadedFile)
      return

    const urlStatus = uploadedFile?.signedUrlForDownload.status
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openFullSizeImageModal()
    } else {
      await downloadUploadedFile(uploadedFile, onProgressUpdate)
    }
  }

  return (
    <div className={Styles.chatDocumentItem}>
      <IonGrid>
        <IonRow>
          <IonCol size="9"  onClick={() => {openFile(fileName, fileContentType)}}>
            <div className={Styles.chatMessageDocumentName}>{fileName}</div>
            {
              fileSizeInBytes &&
              <div className={Styles.chatMessageDocumentSize}><p>{formatBytes(fileSizeInBytes)}</p></div>
            }
          </IonCol>
          <IonCol size="2">
            <div>
              {
                isDownloadingInProgress || isLoading
                  ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
                  : <IonButton fill='clear' size='small' onClick={() => handleDownload()}><IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} /></IonButton>
              }
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default ChatMessageDocumentItem
