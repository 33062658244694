import { IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import ProjectAvatar from './ProjectAvatar'
import Styles from "./ShowProjectPage.module.scss"
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ShowProjectInvite } from './ShowProjectTypes'

type ProjectInviteRowProps = {
  invite: ShowProjectInvite,
}

const getProfileLink = (inviteTeam: NonNullable<ShowProjectInvite["team"]>, myTeam: NonNullable<ReturnType<typeof useMyIndividualActiveTeam>>) => myTeam.id !== inviteTeam.id ? `/profile/${inviteTeam.type}/${inviteTeam.id}` : `/profile`

const ProjectInviteRow: React.FC<ProjectInviteRowProps> = ({ invite }) => {
  const myTeam = useMyIndividualActiveTeam()
  if (!myTeam) throw new Error("Can't render this component without an active team")

  const companyName = invite.team?.name || invite.companyName || "Unknown Team"
  const optionalLinkProp = invite.team ? { routerLink: getProfileLink(invite.team, myTeam) } : {}
  return (
    <IonItem className={Styles.projectMemberItem} {...optionalLinkProp}>
      <ProjectAvatar slot="start" title={companyName} />
      <IonLabel>
        <h2>{companyName}</h2>
        <h3>Invited: {invite.requiredTeamType}</h3>
      </IonLabel>
    </IonItem>
  )
}

export default ProjectInviteRow
