import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useGraphQLDataSource } from '../../../../../../api/graphql'
import ThumbnailPhotoErrored from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoErrored'
import ThumbnailPhotoInProgress from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoInProgress'
import ThumbnailPhotoUnstarted from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoUnstarted'
import { UploadedFile, UploadedFileStatus, useCreateUploadedFileMutation, useUpdateUploadedFileMutation } from '../../../../../../graphql/generated'

type UploadedFileEventInfo = {
  sizeOfUpload: number,
  timeOfUpload: number | undefined,
  fileType: string,
  fileName: string,
}

type UploadFileProps = {
  fileToUpload: File,
  onUploadComplete: (uploadFileId: string) => void,
  trackEvent: (eventInfo: UploadedFileEventInfo) => void,
}

export enum InternalStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export const UploadFile: React.FC<UploadFileProps> = ({  fileToUpload, onUploadComplete, trackEvent }) => {
  const [ uploadedFile, setUploadedFile ] = useState<Pick<UploadedFile, 'id' | 'signedUrlForUpload' | 'status'> | undefined>()
  const [ internalStatus, setInternalStatus ] = useState<InternalStatus>(InternalStatus.NotStarted)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createUploadFileMutation = useCreateUploadedFileMutation(gqlDataSource)
  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDataSource)

  const updateUploadFileStatus = (uploadFileId: string, status: UploadedFileStatus) =>
    updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadFileId,
        status,
      },
    })

  const uploadFile = async () => {
    if (!uploadedFile) return

    await updateUploadFileStatus(uploadedFile.id, UploadedFileStatus.Uploading)
    setInternalStatus(InternalStatus.InProgress)

    const uploadUrl = uploadedFile.signedUrlForUpload.url

    if (!uploadUrl){
      updateUploadFileStatus(uploadedFile.id, UploadedFileStatus.Failed)
      setInternalStatus(InternalStatus.Failed)
      return
    }
    const startOfUpload = DateTime.now()
    await fetch(uploadUrl, { method: "PUT", body: fileToUpload })
      .then(result => {
        const finishedUpload = DateTime.now()
        trackEvent({
          sizeOfUpload: fileToUpload.size,
          timeOfUpload: finishedUpload.diff(startOfUpload, [ "milliseconds" ]).toObject().milliseconds,
          fileType: fileToUpload.type,
          fileName: fileToUpload.name ?? 'Unknown',
        })

        const status = result.status === 200 ? UploadedFileStatus.Completed : UploadedFileStatus.Failed
        updateUploadFileStatus(uploadedFile.id, status)
        setInternalStatus(result.status === 200 ? InternalStatus.Completed : InternalStatus.Failed)

      }).catch(error => {
        updateUploadFileStatus(uploadedFile.id, UploadedFileStatus.Failed)
        setInternalStatus(InternalStatus.Failed)
        throw error
      })
    onUploadComplete(uploadedFile.id)
  }

  useEffect( () => {
    if (uploadedFile?.status === UploadedFileStatus.NotStarted){
      uploadFile()
    }

  }, [ uploadedFile ])

  useEffect(() => {
    const createUploadFile = async () => {
      setInternalStatus(InternalStatus.InProgress)
      const result = await createUploadFileMutation.mutateAsync({
        input: {
          fileName: fileToUpload.name,
          fileContentType: fileToUpload.type,
          fileSizeInBytes: fileToUpload.size,
        },
      })
      setUploadedFile(result.createUploadedFile)
      updateUploadFileStatus(result.createUploadedFile.id, UploadedFileStatus.NotStarted)
    }
    if (!uploadedFile){
      createUploadFile()
    }
  }, [])

  if (internalStatus === InternalStatus.InProgress){
    return <ThumbnailPhotoInProgress />
  }

  if (internalStatus === InternalStatus.Failed){
    return <ThumbnailPhotoErrored onRetryClicked={uploadFile}/>
  }

  if (internalStatus === InternalStatus.Completed){
    return null
  }

  return <ThumbnailPhotoUnstarted />

}
