import React, { useEffect, useState } from 'react'
import { useGraphQLDataSource } from '../../../../../../api/graphql'
import { ContentDisposition, SignedUrlStatus, UploadedFileStatus, useGetUploadedFileQuery, useUpdateUploadedFileMutation } from '../../../../../../graphql/generated'
import ThumbnailPhotoErrored from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoErrored'
import ThumbnailPhotoInProgress from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoInProgress'
import ThumbnailPhotoSucceded from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoSucceded'
import ThumbnailPhotoUnstarted from '../../../../../../common/components/ThumbnailPhoto/ThumbnailPhotoUnstarted'
import { Browser } from '@capacitor/browser'
import { IonButton, IonIcon, useIonModal } from '@ionic/react'
import FullSizePhotoModalStyles from "./FullSizePhotoModal.module.scss"
import { imageOutline, refreshOutline } from 'ionicons/icons'
import { IonSpinner } from '@ionic/react'
import { fullSizeTransformation, thumbnailTransformation } from '../../../../../../common/utils/imageTransformations'
import { useAutoRetryGetUploadedFileQuery } from '../../../../../../common/hooks/useAutoRetryGetUploadedFileQuery'
import { useAnalyticsEvent } from '../../../../../../api/providers/SegmentProvider/hooks'

type DisplayUploadedFileAsPhotoProps = {
  uploadedFileId: string,
  shouldDisplayDeleteButton?: boolean,
  onFileArchived?: () => void,
}

export enum InternalStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

const TryAgainButton: React.FC<{refetch: () => void}> = ({ refetch }) => (<p><IonButton onClick={() => refetch()} size="small" color="danger"><IonIcon icon={refreshOutline} /> Try Again</IonButton></p>)

const FullSizePhotoModal: React.FC<{uploadedFileId: string}> = ({ uploadedFileId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const query = useGetUploadedFileQuery(gqlDataSource, { id: uploadedFileId, config: { transformation: fullSizeTransformation, disposition:  ContentDisposition.Attachment } }, { staleTime: 15 * 60 * 1000 })
  const maxAttemptsReached = useAutoRetryGetUploadedFileQuery(query)

  const { refetch } = query
  const url = query.data?.getUploadedFile.signedUrlForDownload.url

  if (query.isLoading || !url ){
    return <div className={FullSizePhotoModalStyles.container}>
      <IonSpinner /> <span className={FullSizePhotoModalStyles.statusDescription}>Processing the file.</span>
    </div>
  }

  if (maxAttemptsReached){
    return <TryAgainButton refetch={refetch} />
  }

  return (
    <div className={FullSizePhotoModalStyles.container} onClick={() => { Browser.open({ url: url }) }} >
      <IonIcon icon={imageOutline} className={FullSizePhotoModalStyles.downloadIcon} />
      <span className={FullSizePhotoModalStyles.statusDescription}>The file is ready.</span>
    </div>
  )
}

export const DisplayUploadedFileAsPhoto: React.FC<DisplayUploadedFileAsPhotoProps> = ({ uploadedFileId, shouldDisplayDeleteButton, onFileArchived }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const fetchUploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id: uploadedFileId, config: { transformation: thumbnailTransformation } }, { staleTime: 15 * 60 * 1000 })
  const maxAttemptsReached = useAutoRetryGetUploadedFileQuery(fetchUploadedFileQuery)
  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDataSource)

  const triggerEventWorkHistoryReferencesArchivedPhoto = useAnalyticsEvent('WorkHistory_References_Archived_Photo')

  const [ internalStatus, setInternalStatus ] = useState<InternalStatus>(InternalStatus.NotStarted)
  const [ fileUrl, setFileUrl ] = useState<string>("")

  const uploadedFile = fetchUploadedFileQuery.data?.getUploadedFile

  const downloadPicture = async () => {
    if (!uploadedFile) return

    setInternalStatus(InternalStatus.InProgress)
    try {
      const downloadUrl = uploadedFile.signedUrlForDownload

      if (maxAttemptsReached){
        setInternalStatus(InternalStatus.Failed)
      }

      if (downloadUrl.status === SignedUrlStatus.FileNotFound || downloadUrl.status === SignedUrlStatus.ConfigError){
        setInternalStatus(InternalStatus.Failed)
        return
      }

      if (!downloadUrl.url) return

      setInternalStatus(InternalStatus.Completed)
      setFileUrl(downloadUrl.url)
    } catch (error){
      setInternalStatus(InternalStatus.Failed)
      console.log(error)
    }
  }

  const archiveUploadedFile = async () => {
    if (!uploadedFile) return
    await updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadedFile?.id,
        status: UploadedFileStatus.Archived,
      },
    },
    )
    const fileName = uploadedFile.fileName ?? 'Non-Passed'
    await triggerEventWorkHistoryReferencesArchivedPhoto({
      fileName: fileName,
    })
    if (onFileArchived) onFileArchived()
  }

  const [ present ] = useIonModal(FullSizePhotoModal, {
    uploadedFileId,
  })

  const openFullSizeImageModal = () => {
    present({ cssClass: FullSizePhotoModalStyles.fullSizeImageModal })
  }

  useEffect(() => {
    downloadPicture()
  }, [ downloadPicture, maxAttemptsReached, uploadedFile, setInternalStatus, setFileUrl ])

  const retryDownloadFile = () => downloadPicture()

  if (fetchUploadedFileQuery.isLoading){
    return <ThumbnailPhotoInProgress />
  }

  if (internalStatus === InternalStatus.InProgress){
    return <ThumbnailPhotoInProgress />
  }

  if (internalStatus === InternalStatus.Failed){
    return <ThumbnailPhotoErrored onRetryClicked={ () => retryDownloadFile()}/>
  }

  if (internalStatus === InternalStatus.Completed){
    return  <ThumbnailPhotoSucceded photoUrl={fileUrl} onThumbnailClicked={openFullSizeImageModal} onCloseClicked={shouldDisplayDeleteButton ? () =>  archiveUploadedFile() : undefined}/>
  }

  return <ThumbnailPhotoUnstarted />
}
