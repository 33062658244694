import React from 'react'
import PublicProfileHeader from '../PublicProfileHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import PublicWeaverProfile from './PublicWeaverProfile'
import { TeamPublicProfilePageComponentProps } from '../DisplayPublicProfile'

const PublicWeaverProfilePage: React.FC<TeamPublicProfilePageComponentProps> = ({ teamId, ...ionPageProps }) =>
  <WeaverIonPage {...ionPageProps}>
    <WeaverIonHeader>
      <PublicProfileHeader />
    </WeaverIonHeader>
    <WeaverIonContent>
      <PublicWeaverProfile id={teamId} />
    </WeaverIonContent>
  </WeaverIonPage>

export default PublicWeaverProfilePage
