import React from 'react'
import chatDefaultAvatar from './chat-default-avatar.png'
import Styles from "./MyChats.module.scss"
import { ensureTrailingSlash } from '../../../common/utils'
import { useRouteMatch } from 'react-router'
import { IonItem, useIonRouter } from '@ionic/react'
import { ChatRoom, ChatRoomMessage } from '../../../graphql/generated'
import { formatDateAsDuration } from '../../../common/utils/date'
import { useLastMessage } from './useLastMessage'

const Avatar: React.FC = () => {
  return <img className={Styles.chatRowAvatar} src={chatDefaultAvatar} alt="chatAvatar" />
}

export type ChatRoomMessageProps = {
  content: ChatRoomMessage['content'],
  sentAt: ChatRoomMessage['sentAt'],
}

const LastMessagePreview: React.FC<ChatRoomMessageProps> = ({ content }) => {
  return <div className={Styles.chatLastMessagePreview}>{content}</div>
}

const LastMessageSendDate: React.FC<ChatRoomMessageProps> = ({ sentAt }) => {
  const formattedDate = formatDateAsDuration(sentAt)
  return <div className={Styles.chatDate}>{formattedDate} </div>
}

type ChatRoomNameProps = {
  name: ChatRoom['name'],
}

const ChatRoomName: React.FC<ChatRoomNameProps> = ({ name }) => {
  return (
    <div className={Styles.chatName}>
      {name}
    </div>
  )
}

type MyChatsRowProps = {
  id: ChatRoom['id'],
  messages: ChatRoomMessageProps[],
}

export const MyChatsRow: React.FC<MyChatsRowProps & ChatRoomNameProps> = ({ id, name, messages }) => {
  const match = useRouteMatch()
  const ionRouter = useIonRouter()
  const lastMessage = useLastMessage(messages)

  const goToChat = (event: React.MouseEvent) => {
    event.preventDefault()
    const newLocation = `${ensureTrailingSlash(match.url)}${id}`
    ionRouter.push(newLocation)
  }

  return (
    <div className={Styles.chatRow}>
      <Avatar />
      <IonItem className={Styles.chatContentContainer} lines="none" onClick={goToChat}>
        <div className={Styles.chatContent}>
          <div className={Styles.chatTopContent}>
            <ChatRoomName name={name} />
            {lastMessage && <LastMessageSendDate {...lastMessage} />}
          </div>
          {lastMessage && <LastMessagePreview {...lastMessage} />}
        </div>
      </IonItem>
    </div>
  )
}
