import React, { useCallback } from "react"
import { IonButton, IonIcon, IonText, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges, useRouteTo } from "../../../../../routes"
import ThisBudgetRangeIsLockedScreen from "./ScreenThisBudgetRangeIsLocked"
import { useScreens } from "../../../../../common/hooks/useScreens"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import RangeBadge from "../../ContractorBudgetReferences/RangeBadge"
import { budgetRangeLabels } from "../budgetRanges.i18n"
import { getFirstWorkHistoryByBudgetRange, useMyContractorProfile } from "../datasource"
import ScreenWorkHistoryMenu from "./ScreenWorkHistoryMenu"
import { BudgetRange } from "../../../../../graphql/generated"
import IndicatorBar from "../../../../projects/CreateProjectPage/IndicatorBar"
import { alwaysArray } from "../../../../../common/utils"
import { WorkHistoryMenuItem } from "./ScreenWorkHistoryMenu/config"
import Styles from "./UnlockContractorBudgetRange.module.scss"
import { useAnalyticsEvent } from "../../../../../api/providers/SegmentProvider/hooks"
import { useParamsFromPageConfig } from "../../../../../routesProvider"
import WeaverIonContent from "../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonPage from "../../../../../common/components/WeaverIonWrappers/WeaverIonPage"

enum UnlockScreens {
  ThisBudgetRangeIsLocked = "ThisBudgetRangeIsLocked",
  UnlockMenu = "UnlockMenu",
}

const UnlockContractorBudgetRangePage: React.FC = () => {
  const { budgetRange } = useParamsFromPageConfig<{ budgetRange: BudgetRange }>()

  const goToContractorBudgetRanges = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path)

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getFirstWorkHistoryByBudgetRange(myContractorProfile, budgetRange)
  const isNewWorkHistory = workHistory === undefined

  const [ Screen, activeScreen ] = useScreens<UnlockScreens>({
    isWaiting: myContractorProfile.getContractorProfileQuery.isLoading  || myContractorProfile.getContractorProfileQuery.isRefetching,
    init: () => isNewWorkHistory ? UnlockScreens.ThisBudgetRangeIsLocked : UnlockScreens.UnlockMenu,
    resetWhenValueChanges: budgetRange,
    screens: {

      ThisBudgetRangeIsLocked: {
        render: useCallback(({ useChangeScreen }) => {
          const triggerEventWorkHistoryReferencesAddedBudgetPreferences = useAnalyticsEvent('WorkHistory_References_Added_Budget_Preferences')
          const changeScreenToUnlockMenu = useChangeScreen(UnlockScreens.UnlockMenu)
          const triggerEventThenChangeScreen = async () => {
            triggerEventWorkHistoryReferencesAddedBudgetPreferences({ budgetRange })
            changeScreenToUnlockMenu()
          }

          return <>
            <WeaverIonContent className="ion-padding">
              <ThisBudgetRangeIsLockedScreen budgetRange={budgetRange} />
              <br />
              <br />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToContractorBudgetRanges({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={triggerEventThenChangeScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ budgetRange ]),
      },

      UnlockMenu: {
        render: useCallback(() => {
          const hasWorkHistory = workHistory !== undefined

          const doesWorkHistoryHaveAnyReferences = alwaysArray(workHistory?.references).length > 0
          const doesWorkHistoryHaveAnyPhotos = alwaysArray(workHistory?.photos).length > 0
          const currentProgressIndex = !hasWorkHistory ? 0 : [ hasWorkHistory, doesWorkHistoryHaveAnyReferences, doesWorkHistoryHaveAnyPhotos ].filter(Boolean).length
          const totalPages = Object.keys(WorkHistoryMenuItem).length

          return <>
            <WeaverIonContent>
              <div className={`${Styles.pastProjectHeaderContainer} ion-text-center}`}>
                { budgetRange && <RangeBadge budgetRange={budgetRange} />}
                <h5>Unlock {budgetRangeLabels[budgetRange]} leads</h5>
              </div>
              <div className={Styles.indicatorContainer}>
                <IndicatorBar currentPageIndex={currentProgressIndex} totalPages={totalPages} maxAvailableIndex={totalPages} onNavigate={() => null} />
              </div>
              <div className="ion-padding">
                <IonText>To unlock {budgetRangeLabels[budgetRange]} leads, you need to have all the tasks marked as done</IonText>
                <ScreenWorkHistoryMenu budgetRange={budgetRange} workHistory={workHistory} />
              </div>
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToContractorBudgetRanges({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ budgetRange, workHistory ]),
      },
    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen ?? <WeaverIonContent />}
    </WeaverIonPage>
  )
}

export default UnlockContractorBudgetRangePage
