import React, { useCallback, useState } from "react"
import { IonButton, IonIcon, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { budgetRangeLabels } from "../../budgetRanges.i18n"
import { useScreens } from "../../../../../../common/hooks/useScreens"
import { Controller, useForm } from "react-hook-form"
import { WorkHistory, zWorkHistory } from "../workHistoryTypes"
import { BudgetRange, Currency, useCreateContractorProfileWorkHistoryMutation } from "../../../../../../graphql/generated"
import { zodResolver } from "@hookform/resolvers/zod"
import PickAddress from "../../../../../../common/components/PickAddress"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { useInvalidateMyContractorProfile } from "../../datasource"
import ProjectTypeSelector from "./ProjectTypeSelector/ProjectTypeSelector"
import SumOfWork from "./SumOfWorkPage/SumOfWork"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import RangeBadge from "../../../ContractorBudgetReferences/RangeBadge"
import { budgetRangesConfigs } from "../../budgetRanges"
import { z } from "zod"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import SingleClickButton from "../../../../../../common/components/SingleClickButton"
import { moneyAmountAsNumberOnlyLogError, numberToStringWithThousandCommas } from "../../../../../../common/utils/currency"
import Styles from './NewWorkHistory.module.scss'
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"

enum NewWorkHistoryScreens {
  Splash = "Splash",
  ProjectAddress = "ProjectAddress",
  ProjectSumOfWork = "ProjectSumOfWork",
  ProjectType = "ProjectType",
  Finished = "Finished",
}

const NewWorkHistoryPage: React.FC = () => {
  const history = useHistory()
  const { budgetRange } = useParamsFromPageConfig<{ budgetRange: BudgetRange }>()

  const minBudgetRange = Number(budgetRangesConfigs[ budgetRange ].fromInclusive / 100)
  const maxBudgetRange = Number(budgetRangesConfigs[ budgetRange ].toExclusive / 100)

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  const zRefinedWorkHistory = zWorkHistory.superRefine((validated, ctx) => {
    const amountInPence = moneyAmountAsNumberOnlyLogError(validated.constructionValue)
    if (amountInPence == null) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be a currency number!`,
      })
      return
    }
    if (amountInPence < budgetRangesConfigs[ budgetRange ].fromInclusive) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be greater than the minimum of £${numberToStringWithThousandCommas(minBudgetRange)}`,
      })
    }
    if (amountInPence >= budgetRangesConfigs[ budgetRange ].toExclusive) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be less than the maximum of £${numberToStringWithThousandCommas(maxBudgetRange)}`,
      })
    }
  })

  const form = useForm<WorkHistory>({
    defaultValues: {
      projectAddress: undefined,
      constructionValue: {
        currency: Currency.Gbp,
      },
      projectTypes: [],
      references: [],
      photos: [],
    },
    mode: "onChange",
    resolver: zodResolver(zRefinedWorkHistory),
  })

  const [ Screen, activeScreen ] = useScreens<NewWorkHistoryScreens>({
    init: () => {
      form.reset()
      return NewWorkHistoryScreens.Splash
    },
    resetWhenValueChanges: history.location.search,
    screens: {

      Splash: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <TitleWithDescriptionPage
                topDivContents={<RangeBadge budgetRange={budgetRange} />}
                title={`Add a past project in ${budgetRangeLabels[ budgetRange ]} budget range to unlock leads.`}
                description={`It doesn't have to be a Weaver project, but does need to be in the selected budget range.`}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange({ budgetRange })}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ budgetRange ]),
      },

      ProjectAddress: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)
          const triggerEventReferencesAddedPastProjectAddress = useAnalyticsEvent("WorkHistory_References_Added_Past_Project_Address")

          const onClickNextIfFieldIsValid = async () => {
            const isValid = await form.trigger('projectAddress')
            if (isValid) {
              await triggerEventReferencesAddedPastProjectAddress()
              onClickNext()
            }
          }

          return (
            <>
              <WeaverIonHeader>
                <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
              </WeaverIonHeader>
              <WeaverIonContent className="ion-padding" fullscreen>
                <Controller
                  control={form.control}
                  name="projectAddress"
                  render={({
                    field: { onChange, value },
                    fieldState: { error : fieldStateEmptyError },
                    formState: { errors },
                  }) => ( <> <h3><b>Enter address of a past project</b></h3>
                    {fieldStateEmptyError ? <p className={Styles.errorMessage}>{fieldStateEmptyError.message}</p> : null}
                    <PickAddress title='' value={value} setValue={onChange} hasError={errors.projectAddress} /></> )}/>
              </WeaverIonContent>
              <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
                <IonToolbar className={Styles.ionToolbar}>
                  <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                  <IonButton slot="end" onClick={onClickNextIfFieldIsValid}
                    // disabled={!!fieldStateEmptyError}
                  >
                    Next<IonIcon icon={arrowForwardOutline} /></IonButton>
                </IonToolbar>
              </WeaverIonFooter>
            </>)

        }, [ form ]),
      },

      ProjectSumOfWork: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const triggerEventReferencesAddedSumOfWork = useAnalyticsEvent("WorkHistory_References_Added_Sum_Of_Work")

          const onClickNextIfFieldIsValid = async () => {
            const isValid = await form.trigger('constructionValue')
            await triggerEventReferencesAddedSumOfWork({
              currency: form.getValues('constructionValue').currency ,
              amountInPence: form.getValues('constructionValue').amountInPence,
            })
            if (isValid) onClickNext()
          }

          return <>
            {/* Button to set the sum field in the work history */}
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className="ion-padding">
              <Controller
                control={form.control}
                name="constructionValue"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (  <SumOfWork
                  indicatorBarProps={{
                    currentPageIndex: 1,
                    totalPages: 3,
                    maxAvailableIndex: 3,
                    onNavigate: () => null,
                  }}
                  value={value}
                  setValue={onChange}
                  error={error}
                  budgetRange={budgetRange}
                /> )}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNextIfFieldIsValid}
                //  disabled={!!error}
                >Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>

        }, [ form ]),
      },

      ProjectType: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)
          const [ isSubmitting, setSubmitting ] = useState(false)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const createMutation = useCreateContractorProfileWorkHistoryMutation(gqlDataSource)
          const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

          const triggerEventReferencesAddedPastProjectTypes = useAnalyticsEvent("WorkHistory_References_Added_Past_Project_Types")

          const onSubmitThenClickNextIfFieldIsValid = async () => {
            const isProjectTypeValid = await form.trigger('projectTypes')
            if (isProjectTypeValid) {
              setSubmitting(true)

              if (!form.trigger()) {
                console.error(`[NewWorkHistory] Form not valid: `, form)
                return
              }

              console.log('[NewWorkHistory] Adding the Work History: ', form.getValues())

              await createMutation.mutateAsync({
                input: {
                  projectAddress: form.getValues('projectAddress'),
                  constructionValue: form.getValues('constructionValue'),
                  projectTypes: form.getValues('projectTypes'),
                },
              })

              await triggerEventReferencesAddedPastProjectTypes({
                types: form.getValues('projectTypes') ,
              })

              await invalidateMyContractorProfile()
              onClickNext()
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className="ion-padding" fullscreen>
              <Controller
                control={form.control}
                name="projectTypes"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (  <ProjectTypeSelector
                  disabled={isSubmitting}
                  indicatorBarProps={{
                    currentPageIndex: 2,
                    totalPages: 3,
                    maxAvailableIndex: 3,
                    onNavigate: () => null,
                  }} value={value} setValue={onChange} hasError={!!error}
                />)}
              />

            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <SingleClickButton slot="end" onClick={onSubmitThenClickNextIfFieldIsValid}
                // disabled={!!error}
                >Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>

        }, [ form ]),
      },

      Finished: {
        render: useCallback(() => {
          return <>
            <WeaverIonContent>
              <BrilliantScreen title='Amazing!!!' description='You have added a reference project'/>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={goToUnlockContractorBudgetRange({ budgetRange })}>Continue</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ budgetRange ]),
      },
    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default NewWorkHistoryPage
