import React from 'react'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'
import { pageConfig_ShowLead, pageConfig_ShowProject, useRouteTo } from '../../../routes'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

const DisplayContractorLeadAcceptorStripeCallbackPage: React.FC = () => {
  const { leadId, result } = useParamsFromPageConfig<{ leadId: string, result: string }>()

  const goToShowLead = useRouteTo(pageConfig_ShowLead.path)
  const goToShowProject = useRouteTo(pageConfig_ShowProject.path)

  if (result === 'success') {
    return (
      <WeaverIonPage>
        <WeaverIonContent>
          <BrilliantScreen
            title='Amazing!'
            subtitle='You have accepted a lead'
            actionButtonProps={{
              // NOTE: Leads are virtual representations of Projects and share the same ID
              onClick: goToShowProject({ id: leadId }),
              children: <>Continue</>,
              expand: 'block',
            }}
          />
        </WeaverIonContent>
      </WeaverIonPage>
    )
  }

  if (result === 'cancel') {
    return (
      <ErrorBlockPage onRefresh={goToShowLead({ id: leadId })}>
        <p>Transaction Cancelled</p>
      </ErrorBlockPage>
    )
  }

  throw new Error(`Unknown Stripe Result state: '${result}' for Lead ID: ${leadId}`)
}

export default DisplayContractorLeadAcceptorStripeCallbackPage
