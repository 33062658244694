import React, { ComponentProps } from "react"
import { IonPage } from "@ionic/react"
import { CheckableComponents, useComponentCounter } from "./WeaverStructureChecker"
import { useWeaverIonThingsCorrectly } from "./hooks"

type WeaverIonPageProps = {
  /**
   * WeaverIonPage validates the structure of the children it's passed prior to rendering.
   *
   * ```tsx
   * const ChecksAreValidPage: React.FC = () =>
   *   <WeaverIonPage>
   *     <WeaverIonContent>Something.</WeaverIonContent>
   *   </WeaverIonPage>
   * ```
   *
   * Sometimes however, the child structure checks here are not appropriate,
   * like when there are intermediary components or logic, such as `useScreens` or a `React.Context.Provider` in the way.
   * When this happens, you will need to disable the checks and validate another way.
   *
   * ```tsx
   * const MustDisableChecksPage: React.FC = () => {
   *   enum Pages { PageA = "PageA" }
   *
   *   const [ Screen, activeScreen ] = useScreens<Pages>({
   *     init: Pages.PageA,
   *     screens: {
   *       PageA: {
   *         render: () => <WeaverIonContent>Something.</WeaverIonContent>
   *       },
   *     },
   *   })
   *
   *   return (
   *     <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
   *       {Screen}
   *     </WeaverIonPage>
   *   )
   * }
   * ```
   *
   *  _See Also: `WeaverIonPage.test.tsx`_
   *
   * **NOTE:** This does not disable the checks done by `WeaverStructureChecker` which are designed to always hold true.
   */
  disableDirectChildStructureChecks?: boolean,
} & ComponentProps<typeof IonPage>

const WeaverIonPage: React.FC<WeaverIonPageProps> = ({ disableDirectChildStructureChecks = false, ...ionPageProps }) => {
  useWeaverIonThingsCorrectly('WeaverIonPage', disableDirectChildStructureChecks, ionPageProps.children)
  useComponentCounter(CheckableComponents.weaverIonPage)
  return <IonPage {...ionPageProps} />
}

export default WeaverIonPage
