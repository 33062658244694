import React from 'react'
import { IonActionSheet } from "@ionic/react"
import { close, document, camera, imagesOutline } from 'ionicons/icons'
import { isMobile } from '../../../../../common/utils/ionic'

type NewChatDocumentActionSheetProps = {
  isOpen: boolean,
  onDismiss: () => void,
  openFilePickerHandler: () => void,
  openCameraHandler: () => void,
  openPhotoGalleryHandler: () => void,
}

export const  NewChatDocumentActionSheet: React.FC<NewChatDocumentActionSheetProps> = ({ isOpen, onDismiss, openFilePickerHandler, openCameraHandler, openPhotoGalleryHandler }) => {
  const documentOption = {
    text: 'Document',
    icon: document,
    handler: openFilePickerHandler,
  }

  const cameraOption =   {
    text: 'Camera',
    icon: camera,
    handler: openCameraHandler,
  }

  const galleryOption =   {
    text: 'Gallery',
    icon: imagesOutline,
    handler: openPhotoGalleryHandler,
  }

  const cancelOption = {
    text: 'Cancel',
    icon: close,
    role: 'cancel',
  }

  return (
    <IonActionSheet
      isOpen={isOpen}
      onDidDismiss={onDismiss}
      buttons={
        isMobile ?
          [
            documentOption,
            galleryOption,
            cameraOption,
            cancelOption,
          ]:
          [
            documentOption,
            cancelOption,
          ]
      }
    >
    </IonActionSheet>
  )
}
