import { useRef, useEffect, useState } from "react"
import { UseQueryResult } from "react-query"
import { GetUploadedFileQuery, SignedUrlStatus } from "../../graphql/generated"
import useCallFunctionWithDelayedTrigger from "./useCallFunctionWithDelayedTrigger"

const MAX_REFETCH_ATTEMPTS = 10
const ATTEMPT_DURATION_IN_SECONDS = 1

export const useAutoRetryGetUploadedFileQuery = (query: UseQueryResult<GetUploadedFileQuery, unknown>) => {
  const uploadedFile = query.data?.getUploadedFile
  const urlStatus = uploadedFile?.signedUrlForDownload.status
  const { refetch } = query

  const delayedTrigger = useCallFunctionWithDelayedTrigger()

  const attempts = useRef(0)
  const [ maxAttemptsReached, setMaxAttemptsReached ] = useState(false)

  useEffect(() => {
    if (!uploadedFile) return

    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      attempts.current = attempts.current + 1
      setMaxAttemptsReached( attempts.current >= MAX_REFETCH_ATTEMPTS)
      delayedTrigger({ seconds: ATTEMPT_DURATION_IN_SECONDS * attempts.current }, refetch)

      return
    }

  }, [ uploadedFile , refetch, delayedTrigger, setMaxAttemptsReached ])

  return maxAttemptsReached
}
