import React, { ComponentProps } from 'react'
import Autocomplete from "react-google-autocomplete"
import { IonGrid, IonRow, IonItem, IonInput, IonItemDivider } from '@ionic/react'
import { useEnvironmentContext } from '../../api/providers/EnvironmentProvider'
import { WeaverAddressInput } from '../../graphql/generated'
import { convertGoogleMapsPlaceResultToWeaverAddressInput } from '../../common/utils/googleMaps'
import { objectKeys } from '../utils/objects'
import { FieldErrors } from 'react-hook-form'
import Styles from "./PickAddress.module.scss"

export type WeaverAddressMap = Record<keyof WeaverAddressInput, string>;

export type PickAddressProps = {
  title: string,
  value?: WeaverAddressInput,
  setValue: (value?: WeaverAddressInput) => Promise<void> | void,
  hasError?: FieldErrors<WeaverAddressMap>,
  disabled?: boolean,
  hideFields?: (keyof WeaverAddressInput)[],
  hideAutocomplete?: boolean,
}

/**
 * A fully controlled address picker component, which binds to the address passed in.
 */
export const PickAddress: React.FC<PickAddressProps> = ({ title, setValue, disabled, value, hideFields, hideAutocomplete, hasError }) => {
  const environment = useEnvironmentContext()

  const weaverAddressInputTitles: WeaverAddressMap = {
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    postTown: "Post Town",
    country: "Country",
    county: "County",
    postCode: "Postcode*",
    careOf: "Care of",
    poBox: "PO Box",
  }

  const selectPlace = (place: google.maps.places.PlaceResult) => {
    try {
      const placeAddress = convertGoogleMapsPlaceResultToWeaverAddressInput(place)
      console.debug('[PickAddress.selectPlace] Setting the place to: ', { placeAddress, place })
      setValue(placeAddress)
    } catch (err) {
      console.error('[PickAddress.selectPlace] Place cannot be used due to error.', { place, err })
    }
  }

  const onChangeField = (fieldName: keyof NonNullable<typeof value>): ComponentProps<typeof IonInput>['onIonChange'] => event => {
    const newFieldValue = event.detail.value

    const updatedAddress: WeaverAddressInput = {
      ...(value ?? { postCode: '' }),
      [fieldName]: newFieldValue,
    }

    setValue(updatedAddress)
  }

  const shownFieldNames = objectKeys(weaverAddressInputTitles).filter((fieldName) => !hideFields?.includes(fieldName))

  return (
    <IonGrid className={Styles.outerGrid}>
      <IonItem>
        <IonGrid className={Styles.contentContainer}>
          <h3>{title}</h3>
          { hideAutocomplete ? <></> : (
            <>
              <h6>Quick Search by:</h6>
              <IonRow className={Styles.displayRows}>
                <IonItemDivider>
                  <Autocomplete
                    disabled={disabled}
                    apiKey={environment.integrations.google.apiKey}
                    className={Styles.addressAutoComplete}
                    placeholder='Address'
                    onPlaceSelected={selectPlace}
                    options={{
                      types: [ "address" ],
                      componentRestrictions: { country: "gb" },
                    }}
                  />
                </IonItemDivider>
              </IonRow>
            </>
          )}
          {shownFieldNames.map((fieldName) => (
            <IonRow key={fieldName} className={Styles.displayRows}>
              <p className={Styles.inputTitle}>{weaverAddressInputTitles[fieldName]}</p>
              <IonItemDivider className={hasError && hasError[fieldName] !== undefined ? Styles.errorBottomDivider : ''}>
                <IonInput disabled={disabled} value={value ? value[fieldName] : ''} onIonChange={onChangeField(fieldName)} />
                {(hasError && hasError[fieldName] !== undefined) && <p className={Styles.errorText}>{hasError[fieldName]?.message}</p>}
              </IonItemDivider>
            </IonRow>))
          }
        </IonGrid>
      </IonItem>
    </IonGrid>
  )
}

export default PickAddress
