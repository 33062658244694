import React from 'react'
import { IonToolbar, IonButtons, IonIcon, IonTitle, useIonRouter } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import Styles from './EditProfileHeader.module.scss'

type EditProfileHeaderProps = {
  title: string,
}

const EditProfileHeader: React.FC<EditProfileHeaderProps> = ({ title }) => {
  const router = useIonRouter()
  const goBack = () => router.goBack()
  return (
    <IonToolbar className={Styles.ionToolbar}>
      <IonButtons slot="start" className='ion-padding'>
        <IonIcon className={Styles.ionIcon} icon={arrowBack} onClick={goBack}></IonIcon>
      </IonButtons>
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  )
}

export default EditProfileHeader
