import React from 'react'
import { IonIcon, IonItem } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import TeamMembers from '../TeamMembers/TeamMembers'
import ButtonChangeLeadPreferences from '../ContractorLeadPreferences/ButtonChangeLeadPreferences'
import RangeBadges from './RangeBadges'
import ListWorkHistoryItems from './ListWorkHistoryItems'
import { pageConfig_Profile_Edit, useRouteTo } from '../../../routes'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import Styles from './MyContractorProfilePage.module.scss'
import { MyContractorProfileScreenProps, MyContractorScreenNames } from './MyContractorProfilePage'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'

const MyContractorProfileScreenHome: React.FC<MyContractorProfileScreenProps & ScreenRenderProps<MyContractorScreenNames>> = (props) => {
  const routeToEditMyProfile = useRouteTo(pageConfig_Profile_Edit.path)
  const goToEditMyProfile = routeToEditMyProfile({}, undefined, 'forward', 'push')

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='My Profile' />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>
      <section className={Styles.companyInfoContainer}>
        <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={goToEditMyProfile}>
          <h3 className={Styles.companyTradingName}>{props.profile.companyTradingAs}</h3>
          <IonIcon className={Styles.editIcon} color='primary' src={createOutline}></IonIcon>
        </IonItem>
        <p className={Styles.contractorTeamType}>Main Contractor</p>
        <p>{formatAddressToSingleLine(props.profile.companyAddress) ?? <em>No Address</em>}</p>
        <RangeBadges screenProps={props} width="90" height="90" />
      </section>

      <section className={Styles.unlockedBudgetRangesContainer}>
        <ButtonChangeLeadPreferences />
      </section>

      <section>
        <ListWorkHistoryItems {...props} />
      </section>

      <section>
        <TeamMembers teamId={props.profile.id} />
      </section>
    </WeaverIonContent>
  </>
}

export default MyContractorProfileScreenHome
