import React, { useEffect, useRef } from 'react'
import { ChatMessageBubble } from './ChatMessageBubble'
import { ChatMessageWithDocumentsBubble } from './ChatMessageWithDocumentsBubble'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useChatRoom } from './services/ChatRoomProvider'
import { useMyIndividual } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'

const PACKAGE = 'chatContent'

const ChatContent: React.FC = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const { chatRoomMessages, emptyCache } = useChatRoom()
  const individual = useMyIndividual()

  useEffect(() => {
    // The message images cache is clear every time the chat screen is rendered for the first time
    emptyCache()
  }, [])

  useEffect(() => {
    const scrollToLastMessage = () => {
      if (chatRoomMessages !== undefined){
        virtuosoRef.current?.scrollToIndex({ index: chatRoomMessages.length, behavior: 'auto', align: "start" })
      }
    }
    // We need to make sure the last message has rendered on screen before we scroll
    setTimeout(scrollToLastMessage, 1)
  }, [ chatRoomMessages?.length ])

  if (chatRoomMessages === undefined) {
    return <WeaverIonContent>
      <LoadingSpinner name={`${PACKAGE}.undefined`} />
    </WeaverIonContent>
  }

  return (
    <WeaverIonContent useFullHeight={true}>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: "100%" }}
        data={chatRoomMessages}
        itemContent={index => {
          const message = chatRoomMessages[index]
          const wasMessageSentByCurrentUser = message.sentByIndividual.id === individual?.id
          return (
            <>
              {
                message.documentCount
                  ? <ChatMessageWithDocumentsBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} />
                  : <ChatMessageBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} />
              }
            </>
          )
        }}
      >
      </Virtuoso>
    </WeaverIonContent>
  )
}

export default ChatContent
