import React from 'react'
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { helpCircleOutline } from 'ionicons/icons'
import { showIntercom } from '../../common/utils/intercom'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'

const Help: React.FC = () => {
  return (
    <WeaverIonPage>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Help'/>
      </WeaverIonHeader>
      <WeaverIonContent className={'ion-padding ion-padding-end'}>
        <IonText>
          <h4>Help</h4>
        </IonText>
        <IonItem className='ion-no-padding' onClick={showIntercom}>
          <IonIcon icon={helpCircleOutline} className='ion-padding-end'></IonIcon><IonLabel>Message support</IonLabel>
        </IonItem>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default Help
