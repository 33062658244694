import React, { ComponentProps } from 'react'
import { IonButton, IonImg, IonText } from '@ionic/react'
import confettiGif from '../../../assets/images/confetti.gif'
import Styles from "./BrilliantScreen.module.scss"
import SingleClickButton from '../SingleClickButton'

type BrilliantScreenProps = {
  title: string,
  subtitle?: string,
  description?: string,
  actionButtonProps?: ComponentProps<typeof IonButton>,
}

const BrilliantScreen: React.FC<BrilliantScreenProps> = ({ title, subtitle, description, actionButtonProps }) => {

  return (
    <div className={Styles.message}>
      <IonImg src={confettiGif} />
      <div className='ion-text-center'>
        <IonText><h1 className={Styles.caption}>{title}</h1></IonText>
        {subtitle &&<IonText><p>{subtitle}</p></IonText>}
        {description &&<IonText><p className={Styles.messageDescription}>{description}</p></IonText>}
      </div>
      {(actionButtonProps?.onClick || actionButtonProps?.href) && <SingleClickButton {...actionButtonProps} className={`${Styles.button} ${actionButtonProps.className}`} />}
    </div>
  )
}

export default BrilliantScreen
