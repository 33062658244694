import { isSomething } from "."
import { WeaverAddress } from "../../graphql/generated"

export const firstPartOfPostCode = (postCode?: string | null): string | null | undefined  => {
  // Short circuit null / undefined / empty string
  if (!postCode) return postCode

  // If there's a space, return everything before it
  if (postCode.indexOf(' ') > -1) {
    return postCode.split(' ')[0]
  }

  // Default to returning just the first 4 characters
  return postCode.slice(0, 4)
}

export const formatAddressToSingleLine = (address?: WeaverAddress | null): string | null | undefined =>
  address
    ? [
      address?.addressLine1,
      address?.addressLine2,
      address?.postTown,
      address?.county,
      address?.country,
      address?.postCode,
    ]
      .map(each => each ? each : undefined) // Remove blank lines
      .filter(isSomething)
      .join(', ')
    : undefined
