import React, {  useState } from "react"
import { IonButton,  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,  IonChip,  IonCol,  IonContent, IonGrid, IonIcon, IonInput, IonItem,  IonLabel,  IonModal,  IonNote,  IonRow, IonTitle, IonToolbar, isPlatform, useIonAlert, useIonRouter } from "@ionic/react"
import { addOutline, arrowBackOutline, calendarClearOutline, calendarOutline, cashOutline, cloudDownloadOutline, cloudUploadOutline, constructOutline } from "ionicons/icons"
import { TeamType, useShowProjectQuery, ProjectMemberRole, UploadedFileStatus, ContentDisposition, useInviteContractorToMyProjectMutation } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useRouteMatch } from "react-router"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import ProjectDocumentRow from "./ProjectDocumentRow"
import ProjectInviteRow from "./ProjectInviteRow"
import ProjectTaskList from "./checklist"
import Styles from "./ShowProjectPage.module.scss"
import { downloadUploadedFile } from "../../common/utils/files"
import { alwaysArray, getEnumLabel } from "../../common/utils"
import { asyncForEach } from '../../common/utils/index'
import { ProjectMemberRow } from "./ProjectMemberRow"
import { getFlexibleProjectBudgetAsText, useGetInviteUrl } from "./common"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { DateTime } from "luxon"
import { useChatRoomFunctions } from "../../api/services/chat/useChatRoomFunctions"
import { matchPath } from "react-router"
import { getKnownRoutePathForPage, Page, pageConfig_Projects, useRouteTo } from "../../routes"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import { formatAddressToSingleLine } from "../../common/utils/addresses"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import { ProjectMemberTeam, ShowProjectDocument, ShowProjectFiles } from "./ShowProjectTypes"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"

export type DocumentsToDownload = {
  [documentId: string]: boolean,
}
const MODAL_OPEN = 0.92
const NaiveMatchPhoneNumber = /^0 ?\d{4} ?\d{6}$/

export const inviteContractorFormSchema = z.object({
  companyName: z.string().nonempty("Required"),
  givenName: z.string().nonempty("Required"),
  familyName: z.string().nonempty("Required"),
  email: z.string()
    .nonempty("Required")
    .email(),
  phone: z.string()
    .nonempty("Required")
    .refine((x) => x.match(NaiveMatchPhoneNumber), "Must be a valid phone number"),
})

export type InviteContractorForm = z.infer<typeof inviteContractorFormSchema>

type ShowProjectRouteParams = {id: string}

const ShowProject: React.FC = () => {
  const weaverFlags = useWeaverFlags()
  const getInviteUrl = useGetInviteUrl()
  const { createChatRoom } = useChatRoomFunctions()
  const routeMatch = useRouteMatch<ShowProjectRouteParams>()
  // HACK: transitioning to this screen from createProject after initial onboarding seems to cause this component to recieve no route params.
  // HACK: parse the route manually and warn when the params are empty (to aid future debugging)
  // HACK: Track progress on this issue in https://weaver.atlassian.net/browse/MW-1125
  const knownPath = getKnownRoutePathForPage(Page.ShowProject)
  const internalRouteMatch = matchPath<ShowProjectRouteParams>(routeMatch.url, {
    path: getKnownRoutePathForPage(Page.ShowProject),
    exact: true,
    strict: false,
  })

  const goToProjectList = useRouteTo(pageConfig_Projects.path)

  const [ showModal, setShowModal ] = useState(false)
  const [ isInvitingContractor, setIsInvitingContractor ] = useState(false)

  const { register, reset,  handleSubmit, formState: { errors } } = useForm<InviteContractorForm>({ resolver: zodResolver(inviteContractorFormSchema) })

  if (!internalRouteMatch) throw new Error(`[ShowProject] internal route match failed, route provided: ${JSON.stringify(routeMatch)}, attempted path: ${knownPath}, `)
  if (!routeMatch.params.id) {
    console.warn("[ShowProject] required route param was missing. route match:", JSON.stringify(routeMatch), "internally parsed match:", JSON.stringify(internalRouteMatch))
  }

  const { id } = internalRouteMatch.params

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectQuery = useShowProjectQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } } }, { refetchOnWindowFocus: false })
  const inviteContractorToMyProject = useInviteContractorToMyProjectMutation(gqlDataSource)
  const triggerProjectInviteCreated = useAnalyticsEvent("Project_Invite_Created")
  const triggerProjectDocumentDownloaded = useAnalyticsEvent("Project_Document_Downloaded")
  const triggerProjectDocumentDownloadedAll = useAnalyticsEvent("Project_Document_Downloaded_All")

  const myTeam = useMyIndividualActiveTeam()
  const ionRouter = useIonRouter()
  const [ present ] = useIonAlert()
  const isContractor = myTeam?.type === TeamType.Contractor

  const onInviteContractorSubmit = async (contractor: InviteContractorForm) => {
    setIsInvitingContractor(true)
    try {
      const { inviteToMyProject: invite } = await inviteContractorToMyProject.mutateAsync({
        input: {
          projectId: id,
          companyName: contractor.companyName,
          email: contractor.email,
          familyName: contractor.familyName,
          givenName: contractor.givenName,
          phone: contractor.phone,
        },
      })
      await triggerProjectInviteCreated({
        source: "showProject",
        inviteId: invite.id,
        inviteUrl: getInviteUrl(invite.id),
        inviteEmail: contractor.email,
        inviteCompanyName: contractor.companyName,
        inviteFamilyName: contractor.familyName,
        inviteGivenName: contractor.givenName,
        invitePhone: contractor.phone,
        projectId: id,
      })
      reset({
        companyName: "",
        email: "",
        familyName: "",
        givenName: "",
        phone: "",
      })
      setShowModal(false)
      showProjectQuery.refetch()
    } catch (err) {
      let message = "Unknown Error"
      if (err instanceof Error) {
        message = err.message
      }
      present({
        header: "Unable to Invite Contractor",
        message: message,
        buttons: [
          {
            text: "Dismiss",
            role: "cancel",
          },
        ],
      })
    }

    setIsInvitingContractor(false)
  }

  const uploadButtonMessage = "You don't have any uploaded documents, add documents by tapping the upload button."
  const [ documentsToDownload, setDocumentsToDownload ] = useState<DocumentsToDownload>({})

  const downloadAllProjectFiles = (listOfAllDocumentFiles: ShowProjectFiles[]) =>
    asyncForEach(listOfAllDocumentFiles, async (document: ShowProjectFiles) =>
      downloadUploadedFile(document, (hasCompleted: boolean) => {
        setDocumentsToDownload({
          ...documentsToDownload,
          [document.id]: hasCompleted,
        })
      }),
    )

  const navigateToUploadDocumentsPage = () => {
    ionRouter.push(`/projects/${id}/upload-documents/`)
  }

  if (showProjectQuery.isFetching && !showProjectQuery.data) {
    return <LoadingSpinnerPage name="ShowProject"/>
  }

  if (!myTeam) {
    return <LoadingSpinnerPage name="ShowProject" />
  }

  if (showProjectQuery.error || !showProjectQuery.data) {
    return <ErrorBlockPage onRefresh={showProjectQuery.refetch} />
  }

  const canUserUploadFiles = () => myTeam.type !== TeamType.Contractor
  const includeNonArchivedDocuments = (document: ShowProjectFiles) => document.status !== UploadedFileStatus.Archived

  const { getProject: project } = showProjectQuery.data
  const members = project.members
  const invites = project.memberInvites

  const unclaimedInvites = invites.filter(x => !x.team)
  const claimedInvites = invites.filter(x => x.team)
  const allMemberCount = members.length + invites.length

  const contractorSlots = project.memberSlots.filter(x => x.requiredProjectRole === ProjectMemberRole.CandidateProfessional && x.requiredTeamType === TeamType.Contractor)

  const documents = alwaysArray(project.documents).filter(includeNonArchivedDocuments)

  const createChatRoomBetweenTeams = async (myTeam: ProjectMemberTeam, memberTeam: ProjectMemberTeam) => {
    const newChatRoom = await createChatRoom({
      name: `${project.title}: ${myTeam.name} - ${memberTeam.name}`,
      teamIds: [ myTeam.id, memberTeam.id ],
    })
    if (newChatRoom) {
      ionRouter.push(`/chats/${newChatRoom.id}`)
    }
  }

  const handleDownloadDocument = (document: ShowProjectDocument) => {
    const { fileName, fileContentType: fileType, fileSizeInBytes: sizeOfDownload } = document
    const hasRequiredFields = fileName && fileType && sizeOfDownload
    if (!hasRequiredFields) throw new Error(`Failed to report download: Document was missing required fields: ${JSON.stringify(document)}`)

    triggerProjectDocumentDownloaded({
      projectId: project.id,
      fileName,
      fileType,
      sizeOfDownload,
    })
  }

  const onDownloadAllClicked = () => {
    downloadAllProjectFiles(alwaysArray(documents))

    triggerProjectDocumentDownloadedAll({
      projectId: project.id,
      downloads: documents.map((document) => {
        const { fileName, fileContentType: fileType, fileSizeInBytes: sizeOfDownload } = document
        const hasRequiredFields = fileName && fileType && sizeOfDownload
        if (!hasRequiredFields) throw new Error(`Failed to report downloadAll, document was missing required fields: ${JSON.stringify(document)}`)
        return ({
          fileName,
          fileType,
          sizeOfDownload,
        })
      }),
    })
  }

  return (
    <WeaverIonPage disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader
          pageTitle={project.title}
          navElement={
            <IonButton onClick={goToProjectList({})}>
              <IonIcon slot="start" icon={arrowBackOutline} />
              Projects
            </IonButton>
          }
        />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonCard className={Styles.projectCard}>
          <IonCardHeader>
            <IonCardSubtitle>PROJECT</IonCardSubtitle>
            <IonCardTitle>{project.title}</IonCardTitle>
          </IonCardHeader>

          <IonItem>
            <IonIcon icon={constructOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonLabel>Type</IonLabel>
              {project.projectTypes.map(x => <IonChip key={x} >{getEnumLabel(x)}</IonChip>)}
            </IonLabel>
          </IonItem>

          <IonItem>
            <IonIcon icon={cashOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonLabel>Budget</IonLabel>
              {getFlexibleProjectBudgetAsText(project, myTeam)}
            </IonLabel>
          </IonItem>

          <IonItem>
            <IonIcon icon={calendarClearOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonLabel>Work Start Estimate</IonLabel>
              {project.workStartEstimate && getWorkStartEstimateLabels()[project.workStartEstimate]}
            </IonLabel>
          </IonItem>

          <IonItem>
            <IonIcon icon={calendarOutline} slot="start" />
            <IonLabel className="ion-text-wrap">
              <IonLabel>Tender Return Date</IonLabel>
              {project.tenderReturnDate && DateTime.fromISO(project.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
            </IonLabel>
          </IonItem>

          <IonCardContent>
            <h2>Address</h2>
            <p>{formatAddressToSingleLine(project.address)}</p>
            <p>{project.address?.postCode}</p>
          </IonCardContent>

          <IonCardContent>
            <h2>Description</h2>
            {project.description || ""}
          </IonCardContent>

          {weaverFlags.tasks.enabled && weaverFlags.tasks.showProjectList && (
            <IonCardContent>
              <h2>Tasks</h2>
              <ProjectTaskList projectId={id} />
            </IonCardContent>
          )}

          <IonCardContent>
            <IonCardTitle>Members</IonCardTitle>
            <IonCardSubtitle>{contractorSlots.length} Contractor Place{contractorSlots.length !== 1 ? "s": ""} Remaining</IonCardSubtitle>
            { !isContractor && <IonButton onClick={() => setShowModal(true)}><IonIcon icon={addOutline} slot="start"/>Invite My Contractor</IonButton> }
          </IonCardContent>

          { unclaimedInvites.map(unclaimed => <ProjectInviteRow key={unclaimed.id} invite={unclaimed} />) }
          { claimedInvites.map(claimed => <ProjectInviteRow key={claimed.id} invite={claimed} />) }
          { members.map(member => <ProjectMemberRow key={member.id} member={member} createChatRoom={createChatRoomBetweenTeams} />) }
          {
            allMemberCount < 1 ?
              <IonCardContent>This project has no members.</IonCardContent>
              : null
          }

          <IonCardContent>
            <IonCardTitle>Documents</IonCardTitle>
            { canUserUploadFiles() &&
              <IonButton onClick={() => navigateToUploadDocumentsPage()}>
                <IonIcon icon={cloudUploadOutline} slot="start" />
                Upload
              </IonButton>
            }

            {( documents.length > 0 )
              ?<>
                <IonButton onClick={() => onDownloadAllClicked()} hidden={isPlatform('mobileweb')}>
                  <IonIcon icon={cloudDownloadOutline} slot="start"/>
                  Download All
                </IonButton>
                {( documents.map((document) =>
                  <ProjectDocumentRow
                    key={document.id}
                    document={document}
                    isDownloadingAll={documentsToDownload.documentId}
                    onDownload={handleDownloadDocument}
                  />,
                ))}
              </>
              : <IonCardContent>{uploadButtonMessage}</IonCardContent>
            }
          </IonCardContent>
        </IonCard>
      </WeaverIonContent>
      <IonModal
        isOpen={showModal}
        initialBreakpoint={MODAL_OPEN}
        breakpoints={[ 0, MODAL_OPEN ]}
        onDidDismiss={() => setShowModal(false)}>
        <IonToolbar>
          <IonTitle>Invite a Contractor</IonTitle>
        </IonToolbar>

        <IonContent>
          <div className={Styles.modalContainer}>
            <h3>Please enter the details of the contractor you would like to invite.</h3>
            <p>Your contractor will receive an invitation to Weaver.</p>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h3>Company name</h3>
                  <IonInput placeholder="Builders Ltd." {...register("companyName")}></IonInput>
                  {errors.companyName ? <IonNote color='danger'>{errors.companyName.message}</IonNote> : null}
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h3>First name</h3>
                  <IonInput placeholder='James' {...register("givenName")}></IonInput>
                  {errors.givenName ? <IonNote color='danger'>{errors.givenName.message}</IonNote> : null}
                </IonCol>
                <IonCol>
                  <h3>Last name</h3>
                  <IonInput placeholder='Builder' {...register("familyName")}></IonInput>
                  {errors.familyName ? <IonNote color='danger'>{errors.familyName.message}</IonNote> : null}
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <h3>Email</h3>
                  <IonInput placeholder='email@domain.tld' {...register("email")}></IonInput>
                  {errors.email ? <IonNote color='danger'>{errors.email.message}</IonNote> : null}
                </IonCol>
                <IonCol>
                  <h3>Phone</h3>
                  <IonInput placeholder='079468526659' type='number' {...register("phone")} ></IonInput>
                  {errors.phone ? <IonNote color='danger'>{errors.phone.message}</IonNote> : null}
                </IonCol>
              </IonRow>

              <IonButton expand='block' disabled={isInvitingContractor} onClick={handleSubmit(onInviteContractorSubmit)}>Invite Contractor</IonButton>
            </IonGrid>
          </div>
        </IonContent>

      </IonModal>
    </WeaverIonPage>
  )
}

export default ShowProject
