import React, { SVGAttributes } from "react"
import { objectEntries } from "../../../common/utils/objects"
import { ProgressionStatus } from "../../../graphql/generated"
import { convertToLockedBudgetRangeStatuses } from "../ContractorLeadPreferences/ContractorBudgetRanges/datasource"
import RangeBadge from "../ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge"
import { PublicContractorProfileScreenProps } from "./PublicContractorProfile"

type RangeBadgesProps = { screenProps: PublicContractorProfileScreenProps } & SVGAttributes<SVGSVGElement>

const acceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const RangeBadges: React.FC<RangeBadgesProps> = ({ screenProps, ...svnAttrs }) => {
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(screenProps.profile.workHistory)

  return <>
    {objectEntries(lockedBudgetRangeStatuses)
      .filter(([ , status ]) => acceptableStatuses.includes(status))
      .map(([ budgetRange ]) => <RangeBadge key={budgetRange} {...{ budgetRange, ...svnAttrs }} />)
    }
  </>
}

export default RangeBadges
