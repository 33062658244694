import React from "react"
import { IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, useIonRouter } from "@ionic/react"
import { arrowBack } from "ionicons/icons"
import Styles from './ProfileHeader.module.scss'

const ProfileHeader: React.FC<{ isMyTeam?: boolean }> = ({ isMyTeam }) => {
  const router = useIonRouter()
  const goBack = () => router.goBack()
  return isMyTeam
    ? (
      <IonToolbar className={Styles.ionToolbar}>
        <IonButtons slot="start">
          <IonMenuButton autoHide={false}></IonMenuButton>
        </IonButtons>
        <IonTitle>Profile</IonTitle>
      </IonToolbar>
    )
    : (
      <IonToolbar className={Styles.ionToolbar}>
        <IonButtons slot="start" className='ion-padding'>
          <IonIcon className={Styles.ionIcon} icon={arrowBack} onClick={goBack}></IonIcon>
        </IonButtons>
      </IonToolbar>
    )
}

export default ProfileHeader
