import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonIcon, IonItem, IonLabel, IonSpinner, useIonAlert, useIonRouter } from "@ionic/react"
import { calendarClearOutline, calendarOutline, cashOutline, checkmarkCircleOutline, closeOutline, constructOutline } from "ionicons/icons"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useGraphQLDataSource } from "../../api/graphql"
import Styles from "./ShowLeadPage.module.scss"
import { useShowLeadQuery, useRejectLeadMutation } from "../../graphql/generated"
import { Loader } from '@googlemaps/js-api-loader'
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { getEnumLabel } from "../../common/utils"
import { DateTime } from "luxon"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { pageConfig_Projects_ContractorLeadAcceptor, useRouteTo } from "../../routes"
import { useEnvironmentContext } from "../../api/providers/EnvironmentProvider"
import { useParamsFromPageConfig } from "../../routesProvider"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { getFlexibleProjectBudgetAsText } from "./common"

const ShowLead: React.FC = () => {
  const environment = useEnvironmentContext()
  const weaverFlags = useWeaverFlags()

  const myTeam = useMyIndividualActiveTeam()

  const { id } = useParamsFromPageConfig<{id: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ present ] = useIonAlert()
  const router = useIonRouter()

  const showLeadQuery = useShowLeadQuery(gqlDataSource , { id }, { refetchOnWindowFocus: false })
  const routeToContractorLeadAcceptor = useRouteTo(pageConfig_Projects_ContractorLeadAcceptor.path)
  const rejectLeadMutation = useRejectLeadMutation(gqlDataSource)
  const [ rejectLoading, setRejectLoading ] = useState<boolean>(false)
  const [ , setMap ] = useState<google.maps.Map | undefined>(undefined)
  const mapDivRef = useRef<HTMLDivElement| null>(null)

  // useContractorLeadSubscriptions(acceptLeadMutation) which should
  // > check if useWeaverFlags() if Lead Subscriptions are enabled
  // > check if this contractor has an active subscription for this budget range
  // > if not, show the buy lead / subscription flow (otherwise skip)
  // > call the accept lead mutation
  // > success!

  useEffect(() => {
    const init = async () => {
      if ( !window.google?.maps ) {
        await new Loader({ apiKey: environment.integrations.google.apiKey, ...{ libraries: [ 'places' ] } }).load()
      }
      if (!showLeadQuery.data?.getLead.approximateLocation) {
        return
      }
      if (!mapDivRef.current) {
        return
      }

      const [ lat, lng ] = showLeadQuery.data.getLead.approximateLocation.split(" ").map(x => Number(x))
      setMap( new window.google.maps.Map(mapDivRef.current, {
        center: { lat, lng },
        zoom: 10,
        fullscreenControl: false,
        disableDefaultUI: true,
        draggable: false,
        keyboardShortcuts: false,
      }) )
    }

    init()
  }, [ showLeadQuery.data?.getLead?.approximateLocation, mapDivRef.current ])

  const rejectLead = useCallback(async () => {
    setRejectLoading(true)
    try {
      await rejectLeadMutation.mutateAsync({ id })
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Unable to Reject Lead",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setRejectLoading(false)
    router.push("/projects")
  }, [ rejectLeadMutation, id, setRejectLoading, router ])

  if (showLeadQuery.isFetching && !showLeadQuery.data) {
    return <LoadingSpinnerPage name="showLeadQuery"/>
  }

  if (showLeadQuery.error || !showLeadQuery.data) {
    return <ErrorBlockPage onRefresh={showLeadQuery.refetch} />
  }

  const { data: { getLead: lead } } = showLeadQuery

  const loading = rejectLoading

  return <WeaverIonPage>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Projects'/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonCard>
        {weaverFlags['project-leads'].showMap && <div className={Styles.map} ref={mapDivRef}></div>}
        <IonCardHeader>
          <IonCardSubtitle>LEAD</IonCardSubtitle>
          <IonCardTitle>{lead.title}</IonCardTitle>
        </IonCardHeader>

        <IonItem>
          <IonIcon icon={constructOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Type</IonLabel>
            {lead.projectTypes.map(x => <IonChip key={x} >{getEnumLabel(x)}</IonChip>)}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={cashOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Budget</IonLabel>
            {getFlexibleProjectBudgetAsText(lead, myTeam)}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={calendarClearOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Work Start Estimate</IonLabel>
            {lead.workStartEstimate && getWorkStartEstimateLabels()[lead.workStartEstimate]}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonIcon icon={calendarOutline} slot="start" />
          <IonLabel className="ion-text-wrap">
            <IonLabel>Tender Return Date</IonLabel>
            {lead.tenderReturnDate && DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
          </IonLabel>
        </IonItem>

        <IonCardContent>
          <h2>Description</h2>
          {lead.description || ""}
        </IonCardContent>

        <div className={Styles.actionButtons}>
          <IonButton disabled={loading} onClick={routeToContractorLeadAcceptor({ leadId: id })} color="success">
            <IonIcon icon={checkmarkCircleOutline} slot="start"/>
            Accept
          </IonButton>
          <IonButton disabled={loading} onClick={rejectLead} color="danger">
            {rejectLoading ? <IonSpinner /> : <><IonIcon icon={closeOutline} slot="start"/>Reject</>}
          </IonButton>
        </div>
      </IonCard>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ShowLead
