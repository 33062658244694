import React, { useState } from "react"
import { IonButton, IonIcon, IonSpinner } from "@ionic/react"
import { useGraphQLDataSource } from "../../../api/graphql"
import { useAutoRetryGetUploadedFileQuery } from "../../../common/hooks/useAutoRetryGetUploadedFileQuery"
import { fullSizeTransformation } from "../../../common/utils/imageTransformations"
import { ContentDisposition, useGetUploadedFileQuery } from "../../../graphql/generated"
import DownloadUploadedFileModalStyles from "./DownloadUploadedFileModal.module.scss"
import { downloadUploadedFile } from "../../../common/utils/files"
import { cloudDownloadOutline, refreshOutline } from "ionicons/icons"

type DownloadUploadedFileModalType = {
  uploadedFileId: string,
}

const TryAgainButton: React.FC<{refetch: () => void}> = ({ refetch }) => (<p><IonButton onClick={() => refetch()} size="small" color="danger"><IonIcon icon={refreshOutline} /> Try Again</IonButton></p>)

export const DownloadUploadedFileModal: React.FC<DownloadUploadedFileModalType> = ({ uploadedFileId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const query = useGetUploadedFileQuery(gqlDataSource, { id: uploadedFileId, config: { transformation: fullSizeTransformation, disposition:  ContentDisposition.Attachment } }, { staleTime: 15 * 60 * 1000 })
  const maxAttemptsReached = useAutoRetryGetUploadedFileQuery(query)
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)

  const { refetch } = query
  const uploadedFile = query.data?.getUploadedFile

  const url = query.data?.getUploadedFile.signedUrlForDownload.url

  if (query.isLoading || !url ){
    return <div className={DownloadUploadedFileModalStyles.container}>
      <IonSpinner /><span className={DownloadUploadedFileModalStyles.statusDescription}>Processing the file.</span>
    </div>
  }

  if (!uploadedFile) return  <TryAgainButton refetch={refetch}/>

  if (maxAttemptsReached){
    return <TryAgainButton refetch={refetch}/>
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  return (
    <div className={DownloadUploadedFileModalStyles.container} >
      <span className={DownloadUploadedFileModalStyles.statusDescription}>The file is ready to download</span>
      {
        isDownloadingInProgress
          ? <IonSpinner className={DownloadUploadedFileModalStyles.downloadSpinner} name="bubbles" />
          : <IonButton fill='clear' size='small'
            className={DownloadUploadedFileModalStyles.downloadButton}
            onClick={() => { downloadUploadedFile(uploadedFile, onProgressUpdate) }}>
            <IonIcon icon={cloudDownloadOutline}/>
          </IonButton>
      }
    </div>
  )
}
