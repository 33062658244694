import React from "react"
import { IonGrid, IonImg, IonList, IonText } from "@ionic/react"
import Styles from './ScreenProvidePhotos.module.scss'
import ProjectPhoto from "../../../../../../assets/images/project-photo.png"

const ProvidePhotosScreen: React.FC = () => {
  return (

    <div className={Styles.pageContainer}>
      <div className={Styles.imageContainer}>
        <IonImg className={`${Styles.projectPhoto} ${Styles.centerText}`} src={ProjectPhoto}/>
      </div>
      <div className="ion-padding">
        <h3 className={`${Styles.title} ion-text-center`}>
          Provide Project Photos
        </h3>
        <IonGrid>
          <IonList className={`${Styles.centeredText}`}>
            <h6>There are 2 benefits to sharing project photos:</h6>
            <IonText className="ion-padding">
              <p>1. It helps us conduct reference checks more quickly, so you can start seeing leads.</p>
            </IonText>
            <IonText className="ion-padding">
              <p>2. It helps homeowners understand - and get excited by - the quality of your work.</p>
            </IonText>
          </IonList>
        </IonGrid>
      </div>
    </div>

  )
}

export default ProvidePhotosScreen
