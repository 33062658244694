export const createValidCssClassname = (value: string): string =>
  [
    formatStringFromPascalToCamelCase,
    removeSpaces,
  ].reduce((currentValue, stringProcessingFn: (input: string) => string) => stringProcessingFn(currentValue), value)

export const formatStringFromPascalToCamelCase = (value: string): string =>
  value.charAt(0).toLowerCase() + value.slice(1)

export const removeSpaces = (value: string): string => value.split(' ').join('')

export const isString = (maybeString: unknown): maybeString is string => typeof maybeString === 'string' || maybeString instanceof String

export const onlyTruthyStrings = (maybeStrings: unknown[]): string[] => maybeStrings.filter(isString).filter(string => !!string)
