import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, useIonRouter } from '@ionic/react'
import { useListTeamChatRoomsQuery } from '../../graphql/generated'
import ProjectAvatar from './ProjectAvatar'
import Styles from "./ShowProjectPage.module.scss"
import { chatboxOutline } from "ionicons/icons"
import { useGraphQLDataSource } from '../../api/graphql'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { PublicProfileModal } from '../profile/PublicProfileModal'
import { ProjectMemberTeam, ShowProjectMember } from './ShowProjectTypes'

type ProjectMemberRowProps = {
  member: ShowProjectMember,
  createChatRoom: (team1: ProjectMemberTeam, team2: ProjectMemberTeam) => void,
}

const useListChatRoomBetweenTeams = (team1Id: string, team2Id: string) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  // TODO: Research why refetchOnWindowFocus isn't defaulting to true when it should based on React Query's documentation.
  const result = useListTeamChatRoomsQuery(gqlDataSource, { team1Id, team2Id }, { refetchOnWindowFocus: true })

  if (result.isFetching || result.data === undefined) {
    return undefined
  }

  const chatRooms = result.data.listTeamChatRooms
  if (chatRooms !== undefined && chatRooms !== null && chatRooms?.length > 0)
    return chatRooms[0]

  return undefined
}

export const ProjectMemberRow: React.FC<ProjectMemberRowProps> = ({ member, createChatRoom }) => {
  const myTeam = useMyIndividualActiveTeam()
  const [ showModal, setShowModal ] = useState<boolean>(false)
  if (!myTeam) throw new Error("Can't render this component without an active team")
  const memberTeam = member.team

  const ionRouter = useIonRouter()
  const chatRoomBetweenTeams = useListChatRoomBetweenTeams(myTeam.id, memberTeam.id)

  const goToChatRoom = (chatRoomId: string) => {
    ionRouter.push(`/chats/${chatRoomId}`)
  }

  useEffect(() => {
    setShowModal(false)
  }, [ ionRouter.routeInfo.pathname ])

  return (
    <>
      <IonItem key={memberTeam.id} className={Styles.projectMemberItem} >
        <ProjectAvatar slot="start" title={memberTeam.name} />
        <IonLabel onClick={() => setShowModal(true)}>
          <h2>{memberTeam.name}</h2>
          <h3>{memberTeam.type}</h3>
        </IonLabel>

        <IonButton size='large' className={Styles.chatIconButton} slot="end" fill="clear" hidden={myTeam.id === memberTeam.id} onClick={event => {
          event.preventDefault()
          const doesAChatAlreadyExists = chatRoomBetweenTeams?.id !== undefined

          if (doesAChatAlreadyExists) {
            goToChatRoom(chatRoomBetweenTeams?.id)
          } else {
            createChatRoom(myTeam, memberTeam)
          }
          event.stopPropagation()
        }}>
          <IonIcon icon={chatboxOutline} />
        </IonButton>
      </IonItem>
      <PublicProfileModal
        team={memberTeam}
        breakpoints={[ 0, 0.5, 0.75, 0.92 ]}
        initialBreakpoint={0.95}
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      />
    </>
  )
}
