import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useIonRouter } from '@ionic/react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEnvironmentContext } from '../EnvironmentProvider'
import { getActiveTeam, useMaybeMyIndividual } from '../MyIndividualProvider/MyIndividualProvider'
import { GetMyIndividualQuery, useListMyProjectsAndLeadsQuery } from '../../../graphql/generated'
import { SegmentContext } from './SegmentProvider'
import { useAuthContext, WeaverIdTokenClaims } from '../AuthProvider'
import { EventProperties, NoData } from './events'
import { convertIsoDateToEpochQuietly } from '../../../common/utils/date'
import { useGraphQLDataSource } from '../../graphql'
import { alwaysArray } from '../../../common/utils'
import { UseQueryResult } from 'react-query'

export type EventName = keyof EventProperties

/**
 * Initialises the Segment AnalyticsBrowser.
 * Call this hook very early in the rendering of the app.
 */
export const useSegmentSetup = () => {
  const environment = useEnvironmentContext()
  const writeKey = environment.integrations.segment.frontend.writeKey

  return useMemo(() => {
    const analytics = AnalyticsBrowser.load({ writeKey })
    console.log('[SegmentProvider.useSegmentSetup] Init complete.', analytics)
    return analytics
  }, [ writeKey ])
}

export const useAnalyticsBrowserContext = () => {
  const analytics = useContext(SegmentContext)
  if (!analytics) throw new Error('[SegmentProvider.useAnalyticsBrowser] Cannot use the SegmentContext outside of its Provider!')
  return analytics
}

export const useSegmentPageChange = () => {
  const analytics = useAnalyticsBrowserContext()
  const ionRouter = useIonRouter()

  const pathname = ionRouter.routeInfo?.pathname
  const lastPathname = ionRouter.routeInfo?.lastPathname

  useEffect(() => {
    if (pathname !== lastPathname) {
      console.debug(`[useSegmentPageChange] Page change detected. Firing event!`, { pathname, lastPathname })
      analytics.page()
    }
  }, [ ionRouter.routeInfo?.pathname ])
}

/**
 * Identify the individual as the analytics user
 * Sets the specified team as the activeTeam on the analytics user's properties.
 * Also connects the analytics user to the team group.
 *
 * @param getMyIndividualQuery result from useGetMyIndividualQuery
 */
export const useAnalyticsIdentify = (getMyIndividualQuery: UseQueryResult<GetMyIndividualQuery, unknown>) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const listMyProjectsAndLeadsQuery = useListMyProjectsAndLeadsQuery(gqlDataSource, {}, {
    staleTime: 24 * 60 * 60 * 1000, // 1 day
    refetchOnWindowFocus: false,
  })

  const analytics = useAnalyticsBrowserContext()
  const auth = useAuthContext()

  console.log('[SegmentProvider.useAnalyticsIndividual] Render: ', { getMyIndividualQuery, listMyProjectsAndLeadsQuery, analytics, auth })

  // This gets called multiple times if `useMemo` is used instead!
  useEffect(() => {
    if (auth === undefined) return

    const myIndividual = getMyIndividualQuery.data?.getMyIndividual
    if (myIndividual == undefined || listMyProjectsAndLeadsQuery.isLoading) return

    const activeTeam = getActiveTeam(getMyIndividualQuery.data?.getMyIndividual ?? undefined)
    const activeTeamFields = activeTeam
      ? {
        activeTeamId: activeTeam.id,
        activeTeamType: activeTeam.type,
        activeTeamName: activeTeam.name,
        activeTeamCompanyNumber: activeTeam.companyNumber,
        teamId: [ activeTeam.id ],
      }
      : {}

    const projects = alwaysArray(listMyProjectsAndLeadsQuery.data?.listMyProjects)

    const loginMethod: string | undefined =
      `${auth.idToken?.sub}`.startsWith('google-oauth2|') // google-oauth2|0123456789abcdef
        ? 'google' //
        : `${auth.idToken?.sub}`.startsWith('auth0|') // auth0|0123456789abcdef
          ? 'auth0'
          : undefined

    // Tell segment about the user
    analytics.identify(myIndividual.id, {
      created_at: convertIsoDateToEpochQuietly(myIndividual.createdAt), // Speced by MW-1063
      email: auth.userData?.email,
      phone: auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber],
      firstName: myIndividual.givenName,
      lastName: myIndividual.familyName,
      avatar: myIndividual.pictureURL,
      loginMethod,
      ...activeTeamFields,
      projectId: projects.map(item => item.id),
    })

    // Update the Team group with users info
    if (activeTeam) {
      analytics.group(activeTeam.id, {
        name: activeTeam.name, // This gets translated by Segment for Mixpanel: https://segment.com/docs/connections/destinations/catalog/mixpanel/
        teamId: activeTeam.id,
        teamName: activeTeam.name,
        teamType: activeTeam.type,
        teamCompanyNumber: activeTeam.companyNumber,
      })
    }

    projects.map(project => analytics.group(project.id, {
      name: project.title, // This gets translated by Segment for Mixpanel: https://segment.com/docs/connections/destinations/catalog/mixpanel/
      projectId: project.id,
      status: project.status,
      description: project.description,
    }))

    console.debug(`[SegmentProvider.useAnalyticsIndividual] Identify '${myIndividual.id}' with team '${activeTeam?.id}': `, { myIndividual, activeTeam, projects, loginMethod, analytics, auth })
  }, [ analytics, auth, getMyIndividualQuery, listMyProjectsAndLeadsQuery ])
}

/**
 * PRIVATE: internal function for calling any analytics function with any data
 * It's used so that useAnalyticsEvent/useFireAnalyticsEvent can simplify their external type safety without changing functionality
 *
 * It is NOT intented for external consumption - please do NOT consume/export without consultation
 */
const useUnsafeAnalyticsEvent = (eventName: EventName) => {
  const analytics = useAnalyticsBrowserContext()

  const activeTeam = getActiveTeam(useMaybeMyIndividual())
  const activeTeamProps = activeTeam
    ? { teamId: [ activeTeam.id ] }
    : {}

  const trackFn = useCallback(async (extraData = {}) => {
    const extraDataWithAutoGrouping = {
      ...extraData,
      ...activeTeamProps,
    }
    console.debug(`[SegmentProvider.useAnalyticsEvent.trigger] Send event '${eventName}': `, extraDataWithAutoGrouping)
    await analytics.track(eventName, extraDataWithAutoGrouping)
  }, [ analytics ])

  return trackFn
}

/**
 * Use this hook when you need to get a function to call the analytics with.
 * Returns a function which will fire the event named when called with optional extra data.
 *
 * @param eventName EventName
 * @returns A function to fire the event named, with the optional extra data
 */
export const useAnalyticsEvent = <TEventName extends EventName>(eventName: TEventName): (...[ extraData ]: EventProperties[TEventName] extends NoData ? [] : [EventProperties[TEventName]]) => Promise<void> => {
  return useUnsafeAnalyticsEvent(eventName)
}

/**
 * Fires the event named with the optional extra data whenever the dependencies specified change.
 *
 * @param props EventName, optional extra data, and change dependency array
 */

type UseFireAnalyticsEventProps<TEventName extends EventName> =  {
  eventName: TEventName,
  extraData?:  EventProperties[TEventName] extends NoData ? never : EventProperties[TEventName],
  deps?: unknown[],
}
export const useFireAnalyticsEvent = <TEventName extends EventName>({ eventName, extraData, deps = [] }: UseFireAnalyticsEventProps<TEventName>) => {
  const triggerEvent = useUnsafeAnalyticsEvent(eventName)

  // This gets called multiple times if `useMemo` is used instead!
  useEffect(() => {
    triggerEvent(extraData)
  }, deps)
}
