import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { App } from '@capacitor/app'
import useStatefulEffect from '../../../../common/hooks/useStatefulEffect'
import { isMobile } from '../../../../common/utils/ionic'
import { Environment } from '../../EnvironmentProvider'

const useAuth0Client = (environment: Environment) => {
  const auth0ClientOptions = useAuth0ClientOptions(environment)

  const auth0Client = useStatefulEffect<Auth0Client>('auth0Client', ({ setState: setAuth0Client }) => {
    if (auth0ClientOptions === undefined) return

    const client = new Auth0Client(auth0ClientOptions)
    setAuth0Client(client)
  }, [ auth0ClientOptions ]).state

  return { auth0Client, auth0ClientOptions }
}

const useAuth0ClientOptions = (environment: Environment) =>
  useStatefulEffect<Auth0ClientOptions>('auth0ClientOptions', ({ setState: setAuth0ClientOptions }) => {
    console.log('[useAuth0ClientOptions.useStatefulEffect] Running', { environment })
    const common: Auth0ClientOptions = {
      domain: environment.auth0.domain,
      client_id: environment.auth0.client_id,
      audience: environment.auth0.audience,
      cacheLocation: 'localstorage',
      authorizeTimeoutInSeconds: 600, // 10min - Needed to prevent popup window hung state, outlined here: https://weaver.atlassian.net/browse/MW-1749
    }

    if (isMobile) {
      App.getInfo()
        .then(appInfo => setAuth0ClientOptions({
          ...common,
          redirect_uri: `${appInfo.id}://${environment.auth0.domain}/capacitor/${appInfo.id}/callback`,
        }))
    } else {
      // We might yet need to add a redirect_uri
      setAuth0ClientOptions(common)
    }
  }, []).state

export default useAuth0Client
