import { IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import ResponsiveContentWrapper from '../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'
import { BudgetRange } from '../../../../../graphql/generated'
import { budgetRangeLabels } from '../budgetRanges.i18n'

type BudgetRangeIndicatorHeaderProps = {
  budgetRange : BudgetRange,
}

export const BudgetRangeIndicatorHeader: React.FC<BudgetRangeIndicatorHeaderProps> = ({ budgetRange }) => {
  return (
    <ResponsiveContentWrapper>
      <IonToolbar color={'primary'}>
        <IonTitle>Unlock {budgetRangeLabels[budgetRange]}</IonTitle>
      </IonToolbar>
    </ResponsiveContentWrapper>

  )
}
