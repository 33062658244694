import React, { useLayoutEffect, useState } from 'react'
import { IonButton, IonFooter, IonGrid, IonIcon, IonRow, IonToolbar } from '@ionic/react'
import GenericSelectorList from '../../common/components/GenericSelectorList'
import { TeamType } from '../../graphql/generated'
import { selectableTeamTypeLabels } from './team/onboarding.i18n'
import { arrowForwardOutline } from 'ionicons/icons'
import { useFireAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { isSomething } from '../../common/utils'
import { useIsWeaverTeamMember } from '../../api/providers/AuthProvider'
import Styles from "./TeamTypeSelector.module.scss"
import { useRouteToOnboardingForTeam } from '../../routes'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../common/components/WeaverIonWrappers/WeaverIonFooter'

const TeamTypeSelector: React.FC = () => {
  const [ selected, setSelected ] = useState<TeamType | undefined>(undefined)
  const switchToOnboardingTeam = useRouteToOnboardingForTeam(selected)

  // If I've already joined a team, switch to that onboarder
  const myTeam = useMyIndividualActiveTeam()
  const switchToExistingTeam = useRouteToOnboardingForTeam(myTeam?.type)
  useLayoutEffect(() => {
    if (myTeam) {
      console.debug('[TeamTypeSelector] Switching to team ', { myTeam })
      switchToExistingTeam()
    }
  }, [ myTeam?.id ])

  // If I'm authenticated as a Weaver Team Member without a team, switch to the Weaver onboarder
  const isWeaverTeamMember = useIsWeaverTeamMember()
  const switchToWeaverTeam = useRouteToOnboardingForTeam(TeamType.Weaver)
  useLayoutEffect(() => {
    if (!myTeam && isWeaverTeamMember) {
      console.debug('[TeamTypeSelector] Switching to the Weaver Team')
      switchToWeaverTeam()
    }
  }, [ myTeam?.id, isWeaverTeamMember ])

  useFireAnalyticsEvent({
    eventName: 'Onboarding_TeamType_Selected',
    extraData: {
      selectedTeamType: selected,
      existingTeamId: myTeam?.id,
      existingTeamType: myTeam?.type,
    },
    deps: [ selected ],
  })

  return (
    <WeaverIonPage>
      <WeaverIonContent>
        <div className={Styles.container}>
          <div className={Styles.slideContainer}>
            {/* Type Selector Page */}
            <IonGrid className={Styles.contentContainer}>
              <h3 className="ion-text-sm-center ion-padding-start">Which role best describes you?</h3>
              <IonRow className={Styles.item}>
                <GenericSelectorList
                  options={
                    Object.values(TeamType)
                      .filter(teamType => teamType !== TeamType.Weaver) // Cannot select Weaver
                      .map(type => ({
                        key: type,
                        label: selectableTeamTypeLabels[type],
                        value: type,
                      }))
                      .filter(isSomething)
                  }
                  multiSelect={false}
                  selected={selected}
                  onSelect={setSelected}
                />
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </WeaverIonContent>
      <WeaverIonFooter className="ion-no-border ion-padding">
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="primary" slot="end" disabled={selected === undefined} onClick={switchToOnboardingTeam}>
            Next <IonIcon icon={arrowForwardOutline} />
          </IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </WeaverIonPage>
  )
}

export default TeamTypeSelector
