import { App } from "@capacitor/app"
import { useEffect, useState } from "react"
import { useRouteMatch } from 'react-router'
import { ensureTrailingSlash, stripLeadingSlash } from "../utils"
import { isMobile } from "../utils/ionic"

/**
 * Given https://localhost:3000/some/path/here it should return https://localhost:3000/
 */
export const getUrlRoot = (
  windowLocation: typeof window.location): string => {

  const url = new URL(windowLocation.href)
  return url.origin
}

export const useRedirectBaseURL = (path = ''): string | undefined => {
  const [ baseURL, setBaseURL ] = useState<string>()
  const match = useRouteMatch()

  useEffect(() => {
    if (isMobile) {
      console.debug(`[useRedirectBaseURL] Mobile path: `, { baseURL, path })
      App.getInfo().then(info => setBaseURL(`${info.id}://`))
    } else {
      const urlRoot = getUrlRoot(window.location)
      console.debug(`[useRedirectBaseURL] Webapp path: `, { baseURL, path, urlRoot, match, windowLocationHref: window.location.href })
      setBaseURL(urlRoot)
    }
  }, [ isMobile, location ])

  const fullURL = baseURL === undefined
    ? undefined
    : `${ensureTrailingSlash(baseURL)}${stripLeadingSlash(path)}`
  console.debug(`[useRedirectBaseURL] Full URL: ${fullURL}`)

  return fullURL
}
