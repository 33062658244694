
import React, { useCallback, useState } from "react"
import { IonButton, useIonRouter } from "@ionic/react"
import { useScreensWithProps } from "../../../common/hooks/useScreens"
import { BudgetRange, useAcceptLeadMutation, useGetStripeProductConfigsQuery } from "../../../graphql/generated"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { moneyToText } from "../../../common/utils/currency"
import { budgetRangeLabels } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n"
import { budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionStripeProduct } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { useGraphQLDataSource } from "../../../api/graphql"
import MembershipPayPerLead from "./MembershipPayPerLead"
import SelectMembershipPlan from "./SelectMembershipPlan"
import useStripePaymentForLeadPaymentHandler from "../../../api/thirdParty/stripe/useStripePaymentHandler"
import WeaverIonPage from "../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import LoadingSpinnerPage from "../../../common/components/LoadingSpinner/LoadingSpinnerPage"
import { firstElement } from "../../../common/utils/codeReadability"
import { alwaysArray } from "../../../common/utils"
import { pageConfig_ShowProject, useRouteTo } from "../../../routes"
import BrilliantScreen from "../../../common/components/BrilliantScreen/BrilliantScreen"

enum LeadAcceptanceScreens {
  SelectMembershipPlan = "SelectMembershipPlan",
  BuyMembership = "BuyMembership",
  BuyLead = "BuyLead",
  AcceptLead = "AcceptLead", // Don't request payment
}

type ContractorLeadAcceptorProps = {
  leadId: string,
  budgetRange: BudgetRange,
  /** leads for which the user already has a subscription do not require payment */
  hasBudgetRangeSubscription?: boolean,
  /** leads created with ReferralType.ProjectOwner do not require payment */
  isProjectOwnerReferral?: boolean,
}

export enum FeeOption { Subscription, PerLead }

const ContractorLeadAcceptorPage: React.FC<ContractorLeadAcceptorProps> = ({ leadId, budgetRange, hasBudgetRangeSubscription = false, isProjectOwnerReferral = false }) => {
  const weaverFlags = useWeaverFlags()

  const paymentNotNeeded = !weaverFlags["contractor-lead-acceptor"].enabled || hasBudgetRangeSubscription || isProjectOwnerReferral

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: 24 * 60 * 60 * 1000, // 1 day
  })

  const stripeProductLeadFee = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .filter(each => each.product === budgetRangeLeadFeeStripeProduct[budgetRange])
    .find(firstElement)
  const stripeProductMonthlySubscription = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .filter(each => each.product === budgetRangeMonthlySubscriptionStripeProduct[budgetRange])
    .find(firstElement)

  const isLoading = !getStripeProductConfigsQuery.data || !stripeProductLeadFee

  type ScreenProps = {
    budgetRange: typeof budgetRange,
    stripeProductLeadFee: typeof stripeProductLeadFee,
    stripeProductMonthlySubscription: typeof stripeProductMonthlySubscription,
    weaverFlags: typeof weaverFlags,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<LeadAcceptanceScreens, ScreenProps>({
    init: () => paymentNotNeeded ? LeadAcceptanceScreens.AcceptLead : LeadAcceptanceScreens.SelectMembershipPlan,
    screenProps: {
      budgetRange,
      stripeProductLeadFee,
      stripeProductMonthlySubscription,
      weaverFlags,
    },
    screens: {

      SelectMembershipPlan: {
        render: useCallback(props => {

          const changeScreenToBuyMembership = props.useChangeScreen(LeadAcceptanceScreens.BuyMembership)
          const changeScreenToBuyLead = props.useChangeScreen(LeadAcceptanceScreens.BuyLead)

          const handleNextClick = (selected: FeeOption | undefined) => {
            if (selected !== undefined) {
              return  selected === FeeOption.Subscription ? changeScreenToBuyMembership() : changeScreenToBuyLead()
            }
          }

          return <SelectMembershipPlan
            budgetRange={props.budgetRange}
            budgetRangeLabel={budgetRangeLabels[props.budgetRange]}
            budgetRangeLeadFee={moneyToText(props.stripeProductLeadFee?.price)}
            budgetRangeMonthlySubscriptionFee={moneyToText(props.stripeProductMonthlySubscription?.price)}
            handleNextClick={handleNextClick}
          />
        }, []),
      },

      BuyMembership: {
        render: useCallback(props => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const product = budgetRangeMonthlySubscriptionStripeProduct[props.budgetRange]
          const makeStripePayment = useStripePaymentForLeadPaymentHandler()

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)
          const onButtonClick = () => {
            if (!isPaymentInProgress) {
              setIsPaymentInProgress(true)
              makeStripePayment({ product, leadId })
            }
          }

          const subscriptionPerksList = [
            `Receive leads in the ${budgetRangeLabels[props.budgetRange]} budget`,
            "Free to tender",
            "Satisfaction Money back guarantee!*",
            "Cancel your subscription at any time!*",
            "No further fees even if you win a tender",
          ]
          const title = `Get unlimited ${budgetRangeLabels[props.budgetRange]} leads`
          const paymentFee = moneyToText(props.stripeProductMonthlySubscription?.price)

          return <MembershipPayPerLead
            title={title}
            list={subscriptionPerksList}
            budgetRange={budgetRangeLabels[props.budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={true}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, []),
      },

      BuyLead: {
        render: useCallback(props => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const makeStripePayment = useStripePaymentForLeadPaymentHandler()
          const product = budgetRangeLeadFeeStripeProduct[props.budgetRange]

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)
          const onButtonClick = () => {
            if (!isPaymentInProgress) {
              setIsPaymentInProgress(true)
              makeStripePayment({ product, leadId })
            }
          }

          const payPerLeadPerksList = [
            "Free to tender",
            "Satisfaction Money back guarantee!*",
            "No monthly commitment",
            "No further fees even if you win a tender",
          ]

          const paymentFee = moneyToText(props.stripeProductLeadFee?.price)

          return <MembershipPayPerLead
            title={"Pay per lead"}
            list={payPerLeadPerksList}
            budgetRange={budgetRangeLabels[props.budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={false}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, []),
      },

      AcceptLead: {
        render: useCallback(() => {
          const goToShowProject = useRouteTo(pageConfig_ShowProject.path)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const acceptLead = useAcceptLeadMutation(gqlDataSource)

          return <>
            <WeaverIonContent>
              <BrilliantScreen
                title='Amazing!'
                subtitle='You have accepted a lead'
                actionButtonProps={{
                  onClick: async () => {
                    // We only accept the lead when Continue is clicked
                    await acceptLead.mutateAsync({ id: leadId })

                    // NOTE: Leads are virtual representations of Projects and share the same ID
                    goToShowProject({ id: leadId })()
                  },
                  children: <>Continue</>,
                  expand: 'block',
                }}
              />
            </WeaverIonContent>
          </>
        }, []),
      },
    },
  })

  return isLoading
    ? <LoadingSpinnerPage name='ContractorLeadAcceptorPage' />
    : <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>{Screen}</WeaverIonPage>
}

export default ContractorLeadAcceptorPage
