import React from 'react'
import Styles from "./ContractorPrice.module.scss"
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { onlyTruthyStrings } from '../../../../common/utils/string'

const ContractorPrice: React.FC = () => {
  const weaverFlags = useWeaverFlags()

  const {
    currentPrice, // Always shown in red
    originalPrice,
  } = weaverFlags["create-project-contractors"]

  return (
    <div className={Styles.mainContainer}>
      <h4>
        Total:
        {/* Current Price in bold red */}
        {currentPrice && <span className={Styles.currentPrice}>{currentPrice}</span>}
        {/* Original Price, struck out if we also have a Current Price */}
        {originalPrice && <span className={onlyTruthyStrings([ Styles.originalPrice, currentPrice && Styles.lineThrough ]).join(' ')}>{originalPrice}</span>}
      </h4>
    </div>
  )
}
export default ContractorPrice
