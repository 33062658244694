import React from 'react'
import { IonItem, IonText } from '@ionic/react'
import ProfileHeader from '../ProfileHeader'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'

const MyWeaverProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()

  console.debug(`[MyWeaverProfilePage] Render: `, { myTeam })

  if (myTeam === undefined) {
    return <LoadingSpinnerPage name="MyHomeownerProfilePage" />
  }

  return (
    <WeaverIonPage>
      <WeaverIonHeader>
        <ProfileHeader isMyTeam={true} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <h3>Weaver</h3>
        </IonItem>
        <IonText className={Styles.lightGrey}>
          <p>Here to help.</p>
        </IonText>
        <TeamMembers teamId={myTeam.id}/>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default MyWeaverProfilePage
