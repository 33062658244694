import React from 'react'
import { IonBadge, IonIcon, IonItem } from '@ionic/react'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import ProfileHeader from '../../profile/ProfileHeader'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import { alwaysArray, uniqueFilter } from '../../../common/utils'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import Styles from './WorkHistoryForReferencePage.module.scss'
import { DisplayUploadedFileAsPhoto } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import { moneyToText } from '../../../common/utils/currency'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import { useGetContractorProfileQuery } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'

type WorkHistoryForReferenceProps = {
  contractorProfileId?: string | null,
  workHistoryId?: string | null,
}

const WorkHistoryForReferencePage: React.FC<WorkHistoryForReferenceProps> = ({ contractorProfileId, workHistoryId }) => {
  console.debug(`[WorkHistoryForReference] Render: `, { contractorProfileId, workHistoryId })

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  if (!contractorProfileId) {
    console.error(`[MyContractorProfilePage] There is no contractorProfileiD: `, { contractorProfileId , workHistoryId })
    return <ErrorBlockPage />
  }

  const { data, error, isLoading, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: contractorProfileId }, { staleTime: 5 * 60 * 1000 })

  if (isLoading) {
    return <LoadingSpinnerPage name="MyContractorProfilePage" />
  }

  if (error) {
    console.error(`[MyContractorProfilePage] Failed to load Contractor Profile: `, {  data, error })
    return <ErrorBlockPage onRefresh={refetch} />
  }

  if (data?.getContractorProfile == null) {
    console.warn(`[MyContractorProfilePage] Contractor Profile is missing: `, { data, error })
    return <ErrorBlockPage><p>Unable to find the Profile.</p></ErrorBlockPage>
  }

  const workHistoryItem = alwaysArray(data?.getContractorProfile?.workHistory).find(workHistoryItem => workHistoryItem.id === workHistoryId)

  return <WeaverIonPage>
    <WeaverIonHeader>
      <ProfileHeader isMyTeam={false} />
    </WeaverIonHeader>
    <WeaverIonContent>
      <h3>{formatAddressToSingleLine(workHistoryItem?.projectAddress) ?? 'Work History'}</h3>

      <IonItem className={Styles.costOfWorkContainer}>
        <IonIcon slot='start' icon={allIonIcons.cashOutline} />
        <span>
          <p>Cost of Work</p>
          <p>{moneyToText(workHistoryItem?.constructionValue, { withPennies: true })}</p>
        </span>
      </IonItem>

      <IonItem className={Styles.typeOfWorkContainer}>
        <IonIcon icon={allIonIcons.hammerOutline} slot="start"/>
        <span>
          <p>Type</p>
          {alwaysArray(workHistoryItem?.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
        </span>
      </IonItem>

      <h3>Reference</h3>
      {workHistoryItem?.references !== undefined
        ? alwaysArray(workHistoryItem.references)
          .map(reference => reference.teamType)
          .filter(uniqueFilter)
          .map(teamType => <p key={teamType}>{teamType}</p>)
        : <p>No references have been uploaded</p>
      }

      <h3>Photos</h3>
      <div className={Styles.photoContainer}>
        { workHistoryItem?.photos ? alwaysArray(workHistoryItem.photos)
          .map(photo =>
            <div key={photo.id} className={Styles.photoItemContainer}>
              <DisplayUploadedFileAsPhoto uploadedFileId={photo.id}  shouldDisplayDeleteButton={false} />
            </div>,
          )
          : <p>No photos have been uploaded</p>
        }
      </div>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default WorkHistoryForReferencePage
