import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { PublicProfileModal } from '../profile/PublicProfileModal'

const WeaverPublicProfileModalIonButton: React.FC = ({ children }) => {
  const myTeam = useMyIndividualActiveTeam()
  const [ showModal, setShowModal ] = useState<boolean>(false)

  return <>
    <IonButton onClick={() => setShowModal(true)}>{children}</IonButton>
    {myTeam?.id && <PublicProfileModal
      team={myTeam}
      breakpoints={[ 0, 0.5, 0.75, 0.92 ]}
      initialBreakpoint={0.95}
      isOpen={showModal}
      swipeToClose={true}
      onDidDismiss={() => setShowModal(false)}
    />}
  </>
}

export default WeaverPublicProfileModalIonButton
