import React from 'react'
import { IonText } from "@ionic/react"
import GenericSelectorList, { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import { BudgetRange } from '../../../../graphql/generated'

type ListBudgetRangesProps = {
  heading?: string,
  options: GenericSelectorOption<BudgetRange>[],
  onSelect: (budgetRange?: BudgetRange) => Promise<void> | void,
}

const ListBudgetRanges: React.FC<ListBudgetRangesProps> = ({ heading, options, onSelect }) =>
  options.length === 0
    ? null
    : <>
      {heading && <IonText className='ion-padding-start'>{heading}</IonText>}
      <GenericSelectorList
        options={options}
        multiSelect={false}
        selected={undefined}
        onSelect={onSelect}
        showItemDetail={false}
      />
    </>

export default ListBudgetRanges
