import { Directory, Filesystem } from "@capacitor/filesystem"
import write_blob from "capacitor-blob-writer"
import { decode } from "base64-arraybuffer"
import { isPlatform } from "@ionic/react"
import { FileOpener } from '@awesome-cordova-plugins/file-opener'
import { DateTime } from "luxon"
import { UploadedFile } from "../../graphql/generated"

export const fileIconSelector = (fileName: string) => {
  const fileEnding = fileName.split('.').pop()
  switch (fileEnding) {
    case 'pdf':
      return '/assets/icon/pdf-icon.svg'
    case 'xlsx':
      return '/assets/icon/sheets-icon.svg'
    case 'zip':
      return '/assets/icon/zip-icon.svg'
    case 'docx':
    case 'doc':
      return '/assets/icon/document-icon.svg'
    case 'png':
    case 'jpeg':
      return '/assets/icon/image-icon.svg'
    default:
      return '/assets/icon/default-icon.svg'
  }
}

/**
 * Saves a file to the Downloads folder for Desktop devices. The download is done by creating a HTML A object with the URL and clicking on it.
 * This function returns an exception if Capacitor's isPlatform('desktop')
 * resolves to false.
 * @param fileName The name to be use for the download file
 * @param blob  The content to download.
 */
export const saveFileToDownloadsFolder = (fileName: string, blob: Blob) => {
  if (!(isPlatform('desktop') || isPlatform('mobileweb'))) {
    throw Error('This function is only supported for Desktop or mobile web devices.')
  }
  // Create a HTML A object with the URL and click on it in order to trigger to download.
  const url = window.URL.createObjectURL(
    new Blob([ blob ]),
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  // Remove the link
  link.parentNode?.removeChild(link)
}

/**
 * Saves a file to the app's internal documents folder (Directory.Documents).
 * @param file - The file to be saved.
 * @returns The path of the file with the Directory.Documents path, e.g.: /DOCUMENTS/sample.txt
 */
export const saveFileToDeviceStorage = async (file: File): Promise<string> => {
  // We use write_blob for writing since it's more effective than Capacitor's write function on iOS and Android
  const documentPath = await write_blob({
    directory: Directory.Documents,
    path: file.name,
    blob: file,
    on_fallback: (error: Error) => { throw error },
  })
  return documentPath
}

/**
 * Reads a file from the app's internal documents folder (Directory.Documents).
 * @param fileName The name of the file.
 * @returns The file's blob content.
 */
export const readFileFromDeviceStorage = async (fileName: string | null | undefined): Promise<Blob | undefined> => {
  try {
    if (!fileName) return
    const result = await Filesystem.readFile({
      directory: Directory.Documents,
      path: fileName,
    })
    const blob = new Blob([ new Uint8Array(decode(result.data)) ])
    return blob
  } catch (e){
    // If an exception happens, e.g.: the file doesn't exist, return undefined
    return undefined
  }
}

/**
 * Resolves the full path of the app's internal documents folder.
 * @returns A string with the full path for the documents folder, e.g.: file:///var/mobile/Containers/Data/Application/8D125AE6-A599-401E-9465-7A3311175FAD/Documents/
 */
export const resolveAppDirectoryFullPath = async (directory = Directory.Documents) =>
  Filesystem.getUri({
    directory,
    path: '',
  }).then(getUriResult => getUriResult.uri)

/**
 * Opens a file in the FileOpener's plug-in.
 * @param fileName The name of the file
 * @param fileContentType The file's content type
 */
export const openFile = async (fileName: string | null | undefined, fileContentType: string | null | undefined) => {
  if (!fileName)
    return

  if (!fileContentType)
    return

  await resolveAppDirectoryFullPath().then(appDirectoryFullPath => {
    const fullFilePath = appDirectoryFullPath + '/' + fileName
    FileOpener.open(fullFilePath, fileContentType)
  }, (error) => {
    console.log(error)
    throw error
  })
}

export const convertBlobToFile = (currentBlob: Blob, fileName: string): File => {
  return new File([ currentBlob ], fileName, { lastModified: new Date().getTime(), type: currentBlob.type })
}

export const convertImageToBlob = (base64ImageRepresentation: string | undefined, format: string ) => {
  if (base64ImageRepresentation === undefined) return null
  const blob = new Blob([ new Uint8Array(decode(base64ImageRepresentation)) ], {
    type: `image/${format}`,
  })
  return blob
}

export const isFilenameExtensionAnImageExtension =  (fileName: string | null | undefined): boolean => {
  if (!fileName)
    return false

  const mostCommonImageFormatExtensions = [ 'png', 'jpeg', 'jpg', 'bmp' ]
  const fileEnding = fileName.split('.').pop()
  if (fileEnding){
    return mostCommonImageFormatExtensions.includes(fileEnding)
  }
  return false
}

export const convertImageToFile = (base64ImageRepresentation: string | undefined, format: string): File | undefined => {
  const blob = convertImageToBlob(base64ImageRepresentation, format)
  if (blob){
    return convertBlobToFile(blob, getRandomNameForFile(format))
  }
  return undefined
}

/**
 * Returns a random file name using the current time's epoch milliseconds and the specified extension in lowercase.
 * @param fileExtension the file's extension , e.g.: 'jpeg', 'doc'.
 * @returns A file name with the format '${currentTimeMilliseconds}.{fileExtensionInLowercase}'
 */
export const getRandomNameForFile = (fileExtension: string) => {
  return DateTime.now().toMillis() + "." + fileExtension.toLocaleLowerCase()
}

export const downloadUploadedFile = async (
  uploadedFile: Pick<UploadedFile, 'id' | 'fileName' | 'fileSizeInBytes' | 'updatedAt' | 'signedUrlForDownload'>,
  onProgressUpdate: (inProgress: boolean) => Promise<void> | void,
  saveToDownloadsFolder = true,
): Promise<File | null | undefined>  => {
  try {
    onProgressUpdate(true)
    const url = uploadedFile.signedUrlForDownload.url
    if (!url) return

    const fileName = uploadedFile.fileName
    if (!fileName) return

    const result = await fetch(url)
    const blob = await result.blob()

    if ((isPlatform('desktop') || isPlatform('mobileweb')) && saveToDownloadsFolder) {
      saveFileToDownloadsFolder(fileName, blob)
    }

    const file = convertBlobToFile(blob, fileName)
    await saveFileToDeviceStorage(file)

    onProgressUpdate(false)
    return file
  } catch (err) {
    onProgressUpdate(false)
    throw new Error(`File ${uploadedFile.fileName} could not be saved. ${err}`)
  }
}
