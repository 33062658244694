import React, { useContext } from 'react'
import { matchPath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { PageConfig, PageRender } from './routes'
import { sortBy, SortDirection } from './common/utils'

export const PageConfigContext = React.createContext<PageConfig | undefined>(undefined)

export const withPageConfig = (pageConfig: PageConfig, pageRender: PageRender): () => JSX.Element =>
  () => (
    <PageConfigContext.Provider value={pageConfig}>
      {pageRender()}
    </PageConfigContext.Provider>
  )

export const usePageConfig = () => {
  const pageConfig = useContext(PageConfigContext)
  if (pageConfig === undefined) {
    throw new Error("[usePageConfig] This can only be used within the `withPageConfig` wrapper!")
  }

  return pageConfig
}

enum ParamsType {
  useParams = "useParams",
  useRouteMatch = "useRouteMatch",
  matchPath = "matchPath",
  lastMatchPath = "lastMatchPath",
}

/**
 * If only we could just have `useParams` and be done, none of this would be needed. Instead, here we are.
 * This hook is a drop-in replacement for `useParams` that follows the same API and tries much harder to deliver what's needed.
 *
 * The approach is to:
 * 1. Load all the data, not just from `useParams`, but also `useRouteMatch` (which often works better), and then falls back to parsing the paths manually with `matchPath`.
 * 2. Rank the different options by the quantity of data in their params
 * 3. Return the option with the most data, erroring if we still don't have any params
 *
 * The `useParams` option is a straightforward usage as we should be using.
 * The `useRouteMatch` option should similarly always work, but regularly it doesn't. It mostly works when `useParams` does not.
 * The `matchPath` option is us parsing the `ionRouter.routeInfo.pathname` against the current page config. This rarely bubbles to the top.
 *
 * The `lastMatchPath` option is really interesting.
 * This is us parsing the `ionRouter.routeInfo.lastPathname` - which happens when the `ionRouter` path information has updated, but the rendered component is old.
 * In this case the URL is for a different page the one in `IonRoute.render`! This is an attempt to keep to whatever is being rendered, as it will rely on the that data.
 *
 * Once you've onboarded and been redirected to the profile tab, the `useRouteMatch` option is mostly used.
 * If you then click the back button from the New Work History menu, it uses the `lastMatchPath` option.
 * After you've onboarded and refreshed, the `useParams` option seems to be solid and reliable so far.
 *
 * In all cases, none of this should be needed.
 */
export const useParamsFromPageConfig: typeof useParams = <Params extends { [K in keyof Params]?: string } = object>(): Params => {
  // Load all the hooks
  const params = useParams<Params>()
  const routeMatch = useRouteMatch<Params>()
  const pageConfig = usePageConfig()
  const ionRouter = useIonRouter()
  const match = matchPath<Params>(ionRouter.routeInfo.pathname, { path: pageConfig.path, exact: pageConfig.exact })
  const lastMatch = ionRouter.routeInfo.lastPathname
    ? matchPath<Params>(ionRouter.routeInfo.lastPathname, { path: pageConfig.path, exact: pageConfig.exact })
    : null

  const debugObjects = {
    useParams: params,
    useRouteMatch: routeMatch,
    usePageConfig: pageConfig,
    useIonRouter: ionRouter,
    matchPath: match,
    useHistory: useHistory(),
    useLocation: useLocation(),
  }

  // Build a list of the params options, ordering by the option with the most params first
  const paramsOptions: { type: ParamsType, params?: Params }[] = [
    { type: ParamsType.useParams, params: params },
    { type: ParamsType.useRouteMatch, params: routeMatch.params },
    { type: ParamsType.matchPath, params: match?.params },
    { type: ParamsType.lastMatchPath, params: lastMatch?.params },
  ].sort(sortBy(option => option.params === undefined ? -1 : Object.keys(option.params).length, SortDirection.INVERTED))

  //
  if (paramsOptions[0].params !== undefined && Object.keys(paramsOptions[0].params).length !== 0) {
    console.debug(`[useParamsFromPageConfig] Using ${paramsOptions[0].type}: `, paramsOptions[0].params)
    return paramsOptions[0].params
  }

  // HACK! We're unsuccessful and have no params, output some debugging information as something has probably broken
  // We might want to error instead and auto-reload at this point
  console.error(`[useParamsFromPageConfig] ${paramsOptions[0].type} has NO params! `, paramsOptions, debugObjects)
  return paramsOptions[0].params ?? {} as Params
}
