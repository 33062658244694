import React from 'react'
import ChatFooter from './footer/ChatFooter'
import ChatContent from './ChatContent'
import GlobalHeader from '../../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { useChatRoom } from './services/ChatRoomProvider'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'

type ChatRoomProps = {
  goToFilePickerScreen: () => void,
}

const ChatRoom: React.FC<ChatRoomProps> = ({ goToFilePickerScreen }) => {
  const { chatRoomName } = useChatRoom()

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={chatRoomName || 'My Chat'} />
      </WeaverIonHeader>
      <ChatContent />

      <ChatFooter goToFilePickerScreen={goToFilePickerScreen} />
    </>
  )
}

export default ChatRoom
