import React, { SyntheticEvent, useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { ChildChildTask, ChildTask, Task as TaskType } from "./types"
import { IonBadge, IonItem, IonLabel, IonPopover, IonContent, IonList, IonRadio, IonRadioGroup, RadioGroupChangeEventDetail } from '@ionic/react'
import Styles from "./Task.module.scss"
import IonIconTyped from '../../../common/components/IonIconTyped'
import { parseIconName } from '../../../common/components/IonIconTyped/icons'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TaskStatus, TeamType, useUpdateProjectTaskStatusMutation, UpdateProjectTaskStatus } from '../../../graphql/generated'
import { useGraphQLDataSource } from "../../../api/graphql"

const getStatusData = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.Completed:
      return { color: "success", label: "Done" }
    case TaskStatus.Overdue:
      return { color: "danger", label: "Overdue" }
    case TaskStatus.InProgress:
      return { color: "tertiary", label: "In Progress" }
    case TaskStatus.NotStarted:
    default:
      return { color: "light", label: "To Do" }
  }
}

export type TaskProps = {
  task: TaskType | ChildTask | ChildChildTask,
  parentId?: string | undefined,
  onClick?: ({ id, parentId }: { id: string, parentId: string | undefined }) => unknown | void,
  refetchTasks?: any,
  refetchStatus?: boolean,
}
const DEFAULT_ICON = parseIconName("checkmarkCircleOutline")

const Task: React.FC<TaskProps> = ({ task, parentId, onClick, refetchTasks, refetchStatus  }) => {
  const { id, icon, title, dueAt, isConfidential, status, assignedTeam } = task
  const [ taskStatusPopoverOpen, setTaskStatusPopover ] = useState(false)
  const [ statusLoading, setStatusLoading ] = useState(false)
  const popover = useRef<HTMLIonPopoverElement>(null)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  // const { isFetching, error, data, refetch } = useListProjectTasksQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false })

  const updateProjectTaskStatus = useUpdateProjectTaskStatusMutation(gqlDataSource)

  useEffect(() => {
    if (refetchStatus && statusLoading) return
    if (!refetchStatus && statusLoading) setStatusLoading(false)

  },[ refetchStatus ])

  const childTasks = "childTasks" in task && task.childTasks || []
  const completed = childTasks.filter(x => x.status == TaskStatus.Completed).length || 0

  const currentTeam = useMyIndividualActiveTeam()
  const dueDate = dueAt && DateTime.fromISO(dueAt).toLocaleString(DateTime.DATE_SHORT)

  const parsedIcon = icon ? parseIconName(icon) : DEFAULT_ICON
  let unactionable = false

  if (currentTeam?.type === TeamType.Contractor) {
    unactionable = !assignedTeam?.id || !!(assignedTeam?.id && assignedTeam.id !== currentTeam.id)
  } else if (currentTeam?.type === TeamType.Homeowner || currentTeam?.type === TeamType.Architect) {
    unactionable = !!(assignedTeam?.id && assignedTeam.id !== currentTeam.id)
  }

  const hasNoChildren = childTasks.length < 1

  const statusData = getStatusData(status)
  const childTaskStatusSummary = (childTasks.length && `${completed}/${childTasks.length}`) || undefined

  const handleOpenPopover = (e:SyntheticEvent) => {
    e.stopPropagation()

    if (!refetchTasks) return

    if (!popover.current) return

    popover.current.event = e

    setTaskStatusPopover(true)
  }

  const updateTaskStatus = async (input:UpdateProjectTaskStatus) => {
    try {
      await updateProjectTaskStatus.mutateAsync({ input })
    } catch (e) {
      if (e instanceof Error) {
        console.log("ERROR: ", e.message)
      }
    }
  }

  const handleTaskStatusChange = async (e: CustomEvent<RadioGroupChangeEventDetail>) => {
    if (e.detail.value === status) return
    const input = {
      projectId: id,
      status: e.detail.value,
      taskId: id,
    }
    await setTaskStatusPopover(false)
    await setStatusLoading(true)
    await updateTaskStatus(input)
    await refetchTasks()

  }

  return <IonItem lines='none' className={classnames({ [Styles.taskItem]: true, [Styles.unactionable]: unactionable && hasNoChildren } )} key={id}  onClick={() => onClick && onClick({ id, parentId })}>
    <IonIconTyped className={Styles.taskIcon} slot="start" iconName={parsedIcon} />
    <div className={Styles.itemContentContainer}>
      <IonLabel>
        {title}
      </IonLabel>
      <div className={Styles.subtextContainer}>
        {dueDate && (
          <div>
            <IonIconTyped iconName='calendarOutline' />
            <span>{dueDate}</span>
          </div>
        )}
        {assignedTeam && (
          <div>
            <IonIconTyped iconName='personCircleOutline' />
            <span>{assignedTeam?.name}</span>
          </div>
        )}
      </div>
    </div>

    <IonLabel className={Styles.statusContainer} slot="end">
      <div>
        {isConfidential && <IonIconTyped iconName='lockClosedOutline' />}
        {childTaskStatusSummary && <div><IonIconTyped iconName='exitOutline' />{childTaskStatusSummary}</div> }
      </div>
      <div>
        <IonBadge style={{ cursor: "pointer" }} onClick={handleOpenPopover} slot="end" color={statusLoading ? 'light' : statusData.color}>{ statusLoading ? "Status change in progress..." : statusData.label}</IonBadge>
        <IonPopover ref={popover} isOpen={taskStatusPopoverOpen} onDidDismiss={() => setTaskStatusPopover(false)}>
          <IonContent onClick={(e:SyntheticEvent) => e.stopPropagation()}>
            <IonList>
              <IonRadioGroup value={status} onIonChange={handleTaskStatusChange}>
                <IonItem>
                  <IonLabel>
                    Change status of task
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Done</IonLabel>
                  <IonRadio slot="start" value={TaskStatus.Completed} />
                </IonItem>

                <IonItem>
                  <IonLabel>Overdue</IonLabel>
                  <IonRadio slot="start" value={TaskStatus.Overdue} />
                </IonItem>

                <IonItem>
                  <IonLabel>In Progress</IonLabel>
                  <IonRadio slot="start" value={TaskStatus.InProgress} />
                </IonItem>

                <IonItem>
                  <IonLabel>To Do</IonLabel>
                  <IonRadio slot="start" value={TaskStatus.NotStarted}  />
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </IonContent>
        </IonPopover>
      </div>
    </IonLabel>
  </IonItem>

}

export default Task
