import React from 'react'
import { IonItem, IonText } from '@ionic/react'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import Styles from '../Profile.module.scss'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'

type PublicArchitectProfileProps = {
  id: string,
}

const PublicArchitectProfile: React.FC<PublicArchitectProfileProps> = ({ id }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })

  if (isFetching) {
    return <LoadingSpinner name="PublicArchitectProfile" />
  }

  if (error) {
    console.error(`[PublicArchitectProfile] Failed to load Architect Profile: `, { id, data, error })
    return <ErrorBlock onRefresh={refetch} />

  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[PublicArchitectProfile] Architect Profile is missing: `, { id, data, error })
    return <ErrorBlock><p>Unable to find the Architect Profile.</p></ErrorBlock>
  }

  const profile = data.getArchitectProfile

  return <>
    <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
      <h3>{profile.companyTradingAs}</h3>
    </IonItem>

    <IonText className={Styles.lightGrey}>
      <p> Architect </p>
    </IonText>
    <IonText className={Styles.lightGrey}>
      <p>{formatAddressToSingleLine(profile.companyAddress) ?? <em>No Address</em>}</p>
    </IonText>
    <TeamMembers teamId={id} />
  </>
}

export default PublicArchitectProfile
