import React from 'react'
import { IonImg } from '@ionic/react'
import Styles from "./WeaverGuarantee.module.scss"
import dollarBadgeIcon from '../../../../assets/icons/dollar-badge-icon.svg'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

const WeaverGuarantee: React.FC = () => {
  const weaverFlags = useWeaverFlags()

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.titleSection}>
        <h5>Weaver Guarantee</h5>
        <IonImg src={dollarBadgeIcon} />
      </div>
      <p>{weaverFlags["create-project-contractors"].guaranteeText}</p>
    </div>
  )
}
export default WeaverGuarantee
