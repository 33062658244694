import React from 'react'
import { IonItem } from '@ionic/react'
import TeamMembers from '../TeamMembers/TeamMembers'
import RangeBadges from './RangeBadges'
import ListWorkHistoryItems from './ListWorkHistoryItems'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import Styles from './PublicContractorProfilePage.module.scss'
import { PublicContractorProfileScreenProps, PublicContractorScreenNames } from './PublicContractorProfile'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader, { NavElement } from '../../../common/components/GlobalHeader/GlobalHeader'

const ScreenHome: React.FC<PublicContractorProfileScreenProps & ScreenRenderProps<PublicContractorScreenNames>> = (props) => {
  return <>
    <WeaverIonHeader>
      <GlobalHeader
        navElement={NavElement.None}
        pageTitle='Public Profile'
      />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>
      <section className={Styles.companyInfoContainer}>
        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <h3>{props.profile.companyTradingAs}</h3>
        </IonItem>
        <p className={Styles.contractorTeamType}>Main Contractor</p>
        <p>{formatAddressToSingleLine(props.profile.companyAddress) ?? <em>No Address</em>}</p>
        <RangeBadges screenProps={props} width="90" height="90" />
      </section>

      <section>
        <ListWorkHistoryItems {...props} />
      </section>

      <section>
        <TeamMembers teamId={props.profile.id} />
      </section>
    </WeaverIonContent>
  </>
}

export default ScreenHome
