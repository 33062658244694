import React, { useCallback, useState } from "react"
import { IonButton,IonCard,IonIcon,IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import { useScreensWithProps } from "../../../../../../common/hooks/useScreens"
import { alwaysArray } from "../../../../../../common/utils"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { addCircleOutline, arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import ProvidePhotosScreen from "./ScreenProvidePhotos"
import { BudgetRange, UploadedFileStatus, useCreateContractorProfileWorkHistoryPhotoMutation } from "../../../../../../graphql/generated"
import { DisplayUploadedFileAsPhoto } from "./DisplayUploadedFileAsPhoto"
import { UploadFile } from "./UploadFile"
import { getSupportedImageFileTypes } from "../../../../../../common/utils/getSupportedFileTypes"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import { useShouldAwardContractorProfileBudgetRangeBadge } from "../ContractorProfileBudgetRangeBadge/hooks"
import Styles from './WorkHistoryPhotos.module.scss'
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"
import BrowseFilesButton from "../../../../../../common/components/BrowseFilesButton"

enum WorkHistoryPhotosScreens {
  ProvidePhotosInstructions = "ProvidePhotosInstructions",
  UploadPhotos = "UploadPhotos",
  Finished = "Finished",
}

const WorkHistoryPhotosPage: React.FC = () => {
  const history = useHistory()
  const triggerEventWorkHistoryReferencesAddedPastProjectPhoto = useAnalyticsEvent('WorkHistory_References_Added_Photo')
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const myContractorProfile = useMyContractorProfile()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createContractorProfileWorkHistoryPhoto = useCreateContractorProfileWorkHistoryPhotoMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  type ScreenRenderProps = {
    budgetRange: typeof budgetRange,
    workHistory: typeof workHistory,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<WorkHistoryPhotosScreens, ScreenRenderProps>({
    init: WorkHistoryPhotosScreens.ProvidePhotosInstructions,
    resetWhenValueChanges: history.location.search,
    screenProps: {
      budgetRange,
      workHistory,
    },
    screens: {
      ProvidePhotosInstructions: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const onClickNext = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent className={`${Styles.textContainer}`}>
              <ProvidePhotosScreen />
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRange({ budgetRange })}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      UploadPhotos: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen, workHistory }) => {
          const onClickBack = useChangeScreen(previousScreen)
          const onClickNext = useChangeScreen(nextScreen)

          const [ filesToUpload, setFilesToUpload ] = useState<File[]>([])

          const photos = alwaysArray(workHistory?.photos)

          const onFilesSelectedByUser = (files: File[]) => {
            setFilesToUpload(files)
          }

          const addUploadFileToWorkHistoryItemPhotos = (uploadedFileId: string) =>
            createContractorProfileWorkHistoryPhoto.mutateAsync({
              workHistoryId,
              uploadedFileId,
            })

          const onPhotoUploaded = async (uploadFileId: string) => {
            await addUploadFileToWorkHistoryItemPhotos(uploadFileId)
            invalidateMyContractorProfile()
          }

          const onPhotoArchived = () => {
            invalidateMyContractorProfile()
          }
          const supportedImageFileTypes = getSupportedImageFileTypes()
          const buildSupportedImageFormatsLabel = supportedImageFileTypes.map(format => format.replace("image/", "").toUpperCase()).join(", ")
          const nonArchivedPhotos = photos.filter(photo => photo.status !== UploadedFileStatus.Archived)

          return <>
            <WeaverIonHeader>
              <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
            </WeaverIonHeader>
            <WeaverIonContent className="ion-padding">
              <h3 className="ion-padding-start">Upload Photos</h3>
              <span className="ion-padding-start">Supported formats: {buildSupportedImageFormatsLabel}.</span>

              <BrowseFilesButton
                onFilesSelectedByUserCallback={onFilesSelectedByUser}
                containerCssClassName={Styles.uploadPhotosButtonContainer}
                multipleSelection={true}
                supportedFileTypes={supportedImageFileTypes}
                renderButton={
                  (openNativeFilePicker) => (
                    <IonCard>
                      <IonButton onClick={openNativeFilePicker} fill="clear" size="small" className={Styles.uploadPhotosButton}>
                        <IonIcon icon={addCircleOutline} className={Styles.uploadPhotosButtonIcon}/>
                        Upload Photo
                      </IonButton>
                    </IonCard>
                  )
                }
              />

              <div className={Styles.photosContainer}>
                {
                  nonArchivedPhotos
                    .map(photo => <DisplayUploadedFileAsPhoto key={photo.id} uploadedFileId={photo.id} onFileArchived={onPhotoArchived} shouldDisplayDeleteButton={true} />)
                }
                {
                  filesToUpload
                    .map(file => <UploadFile key={file.name} fileToUpload={file} onUploadComplete={onPhotoUploaded} trackEvent={triggerEventWorkHistoryReferencesAddedPastProjectPhoto} />)
                }
              </div>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
                <IonButton slot="end" onClick={onClickNext} disabled={nonArchivedPhotos.length === 0}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },

      Finished: {
        render: useCallback(() => {
          const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)
          const goToAwardBadge = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge.path)

          const onClickNext =
            shouldAwardContractorProfileBudgetRangeBadge
              ? goToAwardBadge({ budgetRange, workHistoryId })
              : goToUnlockContractorBudgetRange({ budgetRange })

          console.debug("[WorkHistoryPhotos.Finished] Render: ", { shouldAwardContractorProfileBudgetRangeBadge })

          return <>
            <WeaverIonContent>
              <BrilliantScreen title="Amazing!!!" description="You have added photos"/>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={onClickNext}>Continue</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
    },
  })

  return (
    <WeaverIonPage key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default WorkHistoryPhotosPage
