import React from "react"
import { IonButton, IonIcon, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, useRouteTo } from "../../../../routes"
import  { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import ListBudgetRanges from "./ListBudgetRanges"
import { budgetRangeLabels } from "./budgetRanges.i18n"
import { arrowBackOutline, lockClosed, lockOpen } from 'ionicons/icons'
import { createValidCssClassname } from "../../../../common/utils/string"
import { convertToLockedBudgetRangeStatuses, useMyContractorProfile } from "./datasource"
import { BudgetRange, ProgressionStatus } from "../../../../graphql/generated"
import { objectEntries } from "../../../../common/utils/objects"
import Styles from "./ContractorBudgetRanges.module.scss"
import { progressionStatusSubtextLabel } from "./progressionStatus.i18n"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import WeaverIonPage from "../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../common/components/WeaverIonWrappers/WeaverIonFooter"

type IonIconPropsIcon = Parameters<typeof IonIcon>['0']['icon']
export const progressionStatusIcons: Partial<Record<ProgressionStatus, IonIconPropsIcon>> = {
  [ProgressionStatus.NotStarted]: lockClosed,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
  [ProgressionStatus.WaitingOnWeaver]: lockOpen,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
}

// Local Helpers
const getLockedBudgetRangeGenericSelectorOptions = (lockedBudgetRangeStatuses: Record<BudgetRange, ProgressionStatus>, filterTo?: ProgressionStatus[]): GenericSelectorOption<BudgetRange>[] =>
  objectEntries(lockedBudgetRangeStatuses)
    .filter(([ , lockedBudgetRangeStatus ]) => filterTo === undefined || filterTo.includes(lockedBudgetRangeStatus))
    .map(([ budgetRange, lockedBudgetRangeStatus ]) => ({
      key: budgetRange,
      label: budgetRangeLabels[budgetRange],
      itemSubtext: progressionStatusSubtextLabel[lockedBudgetRangeStatus],
      value: budgetRange,
      itemClassName: Styles[createValidCssClassname(lockedBudgetRangeStatus )],
      itemSuffix: <IonIcon icon={progressionStatusIcons[lockedBudgetRangeStatus]} />,
    }))

const ContractorBudgetRangesPage: React.FC = () => {
  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)
  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  const myContractorProfile = useMyContractorProfile()
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.workHistory)

  const fireEventWorkHistoryReferencesRangeSelected = useAnalyticsEvent('WorkHistory_References_Range_Selected')

  const selectBudgetRange = (budgetRange?: BudgetRange) => {
    if (!budgetRange) return

    fireEventWorkHistoryReferencesRangeSelected({
      budgetRangeSelected: budgetRange,
      rangeStatus: lockedBudgetRangeStatuses[budgetRange],
    })

    goToUnlockContractorBudgetRange({ budgetRange }, undefined, 'root', 'replace')()
  }

  return (
    <WeaverIonPage>
      <WeaverIonContent className={`ion-padding ${Styles.content}`} >
        <h3 className="ion-padding-start">Pick a budget range to unlock leads</h3>
        <ListBudgetRanges
          heading="Unlocked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.Completed ])}
          onSelect={selectBudgetRange}
        />
        <ListBudgetRanges
          heading="Locked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.WaitingOnUser, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.NotStarted ])}
          onSelect={selectBudgetRange}
        />
      </WeaverIonContent>
      <WeaverIonFooter className="ion-no-border ion-padding">
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={goToContractorLeadPreferences({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </WeaverIonPage>
  )
}

export default ContractorBudgetRangesPage
